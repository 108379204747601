import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CheckboxGroup,
  RadioGroup,
  SectionGridRow,
  TextAreaField,
} from '@application/components';
import { Section } from '@application/views/recruitment/request/components';
import {
  OfferMandateFollowUpCode,
  OfferRegularFollowUpMethodCode,
  OfferRegularFollowUpPeriodCode,
} from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../../../types';

const MandateFollowUpsFields = () => {
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const mandateFollowUps = watch('mandateFollowUps') || [];

  const regularFollowUpsMethods = watch('regularFollowUpsMethods') || [];

  const handleMandateFollowUpsChange = (event: any): void => {
    if (event.target.checked) {
      setValue('mandateFollowUps', [...mandateFollowUps, event.target.value]);
    } else {
      setValue('mandateFollowUps', [
        ...mandateFollowUps.filter((s) => s !== event.target.value),
      ]);
    }
    trigger('mandateFollowUps');
    trigger('regularFollowUpsMethods');
    trigger('regularFollowUpsPeriod');
    trigger('mandateFollowUpsOthersDescription');
  };

  const handleRegularFollowUpsMethodsChange = (event: any): void => {
    if (event.target.checked) {
      setValue('regularFollowUpsMethods', [
        ...regularFollowUpsMethods,
        event.target.value,
      ]);
    } else {
      setValue('regularFollowUpsMethods', [
        ...regularFollowUpsMethods.filter((s) => s !== event.target.value),
      ]);
    }
    trigger('regularFollowUpsMethods');
  };

  return (
    <Section legend={t('subtitles.mandateFollowUps')} padding={0}>
      <Controller
        name="mandateFollowUps"
        control={control}
        render={({ field: { value }, fieldState: { error } }) => (
          <CheckboxGroup
            className="p-s-24"
            data={Object.values(OfferMandateFollowUpCode)
              .map((type) => ({
                value: type,
                text: {
                  fr: tGlobal(
                    `enum.offerMandateFollowUpCode.${type.toLowerCase()}`
                  ),
                  en: tGlobal(
                    `enum.offerMandateFollowUpCode.${type.toLowerCase()}`
                  ),
                },
              }))
              .sort((a) =>
                a.value === OfferMandateFollowUpCode.Others ? 1 : -1
              )}
            language={extractLanguage(i18n.language)}
            onChange={handleMandateFollowUpsChange}
            values={value}
            invalid={!!error}
            helperText={error?.message && tGlobal(error?.message)}
          />
        )}
      />

      {mandateFollowUps.includes(OfferMandateFollowUpCode.RegularFollowUps) && (
        <SectionGridRow
          isTopRow
          className="flex-col gap-s-24 px-s-24 w-auto items-start"
        >
          <Controller
            name="regularFollowUpsMethods"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <CheckboxGroup
                legend={t('labels.regularFollowUpsMethods')}
                data={Object.values(OfferRegularFollowUpMethodCode).map(
                  (type) => ({
                    value: type,
                    text: {
                      fr: tGlobal(
                        `enum.offerRegularFollowUpMethodCode.${type.toLowerCase()}`
                      ),
                      en: tGlobal(
                        `enum.offerRegularFollowUpMethodCode.${type.toLowerCase()}`
                      ),
                    },
                  })
                )}
                language={extractLanguage(i18n.language)}
                onChange={handleRegularFollowUpsMethodsChange}
                legendSize="medium"
                values={value}
                invalid={!!error}
                helperText={error?.message && tGlobal(error?.message)}
              />
            )}
          />

          <Controller
            name="regularFollowUpsPeriod"
            control={control}
            render={({ field: { name, value }, fieldState: { error } }) => (
              <RadioGroup
                legend={t('labels.regularFollowUpsPeriod')}
                data={Object.values(OfferRegularFollowUpPeriodCode)
                  .map((type) => ({
                    value: type,
                    text: {
                      fr: tGlobal(
                        `enum.offerRegularFollowUpPeriodCode.${type.toLowerCase()}`
                      ),
                      en: tGlobal(
                        `enum.offerRegularFollowUpPeriodCode.${type.toLowerCase()}`
                      ),
                    },
                  }))
                  .sort((a) =>
                    a.value === OfferRegularFollowUpPeriodCode.Weekly ? -1 : 1
                  )}
                name={name}
                language={extractLanguage(i18n.language)}
                value={value as string | undefined}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue(
                    name,
                    event.target.value as OfferRegularFollowUpPeriodCode
                  );
                  trigger(name);
                }}
                invalid={!!error}
                helperText={error?.message && tGlobal(error.message)}
                legendSize="medium"
              />
            )}
          />
        </SectionGridRow>
      )}
      {mandateFollowUps.includes(OfferMandateFollowUpCode.Others) && (
        <Controller
          name="mandateFollowUpsOthersDescription"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              className="p-s-24"
              label={t('labels.othersDescription')}
              name={name}
              value={value || ''}
              rows={4}
              maxChar={3000}
              onChange={(e) => {
                onChange(e);
                trigger(name);
              }}
              invalid={!!errors.mandateFollowUpsOthersDescription}
              helperText={
                errors.mandateFollowUpsOthersDescription?.message &&
                tGlobal(errors.mandateFollowUpsOthersDescription?.message, {
                  max: '3000',
                })
              }
              labelSize="medium"
            />
          )}
        />
      )}
      <Controller
        name="mandateFollowUpsMoreDetails"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <TextAreaField
            className="p-s-24"
            label={t('labels.moreDetails')}
            name={name}
            value={value || ''}
            rows={4}
            maxChar={3000}
            onChange={onChange}
            invalid={!!errors.mandateFollowUpsMoreDetails}
            helperText={
              errors.mandateFollowUpsMoreDetails?.message &&
              tGlobal(errors.mandateFollowUpsMoreDetails?.message, {
                max: '3000',
              })
            }
            labelSize="medium"
          />
        )}
      />
    </Section>
  );
};

export default MandateFollowUpsFields;
