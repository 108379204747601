import React from 'react';
import { SubmitHandler } from 'react-hook-form';

interface FormProps {
  children: React.ReactNode;
  className?: string;
  onSubmit?: SubmitHandler<any>;
}

const Form = ({ children, className, onSubmit }: FormProps) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      e.preventDefault();
    }
  };

  return (
    <div role="presentation" className={className} onKeyDown={handleKeyDown}>
      <form className={className} onSubmit={onSubmit}>
        {children}
      </form>
    </div>
  );
};

export default Form;
