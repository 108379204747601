import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';

type GeneralConditionsProps = {
  data: RecruitmentOpportunity | undefined;
};

const GeneralConditions = ({ data }: GeneralConditionsProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow isTopRow>
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.baselineBudget')}&nbsp;
            {data?.generalContractualConditions?.maximumBudgetValue && (
              <div className="mr-s-8 mt-s-16 flex">
                <span>{t('labels.minBudget')}&nbsp;</span>

                <span className="text-18 font-semibold text-primary">
                  {tGlobal('suffix.currencyValue', {
                    value:
                      data?.generalContractualConditions?.minimumBudgetValue,
                  })}
                </span>

                <div className="divider divider-horizontal ml-s-8 mr-s-16 before:w-s-1 after:w-s-1" />

                <span>{t('labels.maxBudget')}&nbsp;</span>

                <span className="text-18 font-semibold text-primary">
                  {tGlobal('suffix.currencyValue', {
                    value:
                      data?.generalContractualConditions?.maximumBudgetValue,
                  })}
                </span>

                <span className="ml-s-32">{t('labels.perCandidateHired')}</span>
              </div>
            )}
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow>
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.preferredPaymentMethod')}&nbsp;
            <PlaceholderSwitcher
              value={data?.generalContractualConditions.paymentMethodValue}
            >
              <span className="text-18 font-semibold text-primary">
                {t(
                  `enum.requestConditionPaymentMethodCode.${data?.generalContractualConditions.paymentMethodValue?.toLowerCase()}`
                )}
              </span>

              {data?.generalContractualConditions
                .startOfAgreementPaymentMethodValue && (
                <div className="mr-s-8 mt-s-16 flex">
                  <span className="mr-s-8">
                    {`${t(`enum.requestConditionStartOfAgreementPaymentMethodCode.${data?.generalContractualConditions.startOfAgreementPaymentMethodValue?.toLowerCase()}`)}: `}
                  </span>
                  <span className="text-18 font-semibold text-primary">
                    {t(
                      `enum.requestConditionPaymentFrequencyCode.${data?.generalContractualConditions.paymentFrequencyValue?.toLowerCase()}`
                    )}
                  </span>
                </div>
              )}
            </PlaceholderSwitcher>
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.mustProposeCandidates')}</span>
        <PlaceholderSwitcher
          value={
            data?.generalContractualConditions.candidateInHandMandatoryValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${!!data?.generalContractualConditions
                .candidateInHandMandatoryValue}`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>
    </>
  );
};

export default GeneralConditions;
