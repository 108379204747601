import React, { useCallback, useMemo, useState } from 'react';

import { AccountContext } from '@application/context';
import { useAccountGet } from '@domain/account';

type AccountProviderProps = {
  children: React.ReactNode;
};

const AccountProvider = ({ children }: AccountProviderProps) => {
  const { data, fetching, error, reexecuteQuery } = useAccountGet(true);
  const account = data?.account || undefined;
  const [localSpecialtyCodes, setLocalSpecialtyCodes] = useState<string[]>([]);

  const initializeAccount = useCallback(
    () => reexecuteQuery(),
    [reexecuteQuery]
  );

  const preferredSpecialtyCodes = useMemo(() => {
    if (localSpecialtyCodes.length > 0) {
      return localSpecialtyCodes;
    }
    return account?.preferredSpecialties.map((s) => s.specialty.code) || [];
  }, [account, localSpecialtyCodes]);

  const updatePreferredSpecialtyCode = useCallback(
    (newCode: string) => {
      const currentCodes =
        account?.preferredSpecialties.map((s) => s.specialty.code) || [];

      if (!currentCodes.includes(newCode)) {
        setLocalSpecialtyCodes([
          ...new Set([...currentCodes, ...localSpecialtyCodes, newCode]),
        ]);
      } else {
        setLocalSpecialtyCodes(currentCodes);
      }
    },
    [account?.preferredSpecialties, localSpecialtyCodes]
  );

  const value = useMemo(
    () => ({
      account,
      error,
      initializeAccount,
      isLoading: fetching,
      preferredSpecialtyCodes,
      refreshAccount: reexecuteQuery,
      updatePreferredSpecialtyCode,
    }),
    [
      account,
      error,
      fetching,
      initializeAccount,
      preferredSpecialtyCodes,
      reexecuteQuery,
      updatePreferredSpecialtyCode,
    ]
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export default AccountProvider;
