import { cn } from '@utils/lib-utils';

import { Tooltip } from '../tooltip';

type SectionGridProps = {
  children?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  gridColsClassName?: string;
  hideTitle?: boolean;
  readOnly?: boolean;
  title?: string;
  isOffer?: boolean;
  tooltipMessage?: string;
};

const SectionGrid = ({
  children,
  className: customClassName,
  fullWidth,
  gridColsClassName: customGridColsClassName,
  hideTitle,
  readOnly,
  title,
  isOffer,
  tooltipMessage,
}: SectionGridProps) => (
  <section
    className={cn('w-full my-s-16', { 'mt-s-0': readOnly }, customClassName)}
  >
    <div
      className={cn('mb-s-16', {
        'sr-only': hideTitle,
      })}
    >
      <h2 className="h4">
        {title}

        {tooltipMessage && (
          <Tooltip message={tooltipMessage}>
            <i className="font-normal z-20 ml-s-4 mt-s-4 absolute ri-information-line text-20 leading-none text-neutral-secondary" />
          </Tooltip>
        )}
      </h2>
    </div>

    <div
      className={cn(
        'grid grid-cols-[1fr_5rem] border rounded-md items-center',
        {
          'rounded-tl-none rounded-tr-none border-t-transparent': readOnly,
          'grid-cols-1': fullWidth,
          'grid-cols-[1fr_10rem]': isOffer,
        },
        customGridColsClassName
      )}
    >
      {children}
    </div>
  </section>
);

export default SectionGrid;
