import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Badge,
  ButtonLink,
  ConfirmationModal,
  IconButton,
  MatchingScore,
  RankBadge,
} from '@application/components';
import { ButtonLinkCounter } from '@application/components/buttons';
import { PictureBadges } from '@application/components/picture-badges';
import { CandidateRequestTypeBadge } from '@application/components/request-type-badges';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { usePagination } from '@application/hooks';
import {
  CandidateOffer,
  CandidateOffersFilters,
  CandidateOffersSortBy,
  CandidateOffersSortDirective,
  OfferStatusTypeCode,
  SortDirection,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import { calculateTimeSince } from '@utils/date-utils';
import { extractLanguage, getLocalizedDescription } from '@utils/i18n-utils';
import { getOfferStatusClassNames } from '@utils/styles-utils';

import useDeleteCandidateOffer from './useDeleteCandidateOffer';
import useGetCandidateOffers from './useGetCandidateOffers';

const columnIds = {
  avatar: 'avatar',
  jobSpecialty: 'jobSpecialty',
  rank: 'rank',
  matchingScore: 'matchingScore',
  status: 'status',
  type: 'type',
  operationTerritories: 'operationTerritories',
  createdAt: 'createdAt',
  link: 'link',
  delete: 'delete',
} as const;

const columnHelper = createColumnHelper<CandidateOffer>();

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>,
  onDelete: (candidateOfferId: string) => () => void,
  hash: string
) => {
  const columns: ColumnDef<CandidateOffer, any>[] = [
    columnHelper.accessor((row) => row.user.avatarUrl, {
      id: columnIds.avatar,
      cell: ({ row }) => (
        <PictureBadges
          pictures={[
            {
              name: row.original.user.name ?? undefined,
              url: row.original.user.avatarUrl,
            },
          ]}
          rounded="rounded-md"
          tooltipPosition="right"
        />
      ),
      header: () => '',
      size: 40,
      minSize: 40,
      maxSize: 40,
      enableSorting: false,
    }),
    columnHelper.accessor(
      (row) =>
        getLocalizedDescription(row.jobSpecialty?.descriptions, language),
      {
        id: columnIds.jobSpecialty,
        cell: (info) => (
          <Link
            to={generatePath(PrivatePage.VIEW_CANDIDATE_OFFER, {
              id: info.row.original.id,
            })}
            className="hover:underline"
          >
            <span>{info.getValue() || '-'}</span>
          </Link>
        ),
        header: () => t('list.column.jobSpecialty'),
        size: 256,
        minSize: 256,
        maxSize: undefined,
        sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
      }
    ),
    columnHelper.accessor((row) => row.rank, {
      id: columnIds.rank,
      cell: (info) =>
        info.row.original.status === OfferStatusTypeCode.Draft ? (
          <span>-</span>
        ) : (
          <RankBadge rank={Number(info.getValue())} className="text-center" />
        ),
      header: () => t('list.column.rank'),
      size: 120,
      minSize: 120,
      maxSize: 120,
      sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
    }),
    columnHelper.accessor((row) => row.matchingScore, {
      id: columnIds.matchingScore,
      cell: (info) =>
        info.row.original.status === OfferStatusTypeCode.Draft ? (
          <span>-</span>
        ) : (
          <MatchingScore value={Number(info.getValue())} className="m-s-0" />
        ),
      header: () => t('list.column.matchingScore'),
      size: 180,
      minSize: 180,
      maxSize: 180,
      sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
    }),
    columnHelper.accessor((row) => row.status, {
      id: columnIds.status,
      cell: (info) => (
        <Badge
          value={t(`list.statusOptions.${info.getValue().toLowerCase()}`)}
          placeholder="-"
          className={getOfferStatusClassNames(info.getValue())}
        />
      ),
      header: () => t('list.column.status'),
      size: 110,
      minSize: 110,
      maxSize: 110,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.candidateOpportunity?.type, {
      id: columnIds.type,
      cell: (info) => <CandidateRequestTypeBadge type={info.getValue()} />,

      header: () => t('list.column.type'),
      size: 88,
      minSize: 88,
      maxSize: 88,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: columnIds.createdAt,
      cell: (info) => {
        const timeSinceCreated = calculateTimeSince(info.getValue());
        return (
          <span>
            {timeSinceCreated.value > 1
              ? tGlobal('date.since', {
                  date: tGlobal(`date.options.${timeSinceCreated.unit}`, {
                    count: timeSinceCreated.value,
                  }),
                })
              : tGlobal('date.today')}
          </span>
        );
      },
      header: () => t('list.column.date'),
      size: 112,
      minSize: 112,
      maxSize: undefined,
      sortDescFirst: true, // Because react-table people can't be bother to fix a simple issue, I need to do this https://github.com/TanStack/table/issues/4289
    }),

    columnHelper.accessor((row) => row, {
      id: columnIds.link,
      cell: ({ row }) =>
        row.original.status === OfferStatusTypeCode.Draft && (
          <ButtonLink
            to={generatePath(PrivatePage.CANDIDATE_OFFER_EDIT, {
              id: row.original.id,
            })}
            size="x-small"
            className="whitespace-nowrap py-s-0 animate-none"
          >
            {t('list.actions.resumeCreation')}
          </ButtonLink>
        ),
      header: () => null,
      size: 150,
      minSize: 150,
      maxSize: 150,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.delete,
      cell: ({ row }) => (
        <div className="flex items-center justify-end gap-s-16">
          <ButtonLinkCounter
            count={row.original.unreadMessagesCount}
            iconName="ri-question-answer-line"
            to={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_CHAT, {
              id: row.original.candidateRequestId,
            })}
            from={generatePath(RootPrivatePage.CANDIDATE_OFFERS)}
            size="xSmall"
          />

          {row.original.status !== OfferStatusTypeCode.Accepted &&
            row.original.status !== OfferStatusTypeCode.Rejected &&
            !row.original.negotiationRoomId && (
              <IconButton
                text={tGlobal('button.delete')}
                className="animate-none"
                icon={<i className="ri-delete-bin-line text-20" />}
                outline
                size="x-small"
                onClick={onDelete(row.original.id)}
              />
            )}
        </div>
      ),
      header: () => null,
      size: 32,
      minSize: 32,
      maxSize: 32,
      enableSorting: false,
    }),
  ];

  return columns.filter((column) => {
    if (hash === '#draft') {
      const draftColumns = [
        columnIds.jobSpecialty,
        columnIds.type,
        columnIds.operationTerritories,
        columnIds.link,
        columnIds.delete,
      ];
      return !!draftColumns.some((id) => id === column.id);
    }
    return true;
  });
};

const stringSortToEnum = (
  by: string,
  language: string
): CandidateOffersSortBy => {
  const extractedLanguage = extractLanguage(language);

  switch (by) {
    case columnIds.createdAt:
      return CandidateOffersSortBy.Date;
    case columnIds.jobSpecialty:
      return extractedLanguage === 'en'
        ? CandidateOffersSortBy.JobSpecialtyEn
        : CandidateOffersSortBy.JobSpecialtyFr;
    case columnIds.matchingScore:
      return CandidateOffersSortBy.MatchingScore;
    case columnIds.rank:
      return CandidateOffersSortBy.Rank;
    default:
      return CandidateOffersSortBy.Date;
  }
};

const mapSorting = (
  sorting: SortingState,
  language: string
): CandidateOffersSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id, language),
  }));

type UseCandidateOffersListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<CandidateOffer>[];
  count?: number;
  currentPage?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  onSortingChange: OnChangeFn<SortingState>;
  candidateOffers: CandidateOffer[];
  sorting: SortingState;
  isLoading: boolean;
};

export const useCandidateOffersList = (
  filters?: CandidateOffersFilters
): UseCandidateOffersListProps => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'matchingScore', desc: true },
  ]);

  const { t, i18n } = useTranslation('candidates');
  const { t: tGlobal } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();

  const {
    viewModel: { data, isLoading },
    refetchCandidateOffers,
  } = useGetCandidateOffers(
    mapSorting(sorting, i18n.language),
    filters,
    pagination
  );

  useEffect(() => {
    setCursors({
      startCursor:
        data?.candidateOffers.page.pageInfo?.startCursor || undefined,
      endCursor: data?.candidateOffers.page.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateOffers.page.pageInfo]);

  useEffect(() => {
    resetPagination();
  }, [sorting, resetPagination]);

  const { hash } = useLocation();

  useEffect(() => {
    resetPagination();
    refetchCandidateOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const { deleteCandidateOffer } = useDeleteCandidateOffer();

  const handleDelete = useCallback(
    (candidateOfferId: string) => async () => {
      setModal(null);
      await deleteCandidateOffer({ input: { candidateOfferId } });
      refetchCandidateOffers();
    },
    [deleteCandidateOffer, setModal, refetchCandidateOffers]
  );

  const confirmDelete = useCallback(
    (candidateOfferId: string) => () => {
      setModal({
        title: t('modal.titles.deleteOffer'),
        content: (
          <ConfirmationModal
            content={t('modal.contents.confirmDeleteOffer')}
            onCancel={() => setModal(null)}
            onConfirm={handleDelete(candidateOfferId)}
          />
        ),
      });
    },
    [setModal, t, handleDelete]
  );

  const columns = getColumns(i18n.language, t, tGlobal, confirmDelete, hash);

  return {
    columnIds,
    columns: columns as ColumnDef<CandidateOffer>[],
    count: data?.candidateOffers.pageData?.count,
    currentPage,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    onSortingChange: setSorting,
    candidateOffers: flattenEdges(
      data?.candidateOffers.page.edges?.slice() || []
    ),
    sorting,
    isLoading,
  };
};
