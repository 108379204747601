import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxGroup, TextAreaField } from '@application/components';
import { Section } from '@application/views/recruitment/request/components';
import { OfferTalentSourceCode } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../../../types';

const TalentSourcesFields = () => {
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const talentSources = watch('talentSources') || [];

  const handleTalentSourcesChange = (event: any): void => {
    if (event.target.checked) {
      setValue('talentSources', [...talentSources, event.target.value]);
    } else {
      setValue('talentSources', [
        ...talentSources.filter((s) => s !== event.target.value),
      ]);
    }
    trigger('talentSources');
    trigger('talentSourcesOthersDescription');
  };

  return (
    <Section legend={t('subtitles.talentSources')} padding={0}>
      <Controller
        name="talentSources"
        control={control}
        render={({ field: { value }, fieldState: { error } }) => (
          <CheckboxGroup
            className="p-s-24"
            data={Object.values(OfferTalentSourceCode)
              .map((type) => ({
                value: type,
                text: {
                  fr: tGlobal(
                    `enum.offerTalentSourceCode.${type.toLowerCase()}`
                  ),
                  en: tGlobal(
                    `enum.offerTalentSourceCode.${type.toLowerCase()}`
                  ),
                },
              }))
              .sort((a) => (a.value === OfferTalentSourceCode.Others ? 1 : -1))}
            language={extractLanguage(i18n.language)}
            onChange={handleTalentSourcesChange}
            values={value}
            invalid={!!error}
            helperText={error?.message && tGlobal(error?.message)}
          />
        )}
      />
      {talentSources.includes(OfferTalentSourceCode.Others) && (
        <Controller
          name="talentSourcesOthersDescription"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              className="p-s-24"
              label={t('labels.othersDescription')}
              name={name}
              value={value || ''}
              rows={4}
              maxChar={3000}
              onChange={(e) => {
                onChange(e);
                trigger(name);
              }}
              invalid={!!errors.talentSourcesOthersDescription}
              helperText={
                errors.talentSourcesOthersDescription?.message &&
                tGlobal(errors.talentSourcesOthersDescription?.message, {
                  max: '3000',
                })
              }
              labelSize="medium"
            />
          )}
        />
      )}
      <Controller
        name="talentSourcesMoreDetails"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <TextAreaField
            className="p-s-24"
            label={t('labels.moreDetails')}
            name={name}
            value={value || ''}
            rows={4}
            maxChar={3000}
            onChange={onChange}
            invalid={!!errors.talentSourcesMoreDetails}
            helperText={
              errors.talentSourcesMoreDetails?.message &&
              tGlobal(errors.talentSourcesMoreDetails?.message, {
                max: '3000',
              })
            }
            labelSize="medium"
          />
        )}
      />

      <Controller
        name="talentSourcesMethodsToolsDescription"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <TextAreaField
            className="p-s-24"
            label={t('labels.talentSourcesMethodsToolsDescription')}
            labelSize="medium"
            name={name}
            value={value || ''}
            rows={4}
            maxChar={3000}
            onChange={onChange}
            invalid={!!errors.talentSourcesMethodsToolsDescription}
            helperText={
              errors.talentSourcesMethodsToolsDescription?.message &&
              tGlobal(errors.talentSourcesMethodsToolsDescription?.message, {
                max: '3000',
              })
            }
          />
        )}
      />
    </Section>
  );
};

export default TalentSourcesFields;
