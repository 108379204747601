import { useTranslation } from 'react-i18next';

import { Box } from '@application/components';
import { ServiceType } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type ServiceTypesProps = {
  services?: readonly ServiceType[];
  hideBorder?: boolean;
};

// FIXME: consultation color and icon
const ServiceTypes = ({ services, hideBorder }: ServiceTypesProps) => {
  const { t } = useTranslation('organization');

  const colors = {
    [ServiceType.Consultation]: 'text-primary bg-neutral',
    [ServiceType.Headhunter]:
      'bg-badge-light-blue border-badge-light-blue text-badge-blue',
    [ServiceType.InternationalRecruitment]:
      'bg-badge-light-green border-badge-light-green text-badge-green',
    [ServiceType.TemporaryHelp]:
      'bg-badge-light-yellow border-badge-light-yellow text-badge-yellow',
    [ServiceType.Outsourcing]:
      'bg-badge-light-pink border-badge-light-pink text-badge-pink',
  };

  const icons = {
    [ServiceType.Consultation]: 'ri-image-circle-line',
    [ServiceType.Headhunter]: 'ri-customer-service-2-line',
    [ServiceType.InternationalRecruitment]: 'ri-global-line',
    [ServiceType.TemporaryHelp]: 'ri-timer-2-line',
    [ServiceType.Outsourcing]: 'ri-profile-line',
  };

  return (
    <Box noBorder={hideBorder}>
      <p className="text-16 font-semibold">{t('information.services')}</p>

      <div className="flex flex-wrap gap-s-10 mt-s-16">
        {services?.map((s) => (
          <div
            key={s}
            className={cn(
              'text-14 rounded-md py-s-10 px-s-16 flex flex-row items-center',
              colors[s]
            )}
          >
            <i className={cn(icons[s], 'text-20 mr-s-8')} />
            {t(`enum.serviceType.${s.toLowerCase()}`)}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default ServiceTypes;
