import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import { AccountFragment } from '../account';
import { RecruitmentOpportunityFragment } from '../opportunity/recruitment/fragment';
import {
  OfferGeneralConditionsFragment,
  OfferHeadhunterConditionsFragment,
  OfferInternationalRecruitmentConditionsFragment,
  OfferOutsourcingConditionsFragment,
  OfferTemporaryPlacementConditionsFragment,
} from './conditions';
import OfferCandidateFragment from './offer.candidate.fragment';

const OfferFragment = gql`
  ${AccountFragment}
  ${OfferOutsourcingConditionsFragment}
  ${OfferInternationalRecruitmentConditionsFragment}
  ${OfferTemporaryPlacementConditionsFragment}
  ${OfferHeadhunterConditionsFragment}
  ${OfferGeneralConditionsFragment}
  ${DescriptionsFragment}
  ${OfferCandidateFragment}
  ${RecruitmentOpportunityFragment}

  fragment OfferFragment on Offer {
    id
    accountId
    requestId
    userId
    status
    rejectionReason
    rejectionExplanation
    linkProfile
    requestTypes
    similarMandatesExperiences
    whyRightToFillMandate
    canGiveDetailsOnSimilarMandates
    similarMandatesReferencesDescription
    whyNoSimilarMandatesReferences
    talentSources
    talentSourcesOthersDescription
    talentSourcesMoreDetails
    talentSourcesMethodsToolsDescription
    candidatesEvaluationProcesses
    candidatesEvaluationProcessesOthersDescription
    candidatesEvaluationProcessesMoreDetails
    mandateFollowUps
    regularFollowUpsMethods
    regularFollowUpsPeriod
    mandateFollowUpsOthersDescription
    mandateFollowUpsMoreDetails
    rank
    matchingScore
    totalCosts
    totalCostsMin
    totalCostsMax
    rank
    negotiationRoomId
    candidatesAvailable
    salesPitch
    noCandidatesExplaination
    progressionStatuses {
      receptionStatus
      candidateStatus
      contractualTermsStatus
      finalDecisionStatus
    }
    generalContractualConditions {
      ...OfferGeneralConditionsFragment
    }
    headhunterContractualConditions {
      ...OfferHeadhunterConditionsFragment
    }
    temporaryPlacementContractualConditions {
      ...OfferTemporaryPlacementConditionsFragment
    }
    internationalRecruitmentContractualConditions {
      ...OfferInternationalRecruitmentConditionsFragment
    }
    outsourcingContractualConditions {
      ...OfferOutsourcingConditionsFragment
    }
    candidates {
      ...OfferCandidateFragment
    }
    createdAt
    updatedAt
    matchingScore
    account {
      ...AccountFragment
    }
    availableTransitions
    resumeCount
    recruitmentOpportunity {
      ...RecruitmentOpportunityFragment
    }
    user {
      name
      email
      avatarUrl
    }
    unreadMessagesCount
  }
`;

export default OfferFragment;
