import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const CandidateOfferListFragment = gql`
  fragment CandidateOfferListFragment on CandidateOffer {
    ${DescriptionsFragment}
    id
    accountId
    candidateRequestId
    userId
    status
    matchingScore
    rank
    negotiationRoomId
    candidateOpportunity {
     type
    }
    createdAt
    updatedAt
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobSpecialty {
      code
         descriptions {
        ...DescriptionsFragment
      }
    }
    user {
      name
      avatarUrl
    }
    unreadMessagesCount
  }
`;

export default CandidateOfferListFragment;
