import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RootPrivatePage } from '@application/enums/pagesUrl';
import {
  CandidateRequestChatRoom,
  CandidateRequestChatRoomMessage,
} from '@domain/graphql.types';

import { ChatRoom } from './components';
import { ChatListItemProps } from './components/ChatListItem';
import { ChatContent, ChatListProps } from './components/ChatRoom';
import {
  useCandidateRequestChatRoomMessagesList,
  useCandidateRequestChatRoomsList,
  useCreateCandidateRequestChatRoomMessage,
  useMarkCandidateRequestChatRoomMessagesAsRead,
} from './hooks';

type CandidateRequestChatContentProps = {
  candidateRequestId: string;
  accountId: string;
  title: string;
  subtitle: string;
};

const CandidateRequestChatContent = ({
  candidateRequestId,
  accountId,
  title,
  subtitle,
}: CandidateRequestChatContentProps) => {
  const [selectedChatRoomId, setSelectedChatRoomId] = useState<string>('');
  const [chatRooms, setChatRooms] = useState<CandidateRequestChatRoom[]>([]);
  const [messages, setMessages] = useState<CandidateRequestChatRoomMessage[]>(
    []
  );
  const { t } = useTranslation('chatRoom');

  const { createCandidateRequestChatRoomMessage } =
    useCreateCandidateRequestChatRoomMessage();

  const { markMessagesAsRead } =
    useMarkCandidateRequestChatRoomMessagesAsRead();

  const { candidateRequestChatRooms, refetchCandidateRequestChatRooms } =
    useCandidateRequestChatRoomsList({
      candidateRequestId,
      agencyAccountId: accountId,
    });

  const {
    candidateRequestChatRoomMessages,
    isLoading: candidateRequestChatRoomMessagesIsLoading,
    refetchCandidateRequestChatRoomMessages,
  } = useCandidateRequestChatRoomMessagesList({
    candidateRequestChatRoomId: selectedChatRoomId,
  });

  const [
    chatListUnderNegotiation,
    chatListNotUnderNegotiation,
    chatListNoOffer,
    chatListNoOfferNoMessage,
  ]: [
    ChatListProps | undefined,
    ChatListProps | undefined,
    ChatListProps | undefined,
    ChatListProps | undefined,
  ] = useMemo(() => {
    const underNegotiation: ChatListProps = {
      groupeTitle: t('labels.candidateUnderNegotiation'),
      chatRoomList: [],
    };
    const notUnderNegotiation: ChatListProps = {
      groupeTitle: t('labels.candidateNotUnderNegotiation'),
      chatRoomList: [],
    };
    const noOffer: ChatListProps = {
      groupeTitle: t('labels.candidateNoOffer'),
      chatRoomList: [],
    };
    const noOfferNoMessage: ChatListProps = {
      groupeTitle: t('labels.candidateNoOfferNoMessage'),
      chatRoomList: [],
    };

    chatRooms?.forEach((chatRoom) => {
      const chatItem: ChatListItemProps = {
        selected: selectedChatRoomId === chatRoom.id,
        rank: chatRoom.candidateOfferData?.rank ?? undefined,
        title: chatRoom.enterpriseName ?? '',
        preview: chatRoom.enterpriseLastMessage?.message ?? '',
        date: chatRoom.enterpriseLastMessage?.sentAt,
        newMessageCount: chatRoom.unreadMessagesCount,
        onClick: () => setSelectedChatRoomId(chatRoom.id),
      };

      if (chatRoom.candidateOfferData?.isUnderNegotiation) {
        underNegotiation.chatRoomList.push(chatItem);
      } else if (chatRoom.candidateOfferData?.hasMadeOffer) {
        notUnderNegotiation.chatRoomList.push(chatItem);
      } else if (chatRoom.enterpriseLastMessage) {
        noOffer.chatRoomList.push(chatItem);
      } else {
        noOfferNoMessage.chatRoomList.push(chatItem);
      }
    });

    return [
      underNegotiation.chatRoomList.length ? underNegotiation : undefined,
      notUnderNegotiation.chatRoomList.length ? notUnderNegotiation : undefined,
      noOffer.chatRoomList.length ? noOffer : undefined,
      noOfferNoMessage.chatRoomList.length ? noOfferNoMessage : undefined,
    ];
  }, [t, chatRooms, selectedChatRoomId]);

  const selectedChatRoom = useMemo(
    () =>
      candidateRequestChatRooms?.find(
        (chatRoom) => chatRoom.id === selectedChatRoomId
      ),
    [candidateRequestChatRooms, selectedChatRoomId]
  );

  const chatContent: ChatContent = useMemo(
    () => ({
      title: selectedChatRoom?.enterpriseName ?? '',
      messageList: messages.map((message) => {
        const readAtDate = message.readAt ? new Date(message.readAt) : null;
        const now = new Date();

        return {
          position:
            message.accountId === accountId
              ? ('right' as const)
              : ('left' as const),
          date: message.sentAt,
          viewed: !!(readAtDate && readAtDate < now),
          content: message.message,
        };
      }),
    }),
    [messages, accountId, selectedChatRoom?.enterpriseName]
  );

  const handleRefresh = useCallback(async () => {
    refetchCandidateRequestChatRooms();
    refetchCandidateRequestChatRoomMessages();
  }, [
    refetchCandidateRequestChatRooms,
    refetchCandidateRequestChatRoomMessages,
  ]);

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createCandidateRequestChatRoomMessage({
        input: {
          candidateRequestChatRoomId: selectedChatRoomId,
          message,
        },
      });

      if (candidateRequestChatRoomMessages.length < 1) {
        refetchCandidateRequestChatRooms();
      }
    },
    [
      createCandidateRequestChatRoomMessage,
      selectedChatRoomId,
      candidateRequestChatRoomMessages.length,
      refetchCandidateRequestChatRooms,
    ]
  );

  const handleMarkMessagesAsRead = useCallback(async () => {
    await markMessagesAsRead({
      input: { candidateRequestChatRoomId: selectedChatRoomId },
    });
    refetchCandidateRequestChatRooms();
    refetchCandidateRequestChatRoomMessages();
  }, [
    markMessagesAsRead,
    refetchCandidateRequestChatRoomMessages,
    refetchCandidateRequestChatRooms,
    selectedChatRoomId,
  ]);

  useEffect(() => {
    if (!candidateRequestChatRooms) {
      setChatRooms([]);
      return;
    }

    const hasSameContent =
      chatRooms.length === candidateRequestChatRooms.length &&
      chatRooms.every(
        (room, idx) =>
          room.id === candidateRequestChatRooms[idx].id &&
          room.unreadMessagesCount ===
            candidateRequestChatRooms[idx].unreadMessagesCount
      );

    if (!hasSameContent) {
      setChatRooms(candidateRequestChatRooms);
    }
  }, [candidateRequestChatRooms, chatRooms]);

  useEffect(() => {
    if (!candidateRequestChatRoomMessages) {
      setMessages([]);
      return;
    }

    const hasSameContent =
      messages.length === candidateRequestChatRoomMessages.length &&
      messages.every(
        (message, idx) =>
          message.id === candidateRequestChatRoomMessages[idx].id &&
          message.readAt === candidateRequestChatRoomMessages[idx].readAt
      );

    if (!hasSameContent) {
      setMessages(candidateRequestChatRoomMessages);
    }
  }, [candidateRequestChatRoomMessages, messages, candidateRequestChatRooms]);

  useEffect(() => {
    if (candidateRequestChatRooms?.length && !selectedChatRoomId) {
      setSelectedChatRoomId(candidateRequestChatRooms[0].id);
    }
  }, [candidateRequestChatRooms, selectedChatRoomId]);

  return (
    <ChatRoom
      isLoading={candidateRequestChatRoomMessagesIsLoading}
      title={title}
      subtitle={subtitle}
      chatListUnderNegotiation={chatListUnderNegotiation}
      chatListNotUnderNegotiation={chatListNotUnderNegotiation}
      chatListNoOffer={chatListNoOffer}
      chatListNoOfferNoMessage={chatListNoOfferNoMessage}
      chatContent={chatContent}
      backTo={RootPrivatePage.CANDIDATE_REQUESTS}
      onSendMessage={handleSendMessage}
      refresh={handleRefresh}
      markMessagesAsRead={handleMarkMessagesAsRead}
    />
  );
};

export default CandidateRequestChatContent;
