import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  Stack,
  Tooltip,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { RecruitmentOpportunity } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../../types';

type ReplacementGuaranteeFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const ReplacementGuaranteeFields = ({
  recruitmentOpportunity,
}: ReplacementGuaranteeFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const conditionMinGuaranteedPeriodOffered = watch(
    'conditionMinGuaranteedPeriodOffered'
  );
  const conditionMinGuaranteedPeriodBonusAsked = watch(
    'conditionMinGuaranteedPeriodBonusAsked'
  );
  const conditionProbationPeriodAsked = watch('conditionProbationPeriodAsked');

  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.generalContractualConditions;

  return (
    <>
      {/* TO DO: Utiliser le section grid title tooltips de Giovanni */}
      <div className="flex items-center mt-s-32">
        <h4>{t('labels.warantyReplacement')}</h4>
        <Tooltip message={t('labels.warantyReplacementTooltips')}>
          <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
        </Tooltip>
      </div>
      <SectionGrid hideTitle isOffer>
        <SectionGridRow
          isOffer
          isTopRow
          rightChildren={
            <span className="text-16 font-semibold text-primary">
              {recruitmentOpportunityConditions?.minGuaranteedPeriodValue
                ? `${recruitmentOpportunityConditions.bonusMonthValue} ${t('suffix.months')}`
                : '-'}
            </span>
          }
        >
          <Stack className="w-full" space={16}>
            <Controller
              name="conditionMinGuaranteedPeriodOffered"
              control={control}
              render={({ field: { name }, fieldState: { error } }) => (
                <RadioGroup
                  name={name}
                  data={BOOLEAN_VALUES}
                  legendSize="medium"
                  legend={
                    <Trans i18nKey="labels.minGuaranteedPeriodOffered" t={t} />
                  }
                  language={extractLanguage(i18n.language)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, event.target.value === 'true');
                    trigger(name);
                  }}
                  alignRow
                  value={conditionMinGuaranteedPeriodOffered ?? undefined}
                  invalid={!!error}
                  helperText={error?.message && tGlobal(error?.message)}
                />
              )}
            />

            {conditionMinGuaranteedPeriodOffered && (
              <FormInputWithSuffix
                className="mt-s-16"
                label={t('labels.minGuaranteedPeriod')}
                suffix={t('suffix.months')}
                type="number"
                mediumSuffix
                noMargin
                minValue={1}
                invalid={!!errors.conditionMinGuaranteedPeriod}
                helperText={
                  errors.conditionMinGuaranteedPeriod?.message &&
                  tGlobal(errors.conditionMinGuaranteedPeriod?.message)
                }
                {...register('conditionMinGuaranteedPeriod')}
              />
            )}
          </Stack>
        </SectionGridRow>

        {conditionMinGuaranteedPeriodOffered && (
          <SectionGridRow
            isOffer
            rightChildren={
              <span className="text-16 font-semibold text-primary">
                {recruitmentOpportunityConditions?.minGuaranteedPeriodBonusValue
                  ? t('suffix.currency', {
                      value:
                        recruitmentOpportunityConditions.minGuaranteedPeriodBonusValue,
                    })
                  : '-'}
              </span>
            }
          >
            <Stack className="w-full" space={16}>
              <Controller
                name="conditionMinGuaranteedPeriodBonusAsked"
                control={control}
                render={({ field: { name }, fieldState: { error } }) => (
                  <RadioGroup
                    name={name}
                    data={BOOLEAN_VALUES}
                    legendSize="medium"
                    legend={
                      <Trans
                        i18nKey="labels.minGuaranteedPeriodBonusAsked"
                        t={t}
                      />
                    }
                    language={extractLanguage(i18n.language)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setValue(name, event.target.value === 'true');
                      trigger(name);
                    }}
                    alignRow
                    value={conditionMinGuaranteedPeriodBonusAsked ?? undefined}
                    invalid={!!error}
                    helperText={error?.message && tGlobal(error?.message)}
                  />
                )}
              />

              {conditionMinGuaranteedPeriodBonusAsked && (
                <FormInputWithSuffix
                  label={t('labels.minGuaranteedPeriodBonus')}
                  suffix={t('suffix.currency')}
                  type="number"
                  mediumSuffix
                  noMargin
                  minValue={1}
                  maxValue={99999999.99}
                  step={0.01}
                  invalid={!!errors.conditionMinGuaranteedPeriodBonus}
                  helperText={
                    errors.conditionMinGuaranteedPeriodBonus?.message &&
                    tGlobal(errors.conditionMinGuaranteedPeriodBonus?.message, {
                      max: '99,999,999.99',
                      min: '1',
                    })
                  }
                  {...register('conditionMinGuaranteedPeriodBonus')}
                />
              )}
            </Stack>
          </SectionGridRow>
        )}

        {conditionMinGuaranteedPeriodOffered && (
          <SectionGridRow
            isOffer
            rightChildren={
              <span className="text-16 font-semibold text-primary">
                {recruitmentOpportunityConditions?.probationPeriodValue
                  ? t('suffix.week', {
                      count:
                        recruitmentOpportunityConditions.probationPeriodValue,
                    })
                  : '-'}
              </span>
            }
          >
            <Stack className="w-full" space={16}>
              <Controller
                name="conditionProbationPeriodAsked"
                control={control}
                render={({ field: { name }, fieldState: { error } }) => (
                  <RadioGroup
                    name={name}
                    data={BOOLEAN_VALUES}
                    legendSize="medium"
                    legend={
                      <Trans i18nKey="labels.probationPeriodAsked" t={t} />
                    }
                    language={extractLanguage(i18n.language)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setValue(name, event.target.value === 'true');
                      trigger(name);
                    }}
                    alignRow
                    value={conditionProbationPeriodAsked ?? undefined}
                    invalid={!!error}
                    helperText={error?.message && tGlobal(error?.message)}
                  />
                )}
              />

              {conditionProbationPeriodAsked && (
                <FormInputWithSuffix
                  label={t('labels.probationPeriod')}
                  suffix={t('suffix.weeks')}
                  type="number"
                  mediumSuffix
                  noMargin
                  invalid={!!errors.conditionProbationPeriod}
                  helperText={
                    errors.conditionProbationPeriod?.message &&
                    tGlobal(errors.conditionProbationPeriod?.message)
                  }
                  {...register('conditionProbationPeriod')}
                />
              )}
            </Stack>
          </SectionGridRow>
        )}
      </SectionGrid>
    </>
  );
};

export default ReplacementGuaranteeFields;
