import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  RadioGroup,
  SectionGrid,
  SectionGridRow,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { extractLanguage } from '@utils/i18n-utils';

import { RequestFormFields } from '../../schema';

type InternationalRecruitmentFieldsProps = {
  control: Control<RequestFormFields, any>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const InternationalRecruitmentFields = ({
  control,
  setValue,
  trigger,
  watch,
}: InternationalRecruitmentFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const mandatoryWorkPermit = watch(
    'internationalRecruitmentConditionMandatoryWorkPermitValue'
  );

  return (
    <SectionGrid
      title={t('subtitles.forInternationalRecruitment')}
      className="mt-s-40"
      fullWidth
    >
      <SectionGridRow isTopRow>
        <Controller
          name="internationalRecruitmentConditionMandatoryWorkPermitValue"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.validWorkPermit')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as any);
                trigger(name);
              }}
              legendSize="medium"
              alignRow
              value={mandatoryWorkPermit ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default InternationalRecruitmentFields;
