import { cn } from '@utils/lib-utils';

type FileProps = {
  link: string;
  text: string;
  className?: string;
  onClick?: () => void;
};

const File = ({
  link,
  text,
  className: customClassName,
  onClick,
}: FileProps) => {
  const className = cn(
    customClassName,
    'flex items-center gap-s-16 group w-fit'
  );

  const iconClassName =
    'flex justify-center items-center text-24 h-s-40 w-s-40 bg-secondary border rounded-md border-transparent group-hover:border-stroke-default group-hover:bg-transparent focus:bg-transparent active:bg-transparent';

  return (
    <a
      className={className}
      href={link}
      target="_blank"
      aria-label={text}
      rel="noreferrer"
      onClick={onClick}
    >
      <div className={iconClassName}>
        <i className="ri-folder-info-line" />
      </div>

      <span className="text-16 font-semibold underline">{text}</span>
    </a>
  );
};

export default File;
