import { DateTime } from 'luxon';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { IconButton } from '@application/components';
import { TextInput } from '@application/components/form/text-input-field/textInput';
import { cn } from '@utils/lib-utils';

import ChatListItem, { ChatListItemProps } from './ChatListItem';
import DateSeparator from './DateSeparator';
import MessageItem, { MessageItemProps } from './MessageItem';

export type ChatContent = {
  title?: string;
  messageList: MessageItemProps[];
};

export type ChatListProps = {
  groupeTitle: string;
  chatRoomList: ChatListItemProps[];
};

type ChatRoomProps = {
  title: string;
  subtitle: string;
  chatListUnderNegotiation?: ChatListProps;
  chatListNotUnderNegotiation?: ChatListProps;
  chatListNoOffer?: ChatListProps;
  chatListNoOfferNoMessage?: ChatListProps;
  chatContent?: ChatContent;
  isLoading: boolean;
  backTo: string;
  onSendMessage: (message: string) => void;
  refresh: () => void;
  markMessagesAsRead: () => void;
};

const ChatRoom = ({
  title,
  subtitle,
  chatListUnderNegotiation,
  chatListNotUnderNegotiation,
  chatListNoOffer,
  chatListNoOfferNoMessage,
  chatContent,
  isLoading,
  backTo,
  onSendMessage,
  refresh,
  markMessagesAsRead,
}: ChatRoomProps) => {
  const [newMessage, setNewMessage] = useState('');
  const messagesWrapperRef = useRef<HTMLDivElement>(null);
  const messageRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.from;
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('chatRoom');

  const onBackClick = useCallback(
    () => navigate(redirectTo || backTo),
    [backTo, navigate, redirectTo]
  );

  const scrollToBottom = useCallback(() => {
    if (!messagesWrapperRef.current) return;

    messagesWrapperRef.current.scrollTop =
      messagesWrapperRef.current.scrollHeight;
  }, []);

  const scrollToIndex = useCallback((index: number) => {
    const el = messageRefs.current.get(index);

    if (!el || !messagesWrapperRef.current) return;

    const containerTop = messagesWrapperRef.current.getBoundingClientRect().top;
    const elementTop = el.getBoundingClientRect().top;
    const offset = elementTop - containerTop;

    messagesWrapperRef.current.scrollTop += offset;
  }, []);

  const handleSendMessage = useCallback(() => {
    if (!newMessage.trim()) return;
    onSendMessage(newMessage);
    setNewMessage('');

    setTimeout(() => {
      scrollToBottom();
    }, 0);
  }, [newMessage, onSendMessage, scrollToBottom]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const messageList = useMemo(() => {
    const now = DateTime.now();
    let yesterdaySeparatorRendered = false;
    let todaySeparatorRendered = false;

    return chatContent?.messageList.map((message, i) => {
      const messageDate = DateTime.fromISO(message.date);
      let dateSeparator = null;

      if (
        !yesterdaySeparatorRendered &&
        messageDate.hasSame(now.minus({ days: 1 }), 'day')
      ) {
        yesterdaySeparatorRendered = true;
        dateSeparator = <DateSeparator type="yesterday" />;
      }

      if (!todaySeparatorRendered && messageDate.hasSame(now, 'day')) {
        todaySeparatorRendered = true;
        dateSeparator = <DateSeparator type="today" />;
      }

      const ref = (el: HTMLDivElement | null) => {
        messageRefs.current.set(i, el);
      };

      return (
        <Fragment key={`message-${i}`}>
          {dateSeparator}

          <MessageItem
            ref={ref}
            position={message.position}
            date={message.date}
            viewed={message.viewed}
            content={message.content}
            isSystem={message.isSystem}
          />
        </Fragment>
      );
    });
  }, [chatContent?.messageList]);

  useEffect(() => {
    if (!chatContent?.messageList.length) {
      return;
    }

    const firstUnreadLeftIndex = chatContent.messageList.findIndex(
      (message) => message.position === 'left' && !message.viewed
    );

    if (firstUnreadLeftIndex === -1) {
      scrollToBottom();
      return;
    }

    const targetIndex =
      firstUnreadLeftIndex > 0
        ? firstUnreadLeftIndex - 1
        : firstUnreadLeftIndex;
    scrollToIndex(targetIndex);
  }, [scrollToBottom, scrollToIndex, refresh, chatContent?.messageList]);

  useEffect(() => {
    const wrapper = messagesWrapperRef.current;

    if (!wrapper) {
      return () => {};
    }

    const handleScroll = () => {
      const isAtBottom =
        wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight - 5;

      if (!isAtBottom) return;

      const hasUnreadLeft = chatContent?.messageList.some(
        (message) => message.position === 'left' && !message.viewed
      );

      if (hasUnreadLeft) {
        markMessagesAsRead();
      }
    };

    const isScrollable = wrapper.scrollHeight > wrapper.clientHeight + 5;

    if (!isScrollable) {
      const timerId = setTimeout(() => {
        const hasUnreadLeft = chatContent?.messageList.some(
          (m) => m.position === 'left' && !m.viewed
        );
        if (hasUnreadLeft) {
          markMessagesAsRead();
        }
      }, 2000);

      return () => {
        clearTimeout(timerId);
      };
    }

    wrapper.addEventListener('scroll', handleScroll);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, [chatContent?.messageList, markMessagesAsRead]);

  const classMainWrapper = 'h-full';
  const classHeaderWrapper = 'h-s-48 flex items-center gap-s-16';
  const classTitle =
    'flex-shrink max-w-[60%] overflow-ellipsis overflow-hidden whitespace-nowrap h3';
  const classSubtitle =
    'flex-grow overflow-ellipsis overflow-hidden whitespace-nowrap text-neutral-secondary';
  const classContentWrapper =
    'mt-s-24 h-[calc(100%-3rem-1.5rem)] flex gap-s-16';
  const classLeftContentWrapper =
    'w-s-160 md:w-s-256 lg:w-[360px] flex-shrink-0 overflow-y-auto';
  const classLeftContentSeparator = 'text-14 border-b mb-s-16 pb-s-4';
  const classRightContentWrapper = 'relative flex-grow border rounded-md';
  const classChatContentTitle =
    'h-s-64 flex items-center px-s-16 text-18 font-semibold border-b';
  const classMessagesWrapper = cn('h-full p-s-16 overflow-auto', {
    'h-[calc(100%-3rem)]': chatContent?.title === undefined,
    'h-[calc(100%-4rem-3rem)]': chatContent?.title !== undefined,
  });
  const classInputWrapper = 'flex border-t h-s-48';
  const classInput =
    'flex-grow px-s-16 rounded-tl-none rounded-br-none rounded-tr-none border-t-transparent border-l-transparent';
  const classSendButton = 'rounded-t-none rounded-bl-none';

  return (
    <div className={classMainWrapper}>
      <div className={classHeaderWrapper}>
        <IconButton
          type="button"
          icon={<i className="ri-arrow-left-line" />}
          text={tGlobal('button.backPreviousPage')}
          onClick={onBackClick}
        />

        <h1 className={classTitle}>{title}</h1>
        <div className={classSubtitle}>{subtitle}</div>

        <IconButton
          type="button"
          icon={<i className="ri-refresh-line" />}
          text="refresh"
          onClick={refresh}
        />
      </div>

      <div className={classContentWrapper}>
        {(chatListUnderNegotiation ||
          chatListNotUnderNegotiation ||
          chatListNoOffer ||
          chatListNoOfferNoMessage) && (
          <div className={classLeftContentWrapper}>
            {chatListUnderNegotiation && (
              <>
                <div className={classLeftContentSeparator}>
                  {chatListUnderNegotiation.groupeTitle}
                </div>

                {chatListUnderNegotiation.chatRoomList.map((chat, i) => (
                  <ChatListItem
                    key={i}
                    selected={chat.selected}
                    rank={chat.rank}
                    title={chat.title}
                    preview={chat.preview}
                    date={chat.date}
                    newMessageCount={chat.newMessageCount}
                    onClick={chat.onClick}
                  />
                ))}
              </>
            )}

            {chatListNotUnderNegotiation && (
              <>
                <div className={classLeftContentSeparator}>
                  {chatListNotUnderNegotiation.groupeTitle}
                </div>

                {chatListNotUnderNegotiation.chatRoomList.map((chat, i) => (
                  <ChatListItem
                    key={i}
                    selected={chat.selected}
                    title={chat.title}
                    preview={chat.preview}
                    date={chat.date}
                    newMessageCount={chat.newMessageCount}
                    onClick={chat.onClick}
                  />
                ))}
              </>
            )}

            {chatListNoOffer && (
              <>
                <div className={classLeftContentSeparator}>
                  {chatListNoOffer.groupeTitle}
                </div>

                {chatListNoOffer.chatRoomList.map((chat, i) => (
                  <ChatListItem
                    key={i}
                    selected={chat.selected}
                    title={chat.title}
                    preview={chat.preview}
                    date={chat.date}
                    newMessageCount={chat.newMessageCount}
                    onClick={chat.onClick}
                  />
                ))}
              </>
            )}

            {chatListNoOfferNoMessage && (
              <>
                <div className={classLeftContentSeparator}>
                  {chatListNoOfferNoMessage.groupeTitle}
                </div>

                {chatListNoOfferNoMessage.chatRoomList.map((chat, i) => (
                  <ChatListItem
                    key={i}
                    selected={chat.selected}
                    title={chat.title}
                    preview={chat.preview}
                    date={chat.date}
                    newMessageCount={chat.newMessageCount}
                    onClick={chat.onClick}
                  />
                ))}
              </>
            )}
          </div>
        )}

        <div className={classRightContentWrapper}>
          {chatContent?.title && (
            <div className={classChatContentTitle}>{chatContent?.title}</div>
          )}

          <div ref={messagesWrapperRef} className={classMessagesWrapper}>
            {messageList}
          </div>

          <div className={classInputWrapper}>
            <TextInput
              id="newMessage"
              name="newMessage"
              className={classInput}
              placeholder={
                chatContent?.title
                  ? t('placeholders.writeTo', {
                      field: chatContent.title,
                    })
                  : t('placeholders.writeHere')
              }
              type="text"
              value={newMessage}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              autoComplete="off"
            />

            <IconButton
              className={classSendButton}
              icon={<i className="ri-send-plane-2-line" />}
              text={t('button.send')}
              primary
              onClick={handleSendMessage}
              disabled={isLoading || !newMessage.trim()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatRoom;
