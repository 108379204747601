import { useTranslation } from 'react-i18next';

import {
  PlaceholderSwitcher,
  SectionGridRow,
  VisibilitySwitcher,
} from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';

type InternationalRecruitmentConditionsProps = {
  data: RecruitmentOpportunity | undefined;
};

const InternationalRecruitmentConditions = ({
  data,
}: InternationalRecruitmentConditionsProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });
  const { t: tGlobal } = useTranslation();

  return (
    <SectionGridRow isTopRow>
      <span className="mr-s-8">{t('labels.validWorkPermit')}</span>

      <VisibilitySwitcher
        visible={
          data?.internationalRecruitmentContractualConditions
            ?.mandatoryWorkPermitDisplay || false
        }
        content={
          <PlaceholderSwitcher
            value={
              data?.internationalRecruitmentContractualConditions
                ?.mandatoryWorkPermitValue
            }
          >
            <span className="text-18 font-semibold text-primary">
              {tGlobal(
                `boolean.${!!data?.internationalRecruitmentContractualConditions
                  ?.mandatoryWorkPermitValue}`
              )}
            </span>
          </PlaceholderSwitcher>
        }
      />
    </SectionGridRow>
  );
};

export default InternationalRecruitmentConditions;
