import { ReactNode } from 'react';

import { cn } from '@utils/lib-utils';

type FieldsetProps = {
  legend: ReactNode;
  children: ReactNode;
  legendSize?: 'small' | 'medium' | 'big';
  hideLegend?: boolean;
  className?: string;
  legendClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
  /**
   * Specify if the `<legend>` and children should be aligned in a row
   */
  alignRow?: boolean;
  invalid?: boolean;
};

const Fieldset = ({
  className: customClassName,
  legendClassName: customLegendClassName,
  contentClassName,
  wrapperClassName: customWrapperClassName,
  legend,
  hideLegend = false,
  children,
  legendSize,
  alignRow,
  invalid,
}: FieldsetProps) => {
  const className = cn(customClassName);
  const wrapperClassName = cn(customWrapperClassName, {
    'flex flex-row items-center flex-wrap': alignRow,
  });
  const legendClassName = cn(
    'text-16 font-semibold',
    {
      'sr-only': hideLegend,
      'label text-14 pt-s-0 pl-s-0 text-neutral-secondary font-normal -mb-s-16':
        legendSize === 'small',
      'label text-16 pt-s-0 text-neutral-secondary font-normal pl-s-0 !pb-s-0 pr-s-16':
        legendSize === 'medium',
      'text-20': legendSize === 'big',
      'text-error-content': invalid,
    },
    customLegendClassName
  );
  const contentWrapperClassName = cn(
    { 'mt-s-16': !hideLegend && !alignRow },
    contentClassName
  );

  return (
    <fieldset className={className}>
      <div className={wrapperClassName}>
        <legend className={legendClassName}>{legend}</legend>
        <div className={contentWrapperClassName}>{children}</div>
      </div>
    </fieldset>
  );
};

export default Fieldset;
