import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import {
  ConditionRemunerationType,
  Maybe,
  Offer,
  Request,
} from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type GeneralConditionsProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const GeneralConditions = ({ data, request }: GeneralConditionsProps) => {
  const { t, i18n } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tGlobal } = useTranslation();

  const offerValueClassName = 'text-16 font-semibold text-primary';

  const conditions = data?.generalContractualConditions;
  const requestConditions = request?.generalContractualConditions;

  const displayHourlyRate = useMemo(() => {
    const {
      remunerationHourlyRate: rate,
      remunerationEstimatedHoursMin: hoursMin,
      remunerationEstimatedHoursMax: hoursMax,
    } = conditions || {};

    if (!rate || !hoursMin || !hoursMax) {
      return t('labels.remunerationType.hourlyRateOnly');
    }

    return hoursMin !== hoursMax
      ? t('labels.remunerationType.hourlyRate', { rate, hoursMin, hoursMax })
      : t('labels.remunerationType.hourlyRateMinMax', {
          rate,
          hours: hoursMin,
        });
  }, [conditions, t]);

  const remuneration = useMemo(() => {
    switch (conditions?.remunerationType) {
      case ConditionRemunerationType.AnnualSalaryPercentage:
        return conditions?.remunerationPercentage
          ? t('labels.remunerationType.salaryPercentage', {
              amount: conditions.remunerationPercentage,
            })
          : t('labels.remunerationType.salaryPercentageOnly');
      case ConditionRemunerationType.FixedAmount:
        return conditions?.remunerationAmount
          ? t('labels.remunerationType.fixedAmount', {
              amount: conditions.remunerationAmount,
            })
          : t('labels.remunerationType.fixedAmountOnly');
      case ConditionRemunerationType.HourlyRate:
        return displayHourlyRate;
      default:
        return '-';
    }
  }, [conditions, displayHourlyRate, t]);

  const getBudgetRange = useMemo(() => {
    const formatCost = (cost: Maybe<string> | undefined) => {
      if (!cost) return 0;
      return Math.floor(Number(cost));
    };

    const formattedMin = formatCost(data?.totalCostsMin);
    const formattedMax = formatCost(data?.totalCostsMax);
    const formattedTotal = formatCost(data?.totalCosts);

    if (
      data?.totalCostsMin &&
      data?.totalCostsMax &&
      formattedMax === formattedMin
    ) {
      return (
        <span>
          <span className="mr-s-8">{t('labels.totalCosts')}</span>
          <span className={offerValueClassName}>
            {tGlobal('suffix.currencyValue', {
              value: formattedMax,
            })}
          </span>
          <span className="ml-s-32">({t('labels.perCandidateHired')})</span>
        </span>
      );
    }

    switch (conditions?.remunerationType) {
      case ConditionRemunerationType.AnnualSalaryPercentage:
      case ConditionRemunerationType.HourlyRate: {
        return (
          <div className="flex">
            <span className="mr-s-32">
              <span className="mr-s-8">{t('labels.totalCostsMin')}</span>
              <span className={offerValueClassName}>
                {tGlobal('suffix.currencyValue', {
                  value: formattedMin,
                })}
              </span>
            </span>
            |
            <span className="ml-s-32">
              <span className="mr-s-8">{t('labels.totalCostsMax')}</span>
              <span className={offerValueClassName}>
                {tGlobal('suffix.currencyValue', {
                  value: formattedMax,
                })}
              </span>
              <span className="ml-s-32">({t('labels.perCandidateHired')})</span>
            </span>
          </div>
        );
      }
      case ConditionRemunerationType.FixedAmount:
      default:
        return (
          <span>
            <span className="mr-s-8">{t('labels.totalCosts')}</span>
            <span className={offerValueClassName}>{formattedTotal}$</span>
            <span> ({t('labels.perCandidateHired')})</span>
          </span>
        );
    }
  }, [
    conditions?.remunerationType,
    data?.totalCosts,
    data?.totalCostsMax,
    data?.totalCostsMin,
    t,
    tGlobal,
    offerValueClassName,
  ]);

  const getContentValue = useCallback(
    (
      type: string,
      value: string | number | boolean | null | undefined,
      isShort?: boolean
    ) => {
      const shortSuffix = isShort ? 'short.' : '';

      switch (type) {
        case 'weeks':
          return value ? t('suffix.week', { count: Number(value) }) : '-';
        case 'months':
          return value ? t('suffix.month', { count: Number(value) }) : '-';
        case 'text':
          return value
            ? t(
                `enum.requestConditionPaymentMethodCode.${shortSuffix}${
                  value.toString().toLowerCase() as string
                }`
              )
            : '-';
        case 'frequency':
          return value
            ? t(
                `enum.requestConditionPaymentFrequencyCode.${shortSuffix}${
                  value.toString().toLowerCase() as string
                }`
              )
            : '-';
        case 'currency':
          return value
            ? tGlobal('suffix.currencyValue', { value: Number(value) })
            : '-';
        case 'date':
          return value ? formatDate(String(value), i18n.language) : '-';
        default:
          return '-';
      }
    },
    [t, tGlobal, i18n.language]
  );
  return (
    <>
      {!data?.candidates?.length && (
        <SectionGridRow
          isTopRow
          isOffer
          rightChildren={
            <PlaceholderSwitcher
              value={requestConditions?.maxRecruitmentPeriodValue}
            >
              <span className="text-16 font-semibold text-primary">
                {getContentValue(
                  'weeks',
                  requestConditions?.maxRecruitmentPeriodValue
                )}
              </span>
            </PlaceholderSwitcher>
          }
        >
          <span className="mr-s-8">{t('labels.maxRecruitmentPeriod')}</span>

          {conditions?.maxRecruitmentPeriod ? (
            <span className="text-16 font-semibold text-primary">
              {t('suffix.week', {
                count: Number(conditions?.maxRecruitmentPeriod),
              })}
            </span>
          ) : (
            <span>-</span>
          )}
        </SectionGridRow>
      )}

      <SectionGridRow
        isOffer
        rightChildren={
          <div className="flex flex-col">
            <span className="text-16 text-center font-semibold text-primary">
              {t('labels.baseBudget')}
            </span>
            <div className="flex flex-col">
              <span className="text-16 text-center font-semibold text-primary  mt-s-18">
                {t('labels.min')}
                {tGlobal('suffix.currencyValue', {
                  value:
                    request?.generalContractualConditions.minimumBudgetValue,
                })}
              </span>
              <span className="text-16 text-center font-semibold text-primary  mt-s-18">
                {t('labels.max')}
                {tGlobal('suffix.currencyValue', {
                  value:
                    request?.generalContractualConditions.maximumBudgetValue,
                })}
              </span>
            </div>
          </div>
        }
      >
        <div className="w-full flex flex-col h-full">
          <div className="flex items-center h-1/2">
            <span className="mr-s-8">{t('labels.remuneration')}</span>
            <span className="text-16 font-semibold text-primary">
              {remuneration}
            </span>
          </div>

          <div className="relative flex items-center h-1/2">
            <hr className="absolute top-s-0 h-px w-[calc(100%-2rem)] border-t" />
            <div className="pt-2">{getBudgetRange}</div>
          </div>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <PlaceholderSwitcher
            value={
              requestConditions?.paymentMethodValue ||
              requestConditions?.paymentFrequencyValue
            }
          >
            <div className="flex flex-col">
              <div className="mr-s-8">
                <span className="text-16 font-semibold text-primary">
                  {getContentValue(
                    'text',
                    requestConditions?.paymentMethodValue,
                    false
                  )}
                </span>
              </div>
              {requestConditions?.paymentFrequencyValue && (
                <div className="mr-s-8 mt-s-24">
                  <span className="text-16 font-semibold text-primary">
                    {getContentValue(
                      'frequency',
                      requestConditions?.paymentFrequencyValue,
                      false
                    )}
                  </span>
                </div>
              )}
            </div>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.paymentMethod')}&nbsp;
            <span className="text-16 font-semibold text-primary">
              {conditions?.paymentMethod
                ? t(
                    `enum.requestConditionPaymentMethodCode.${conditions?.paymentMethod?.toLowerCase()}`
                  )
                : '-'}
            </span>
          </div>
          {conditions?.paymentFrequency && (
            <div className="mr-s-8 mt-s-24 flex">
              <span className="mr-s-8">{t(`labels.paymentFrequency`)}</span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.paymentFrequency
                  ? t(
                      `enum.requestConditionPaymentFrequencyCode.${conditions?.paymentFrequency?.toLowerCase()}`
                    )
                  : '-'}
              </span>
            </div>
          )}
        </div>
      </SectionGridRow>
    </>
  );
};

export default GeneralConditions;
