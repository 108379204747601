import { gql } from '@urql/core';

const MutationOfferCandidateUpdateSeenAt = gql`
  mutation OfferCandidateUpdateSeenAt($input: OfferCandidateUpdateInput!) {
    offerCandidateUpdateSeenAt(input: $input) {
      offerCandidate {
        id
        offerId
        seenAt
      }
    }
  }
`;

export default MutationOfferCandidateUpdateSeenAt;
