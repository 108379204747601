import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import { AccountFragment } from '../../account';
import {
  OfferGeneralConditionsFragment,
  OfferHeadhunterConditionsFragment,
  OfferInternationalRecruitmentConditionsFragment,
  OfferOutsourcingConditionsFragment,
  OfferTemporaryPlacementConditionsFragment,
} from '../../offer/conditions';
import OfferCandidateFragment from '../../offer/offer.candidate.fragment';

const RequestOfferFragment = gql`
  ${AccountFragment}
  ${OfferOutsourcingConditionsFragment}
  ${OfferInternationalRecruitmentConditionsFragment}
  ${OfferTemporaryPlacementConditionsFragment}
  ${OfferHeadhunterConditionsFragment}
  ${OfferGeneralConditionsFragment}
  ${DescriptionsFragment}
  ${OfferCandidateFragment}

  fragment RequestOfferFragment on Offer {
    id
    accountId
    requestId
    userId
    negotiationRoomId
    status
    rejectionReason
    rejectionExplanation
    linkProfile
    requestTypes
    matchingScore
    totalCosts
    totalCostsMin
    totalCostsMax
    rank
    progressionStatuses {
      receptionStatus
      candidateStatus
      contractualTermsStatus
      finalDecisionStatus
    }
    generalContractualConditions {
      ...OfferGeneralConditionsFragment
    }
    headhunterContractualConditions {
      ...OfferHeadhunterConditionsFragment
    }
    temporaryPlacementContractualConditions {
      ...OfferTemporaryPlacementConditionsFragment
    }
    internationalRecruitmentContractualConditions {
      ...OfferInternationalRecruitmentConditionsFragment
    }
    outsourcingContractualConditions {
      ...OfferOutsourcingConditionsFragment
    }
    candidates {
      ...OfferCandidateFragment
    }
    createdAt
    updatedAt
    seenAt
    matchingScore
    account {
      ...AccountFragment
    }
    user {
      email
    }
    availableTransitions
    resumeCount
  }
`;

export default RequestOfferFragment;
