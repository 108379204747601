import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { OfferConditionOtherBonusCode, Request } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type OtherBonusesOfferedProps = {
  data: Request | undefined;
};

const OtherBonusesOffered = ({ data }: OtherBonusesOfferedProps) => {
  const { t, i18n } = useTranslation('requests');

  const showCandidateCompanyLongivity =
    data?.generalContractualConditions.otherBonuses?.includes(
      OfferConditionOtherBonusCode.CandidateCompanyLongivity
    ) &&
    (data?.generalContractualConditions.bonusMonthValue ||
      data?.generalContractualConditions.bonusValue);
  const showHiringSpeed =
    data?.generalContractualConditions.otherBonuses?.includes(
      OfferConditionOtherBonusCode.HiringSpeed
    ) &&
    (data?.generalContractualConditions.hiredDateBonusRequirement ||
      data?.generalContractualConditions.hiredDateBonusValue);
  const showOther =
    data?.generalContractualConditions.otherBonuses?.includes(
      OfferConditionOtherBonusCode.Other
    ) &&
    (data?.generalContractualConditions.otherBonusDescription ||
      data?.generalContractualConditions.otherBonusValue);

  return (
    (showCandidateCompanyLongivity || showHiringSpeed || showOther) && (
      <>
        {showCandidateCompanyLongivity && (
          <>
            <SectionGridRow isTopRow>
              <span className="mr-s-8">
                {t('labels.otherBonusesOfferedDuration')}
                <span className="text-18 font-semibold text-primary">
                  {data?.generalContractualConditions.bonusMonthValue}
                </span>{' '}
                {t('suffix.weeks')}
              </span>
            </SectionGridRow>

            {data?.generalContractualConditions.bonusValue && (
              <SectionGridRow partialBorderTop>
                <span className="mr-s-8">
                  {t('labels.otherBonusesOfferedAmountShort')}
                </span>
                <span className="text-18 font-semibold text-primary">
                  {t('suffix.currencyValue', {
                    value: data?.generalContractualConditions.bonusValue,
                  })}
                </span>
              </SectionGridRow>
            )}
          </>
        )}

        {showHiringSpeed && (
          <>
            <SectionGridRow isTopRow={!showCandidateCompanyLongivity}>
              <span className="mr-s-8">
                {t('labels.otherBonusesOfferedHiringSpeed')}
                <span className="text-18 font-semibold text-primary">
                  {formatDate(
                    data?.generalContractualConditions
                      .hiredDateBonusRequirement,
                    i18n.language
                  )}
                </span>
              </span>
            </SectionGridRow>

            {data?.generalContractualConditions.hiredDateBonusValue && (
              <SectionGridRow partialBorderTop>
                <span className="mr-s-8">
                  {t('labels.otherBonusesOfferedAmountShort')}
                </span>
                <span className="text-18 font-semibold text-primary">
                  {t('suffix.currencyValue', {
                    value:
                      data?.generalContractualConditions.hiredDateBonusValue,
                  })}
                </span>
              </SectionGridRow>
            )}
          </>
        )}

        {showOther && (
          <>
            <SectionGridRow
              isTopRow={!showCandidateCompanyLongivity && !showHiringSpeed}
              className="block"
            >
              <div className="mr-s-8">
                {t('labels.otherBonusesOfferedOther')}
              </div>
              <span className="text-18 font-semibold text-primary">
                {data?.generalContractualConditions.otherBonusDescription}
              </span>
            </SectionGridRow>

            {data?.generalContractualConditions.otherBonusValue && (
              <SectionGridRow partialBorderTop>
                <span className="mr-s-8">
                  {t('labels.otherBonusesOfferedAmountShort')}
                </span>
                <span className="text-18 font-semibold text-primary">
                  {t('suffix.currencyValue', {
                    value: data?.generalContractualConditions.otherBonusValue,
                  })}
                </span>
              </SectionGridRow>
            )}
          </>
        )}
      </>
    )
  );
};

export default OtherBonusesOffered;
