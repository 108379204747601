import * as yup from 'yup';

import {
  AccountAdvantageTypeCode,
  AccountIndustryType,
  EducationLevelCode,
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
  JobScheduleTypeCode,
  OfferConditionOtherBonusCode,
  OperationTerritoryCode,
  RequestConditionPaymentFrequencyCode,
  RequestConditionPaymentMethodCode,
  RequestConditionResponsibility,
  RequestConditionStartOfAgreementPaymentMethodCode,
  RequestPriorityType,
  RequestTypeCode,
} from '@domain/graphql.types';
import { validateDecimal } from '@utils/yup-utils';

// The schema is ordered like the model in api/domains
export const REQUEST_SCHEMA = yup.object({
  antiGhostingConsent: yup
    .boolean()
    .oneOf([true], 'validations.required.antiGhostingConsent')
    .nullable(),
  operationUnitId: yup.string().nullable(),
  types: yup
    .array(yup.mixed<RequestTypeCode>().oneOf(Object.values(RequestTypeCode)))
    .nullable(),
  openToInternationalRecruitment: yup.boolean().nullable(),
  sectorCode: yup.string().nullable(),
  citiesSubRegions: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .nullable(),
  publishNow: yup.boolean().nullable(),
  priority: yup
    .mixed<RequestPriorityType>()
    .oneOf(Object.values(RequestPriorityType))
    .nullable(),
  isImportant: yup.boolean().nullable(),
  industryType: yup
    .mixed<AccountIndustryType>()
    .oneOf(Object.values(AccountIndustryType)),
  industrySector: yup.string(),
  usePreferredSpecialties: yup.boolean(),
  jobSpecialtyCode: yup.string().nullable(),
  specialty: yup.string().nullable().max(128, 'validations.maxLength'),
  jobInternalTitle: yup.string().nullable().max(128, 'validations.maxLength'),
  desiredStartDate: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  receivingOfferDeadline: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  operationTerritoryCodes: yup
    .array(
      yup
        .mixed<OperationTerritoryCode>()
        .oneOf(Object.values(OperationTerritoryCode))
    )
    .nullable(),
  jobExperienceLevelMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(0, 'validations.minNumber')
    .max(44, 'validations.maxNumber'),
  jobExperienceLevelMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(45, 'validations.maxNumber'),
  desiredEducationLevelCodes: yup.array(
    yup.mixed<EducationLevelCode>().oneOf(Object.values(EducationLevelCode))
  ),
  desiredStudyField: yup.string().nullable().max(128, 'validations.maxLength'),
  jobDurationCode: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .nullable(),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  jobWorkingHours: yup
    .array(
      yup.mixed<JobScheduleTypeCode>().oneOf(Object.values(JobScheduleTypeCode))
    )
    .nullable(),
  perks: yup
    .array(
      yup
        .mixed<AccountAdvantageTypeCode>()
        .oneOf(Object.values(AccountAdvantageTypeCode))
    )
    .nullable(),
  otherPerks: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .nullable(),
  softSkills: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .nullable(),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .nullable(),
  jobFullTimeAvailability: yup.boolean().nullable(),
  jobPartTimeAvailability: yup.boolean().nullable(),
  jobAvailabilityInHoursPerWeek: yup.array(yup.string()).nullable(),
  jobRemoteModeInDays: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber'),
  jobOpenPositions: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  jobSalaryMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  jobSalaryMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  jobPaymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .nullable(),
  jobHourlyRateMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  jobHourlyRateMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  jobHourlyBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  jobRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  jobOtherRemunerationAmount: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  jobPaymentOtherTypesCodes: yup
    .array(
      yup
        .mixed<JobPaymentOtherTypesCode>()
        .oneOf(Object.values(JobPaymentOtherTypesCode))
    )
    .nullable(),
  jobAdditionalNotes: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength'),
  generalNotes: yup.string().nullable().max(3000, 'validations.maxLength'),
  jobDescription: yup.string().nullable().max(3000, 'validations.maxLength'),

  /* GENERAL CONDITIONS */
  conditionMinGuaranteedPeriodAsked: yup.boolean().nullable(),
  conditionMinGuaranteedPeriodBonusOffered: yup.boolean().nullable(),
  conditionMaxRecruitmentPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionMaxRecruitmentPeriodDisplay: yup.boolean().nullable(),
  conditionCandidateInHandMandatoryValue: yup.boolean().nullable(),
  conditionCandidateInHandMandatoryDisplay: yup.boolean().nullable(),
  conditionMinimumBudgetValue: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(3500, 'validations.minNumber')
    .max(100000, 'validations.maxNumber')
    .lessThan(100000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionMaximumBudgetValue: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(3500, 'validations.minNumber')
    .max(100000, 'validations.maxNumber')
    .lessThan(100000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionMinGuaranteedPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionMinGuaranteedPeriodBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionMinGuaranteedPeriodDisplay: yup.boolean().nullable(),
  conditionProbationPeriodOffered: yup.boolean().nullable(),
  conditionProbationPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionProbationPeriodDisplay: yup.boolean().nullable(),
  conditionPaymentMethodValue: yup
    .mixed<RequestConditionPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionPaymentMethodCode))
    .nullable(),
  conditionPaymentAmountValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionDepositDisplay: yup.boolean().nullable(),
  conditionDepositValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionPaymentFrequencyValue: yup
    .mixed<RequestConditionPaymentFrequencyCode>()
    .oneOf(Object.values(RequestConditionPaymentFrequencyCode))
    .nullable(),
  conditionPaymentMethodDisplay: yup.boolean().nullable(),
  conditionBudgetDisplay: yup.boolean().nullable(),
  conditionHiredDateBonusDisplay: yup.boolean().nullable(),
  conditionBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionBonusMonthValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionHiredDateBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionHiredDateBonusRequirement: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  conditionBonusDisplay: yup.boolean().nullable(),
  conditionOtherBonusesOffered: yup.boolean().nullable(),
  conditionOtherBonuses: yup
    .array(
      yup
        .mixed<OfferConditionOtherBonusCode>()
        .oneOf(Object.values(OfferConditionOtherBonusCode))
    )
    .nullable(),
  conditionOtherBonusDescription: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength'),
  conditionOtherBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  /* HEADHUNTER CONDITIONS */
  headhunterConditionExclusivityPeriodOffered: yup.boolean().nullable(),
  headhunterConditionMaximumReplacementValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  headhunterConditionMaximumReplacementDisplay: yup.boolean().nullable(),
  headhunterConditionExclusivityPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  headhunterConditionExclusivityPeriodDisplay: yup.boolean().nullable(),

  /* INTERNATIONAL RECRUITMENT CONDITIONS */
  internationalRecruitmentConditionMandatoryWorkPermitValue: yup
    .boolean()
    .nullable(),
  internationalRecruitmentConditionMandatoryWorkPermitDisplay: yup
    .boolean()
    .nullable(),
  internationalRecruitmentConditionExclusivityPeriodDisplay: yup
    .boolean()
    .nullable(),
  internationalRecruitmentConditionMaximumReplacementDisplay: yup
    .boolean()
    .nullable(),

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumDurationValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  outsourcingConditionMaximumDurationDisplay: yup.boolean().nullable(),
  outsourcingConditionExclusivityPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  outsourcingConditionExclusivityPeriodDisplay: yup.boolean().nullable(),
  outsourcingConditionMaximumBudgetValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  outsourcingConditionMaximumBudgetDisplay: yup.boolean().nullable(),
  outsourcingConditionResponsibilities: yup.string().nullable(),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuybackValue: yup.boolean().nullable(),
  temporaryPlacementConditionPossibleBuybackBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  temporaryPlacementConditionPossibleBuybackDisplay: yup.boolean().nullable(),
  temporaryPlacementConditionRisksValue: yup.string().nullable(),
  temporaryPlacementConditionRisksDisplay: yup.boolean().nullable(),
  temporaryPlacementConditionTravelExpensesResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionTrainingExpensesResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionCnesstExpensesResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionOtherResponsibilitiesValue: yup
    .string()
    .nullable(),
  temporaryPlacementConditionResponsibilitiesDisplay: yup.boolean().nullable(),
  temporaryPlacementConditionForeignApplicantsValue: yup.boolean().nullable(),
  temporaryPlacementConditionForeignApplicantsDisplay: yup.boolean().nullable(),
});

export const REQUEST_HR_NEEDS_SCHEMA = yup.object({
  antiGhostingConsent: yup
    .boolean()
    .oneOf([true], 'validations.required.antiGhostingConsent'),
  types: yup
    .array(yup.mixed<RequestTypeCode>().oneOf(Object.values(RequestTypeCode)))
    .test(
      'notEmptyArray',
      'validations.required.types',
      (value) =>
        Array.isArray(value) &&
        value.filter((x) => x !== RequestTypeCode.InternationalRecruitment)
          .length > 0
    ),
  openToInternationalRecruitment: yup
    .boolean()
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.Headhunter)
        ? schema.required('validations.required.openToInternationalRecruitment')
        : schema.nullable()
    ),
  conditionCandidateInHandMandatoryValue: yup
    .boolean()
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.Headhunter)
        ? schema.required(
            'validations.required.conditionCandidateInHandMandatoryValue'
          )
        : schema.nullable()
    ),
});

export const REQUEST_DESCRIPTION_PUBLISH_SCHEMA = yup.object({
  jobSpecialtyCode: yup
    .string()
    .required('validations.required.jobSpecialtyCode'),
  jobDescription: yup
    .string()
    .required('validations.required.jobDescription')
    .max(3000, 'validations.maxLength')
    .min(200, 'validations.minLength'),
  receivingOfferDeadline: yup
    .string()
    .transform((_, val) => val || null)
    .required('validations.required.receivingOfferDeadline'),
  operationUnitId: yup
    .string()
    .required('validations.required.operationUnitId'),
  softSkills: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .test(
      'notEmptyArray',
      'validations.required.softSkills',
      (value) => Array.isArray(value) && value.length > 0
    ),
  operationTerritoryCodes: yup
    .array(
      yup
        .mixed<OperationTerritoryCode>()
        .oneOf(Object.values(OperationTerritoryCode))
    )
    .when('jobModeCode', ([jobModeCode]) =>
      jobModeCode === JobModeCode.Remote
        ? yup
            .array(
              yup
                .mixed<OperationTerritoryCode>()
                .oneOf(Object.values(OperationTerritoryCode))
            )
            .nullable()
        : yup
            .array(
              yup
                .mixed<OperationTerritoryCode>()
                .oneOf(Object.values(OperationTerritoryCode))
            )
            .test(
              'notEmptyArray',
              'validations.required.operationTerritoryCodes',
              (value) => Array.isArray(value) && value.length > 0
            )
    ),
  citiesSubRegions: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .when('jobModeCode', ([jobModeCode]) =>
      jobModeCode === JobModeCode.Remote
        ? yup.array(yup.string().max(128, 'validations.maxLength')).nullable()
        : yup
            .array(yup.string().max(128, 'validations.maxLength'))
            .test(
              'notEmptyArray',
              'validations.required.citiesSubRegions',
              (value) => Array.isArray(value) && value.length > 0
            )
    ),
  desiredEducationLevelCodes: yup
    .array(
      yup.mixed<EducationLevelCode>().oneOf(Object.values(EducationLevelCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.desiredEducationLevelCodes',
      (value) => Array.isArray(value) && value.length > 0
    ),
  jobOpenPositions: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(0, 'validations.minNumber')
    .required('validations.required.jobOpenPositions'),
  jobExperienceLevelMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(0, 'validations.minNumber')
    .max(44, 'validations.maxNumber')
    .required('validations.required.experience'),
  jobExperienceLevelMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.maxNumber')
    .max(45, 'validations.maxNumber')
    .required('validations.required.experience'),
  jobDurationCode: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .required('validations.required.jobDurationCode'),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when('jobDurationCode', ([jobDurationCode], schema) =>
      !!jobDurationCode &&
      (jobDurationCode === JobDurationCode.Contract ||
        jobDurationCode === JobDurationCode.Freelance ||
        jobDurationCode === JobDurationCode.Internship)
        ? schema.required('validations.required.jobDurationInMonths')
        : schema.nullable()
    ),
  jobPartTimeAvailability: yup
    .boolean()
    .nullable()
    .test({
      name: 'jobPartTimeAvailability',
      message: 'validations.required.jobAvailability',
      test() {
        return (
          this.parent.jobFullTimeAvailability ||
          this.parent.jobPartTimeAvailability
        );
      },
    }),
  jobFullTimeAvailability: yup
    .boolean()
    .nullable()
    .test({
      name: 'jobFullTimeAvailability',
      message: 'validations.required.jobAvailability',
      test() {
        return (
          this.parent.jobFullTimeAvailability ||
          this.parent.jobPartTimeAvailability
        );
      },
    }),
  jobAvailabilityInHoursPerWeek: yup
    .array(yup.string())
    .test(
      'notEmptyArray',
      'validations.required.jobAvailabilityInHoursPerWeek',
      (value) => Array.isArray(value) && value.length > 0
    ),
  jobWorkingHours: yup
    .array(
      yup.mixed<JobScheduleTypeCode>().oneOf(Object.values(JobScheduleTypeCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.jobWorkingHours',
      (value) => Array.isArray(value) && value.length > 0
    ),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .required('validations.required.jobModeCode'),
  jobPaymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .required('validations.required.jobPaymentFrequencyCode'),
  jobRemoteModeInDays: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber')
    .when('jobModeCode', ([jobModeCode], schema) =>
      jobModeCode === JobModeCode.Hybrid
        ? schema.required('validations.required.jobRemoteModeInDays')
        : schema.nullable()
    ),
  jobRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('jobPaymentFrequencyCode', ([jobPaymentFrequencyCode], schema) =>
      jobPaymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount
        ? schema.required('validations.required.enterAmount')
        : schema.nullable()
    ),
  jobOtherRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .nullable()
    .when('jobPaymentOtherTypesCodes', ([jobPaymentOtherTypesCodes], schema) =>
      jobPaymentOtherTypesCodes?.includes(JobPaymentOtherTypesCode.HiringBonus)
        ? schema.required('validations.required.enterAmount')
        : schema.nullable()
    ),
  conditionMinimumBudgetValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(3500, 'validations.minNumber')
    .max(100000, 'validations.maxNumber')
    .lessThan(100000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionMaximumBudgetValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(3500, 'validations.minNumber')
    .max(100000, 'validations.maxNumber')
    .lessThan(100000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
});
export const REQUEST_CONDITIONS_PUBLISH_SCHEMA = yup.object({
  /* GENERAL CONDITIONS */
  conditionPaymentMethodValue: yup
    .mixed<RequestConditionPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionPaymentMethodCode))
    .required('validations.required.conditionPaymentMethodValue'),
  conditionPaymentAmountValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .when(
      'conditionStartOfAgreementPaymentMethodValue',
      ([conditionStartOfAgreementPaymentMethodValue], schema) =>
        conditionStartOfAgreementPaymentMethodValue ===
        RequestConditionStartOfAgreementPaymentMethodCode.AmountWanted
          ? schema.required('validations.required.conditionPaymentAmountValue')
          : schema.nullable()
    ),
  conditionDepositValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionPaymentFrequencyValue: yup
    .mixed<RequestConditionPaymentFrequencyCode>()
    .oneOf(Object.values(RequestConditionPaymentFrequencyCode))
    .when(
      'conditionPaymentMethodValue',
      ([conditionPaymentMethodValue], schema) =>
        conditionPaymentMethodValue ===
        RequestConditionPaymentMethodCode.StartOfAgreement
          ? schema.required(
              'validations.required.conditionPaymentFrequencyValue'
            )
          : schema.nullable()
    ),
  conditionMinGuaranteedPeriodAsked: yup
    .boolean()
    .required('validations.boolean'),
  conditionMinGuaranteedPeriodBonusOffered: yup
    .boolean()
    .when(
      'conditionMinGuaranteedPeriodAsked',
      ([conditionMinGuaranteedPeriodAsked], schema) =>
        conditionMinGuaranteedPeriodAsked
          ? schema.required('validations.boolean')
          : schema.nullable()
    ),
  conditionMinGuaranteedPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      'conditionMinGuaranteedPeriodAsked',
      ([conditionMinGuaranteedPeriodAsked], schema) =>
        conditionMinGuaranteedPeriodAsked
          ? schema.required('validations.required.durationInMonths')
          : schema.nullable()
    ),
  conditionProbationPeriodOffered: yup
    .boolean()
    .when(
      'conditionMinGuaranteedPeriodAsked',
      ([conditionMinGuaranteedPeriodAsked], schema) =>
        conditionMinGuaranteedPeriodAsked
          ? schema.required('validations.boolean')
          : schema.nullable()
    ),
  conditionProbationPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .when(
      ['conditionProbationPeriodOffered', 'conditionMinGuaranteedPeriodAsked'],
      (
        [conditionProbationPeriodOffered, conditionMinGuaranteedPeriodAsked],
        schema
      ) =>
        conditionProbationPeriodOffered && conditionMinGuaranteedPeriodAsked
          ? schema.required('validations.required.durationInWeeks')
          : schema.nullable()
    ),
  conditionOtherBonusesOffered: yup.boolean().required('validations.boolean'),
  conditionOtherBonuses: yup
    .array(
      yup
        .mixed<OfferConditionOtherBonusCode>()
        .oneOf(Object.values(OfferConditionOtherBonusCode))
    )
    .when(
      'conditionOtherBonusesOffered',
      ([conditionOtherBonusesOffered], schema) =>
        conditionOtherBonusesOffered
          ? schema.test(
              'notEmptyArray',
              'validations.required.otherBonusesOfferedType',
              (value) => Array.isArray(value) && value.length > 0
            )
          : schema.nullable()
    ),
  conditionOtherBonusDescription: yup
    .string()
    .transform((value, originalValue) => {
      if (
        originalValue == null ||
        (typeof originalValue === 'string' && originalValue.trim() === '')
      ) {
        return undefined;
      }
      return value;
    })
    .max(3000, 'validations.maxLength')
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesOffered'],
      ([conditionOtherBonuses, conditionOtherBonusesOffered], schema) =>
        Array.isArray(conditionOtherBonuses) &&
        conditionOtherBonuses.includes(OfferConditionOtherBonusCode.Other) &&
        conditionOtherBonusesOffered
          ? schema.required(
              'validations.required.conditionOtherBonusDescription'
            )
          : schema.nullable()
    ),
  conditionOtherBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesOffered'],
      ([conditionOtherBonuses, conditionOtherBonusesOffered], schema) =>
        Array.isArray(conditionOtherBonuses) &&
        conditionOtherBonuses.includes(OfferConditionOtherBonusCode.Other) &&
        conditionOtherBonusesOffered
          ? schema.required('validations.required.enterAmount')
          : schema.nullable()
    ),
  conditionMinGuaranteedPeriodBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      [
        'conditionMinGuaranteedPeriodBonusOffered',
        'conditionMinGuaranteedPeriodAsked',
      ],
      (
        [
          conditionMinGuaranteedPeriodBonusOffered,
          conditionMinGuaranteedPeriodAsked,
        ],
        schema
      ) =>
        conditionMinGuaranteedPeriodBonusOffered &&
        conditionMinGuaranteedPeriodAsked
          ? schema.required('validations.required.enterAmount')
          : schema.nullable()
    ),
  conditionBonusMonthValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesOffered'],
      ([conditionOtherBonuses, conditionOtherBonusesOffered], schema) =>
        Array.isArray(conditionOtherBonuses) &&
        conditionOtherBonuses.includes(
          OfferConditionOtherBonusCode.CandidateCompanyLongivity
        ) &&
        conditionOtherBonusesOffered
          ? schema.required('validations.required.durationInWeeks')
          : schema.nullable()
    ),
  conditionBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesOffered'],
      ([conditionOtherBonuses, conditionOtherBonusesOffered], schema) =>
        Array.isArray(conditionOtherBonuses) &&
        conditionOtherBonuses.includes(
          OfferConditionOtherBonusCode.CandidateCompanyLongivity
        ) &&
        conditionOtherBonusesOffered
          ? schema.required('validations.required.enterAmount')
          : schema.nullable()
    ),
  conditionHiredDateBonusRequirement: yup
    .string()
    .transform((_, val) => val || null)
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesOffered'],
      ([conditionOtherBonuses, conditionOtherBonusesOffered], schema) =>
        Array.isArray(conditionOtherBonuses) &&
        conditionOtherBonuses.includes(
          OfferConditionOtherBonusCode.HiringSpeed
        ) &&
        conditionOtherBonusesOffered
          ? schema.required('validations.required.date')
          : schema.nullable()
    ),
  conditionHiredDateBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesOffered'],
      ([conditionOtherBonuses, conditionOtherBonusesOffered], schema) =>
        Array.isArray(conditionOtherBonuses) &&
        conditionOtherBonuses.includes(
          OfferConditionOtherBonusCode.HiringSpeed
        ) &&
        conditionOtherBonusesOffered
          ? schema.required('validations.required.enterAmount')
          : schema.nullable()
    ),

  /* HEADHUNTER CONDITIONS */
  conditionMaxRecruitmentPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .when(
      ['conditionCandidateInHandMandatoryValue', 'types'],
      ([conditionCandidateInHandMandatoryValue, types], schema) =>
        types &&
        types.length > 0 &&
        types.includes(RequestTypeCode.Headhunter) &&
        !conditionCandidateInHandMandatoryValue
          ? schema.required('validations.required.durationInWeeks')
          : schema.nullable()
    ),
  headhunterConditionExclusivityPeriodOffered: yup
    .boolean()
    .when(
      ['conditionCandidateInHandMandatoryValue', 'types'],
      ([conditionCandidateInHandMandatoryValue, types], schema) =>
        types &&
        types.length > 0 &&
        types.includes(RequestTypeCode.Headhunter) &&
        !conditionCandidateInHandMandatoryValue
          ? schema.required('validations.boolean')
          : schema.nullable()
    ),
  headhunterConditionExclusivityPeriodValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when(
      [
        'conditionCandidateInHandMandatoryValue',
        'types',
        'headhunterConditionExclusivityPeriodOffered',
      ],
      (
        [
          conditionCandidateInHandMandatoryValue,
          types,
          headhunterConditionExclusivityPeriodOffered,
        ],
        schema
      ) =>
        types &&
        types.length > 0 &&
        types.includes(RequestTypeCode.Headhunter) &&
        !conditionCandidateInHandMandatoryValue &&
        headhunterConditionExclusivityPeriodOffered
          ? schema.required('validations.required.durationInWeeks')
          : schema.nullable()
    ),

  /* INTERNATIONAL RECRUITMENT CONDITIONS */
  internationalRecruitmentConditionMandatoryWorkPermitValue: yup
    .boolean()
    .when(
      'openToInternationalRecruitment',
      ([openToInternationalRecruitment], schema) =>
        openToInternationalRecruitment
          ? schema.required(
              'validations.required.internationalRecruitmentConditionMandatoryWorkPermitValue'
            )
          : schema.nullable()
    ),

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumDurationValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive')
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.Outsourcing)
        ? schema.required('validations.required.durationInWeeks')
        : schema.nullable()
    ),
  outsourcingConditionMaximumBudgetValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.Outsourcing)
        ? schema.required(
            'validations.required.outsourcingConditionMaximumBudgetValue'
          )
        : schema.nullable()
    ),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuybackValue: yup
    .boolean()
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionPossibleBuybackValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionPossibleBuybackBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      'temporaryPlacementConditionPossibleBuybackValue',
      ([temporaryPlacementConditionPossibleBuybackValue], schema) =>
        temporaryPlacementConditionPossibleBuybackValue
          ? schema.required('validations.required.enterMaxAmount')
          : schema.nullable()
    ),
  temporaryPlacementConditionTravelExpensesResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionTravelExpensesResponsibilityValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionTrainingExpensesResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionTrainingExpensesResponsibilityValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionCnesstExpensesResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionCnesstExpensesResponsibilityValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('types', ([types], schema) =>
      types && types.length > 0 && types.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue'
          )
        : schema.nullable()
    ),
});

// FIXME: Tis is a workaround in order to validate each schema separately at publish.
export const REQUEST_PUBLISH_SCHEMA = REQUEST_SCHEMA.shape({})
  .concat(REQUEST_HR_NEEDS_SCHEMA)
  .concat(REQUEST_DESCRIPTION_PUBLISH_SCHEMA)
  .concat(REQUEST_CONDITIONS_PUBLISH_SCHEMA);

export type RequestFormFields = yup.InferType<typeof REQUEST_SCHEMA>;
