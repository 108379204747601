import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { LoadingSpinner } from '@application/components/spinner';
import { NoContent } from '@application/components/table';
import { CandidateNegotiationsFilters } from '@domain/graphql.types';

import { ListCard } from '../components';
import {
  useCandidateNegotiationFilters,
  useCandidateNegotiationsList,
} from '../hooks';

const CandidateNegotiationsList = () => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'list.noContent.negotiations',
  });

  const [tableFilters, setTableFilters] =
    useState<CandidateNegotiationsFilters>();

  const [showLoader, setShowLoader] = useState(false);

  const location = useLocation();

  const hash = useMemo(
    () =>
      location.hash
        ? location.hash.replace('#', '').replace('-', '_')
        : 'in_progress',
    [location]
  );

  const { candidateNegotiations, isLoading } =
    useCandidateNegotiationsList(tableFilters);

  const { filters } = useCandidateNegotiationFilters();

  useEffect(() => {
    setTimeout(() => setShowLoader(isLoading), 250);
  }, [isLoading]);

  useEffect(() => {
    setTableFilters(filters);
  }, [filters]);

  return (
    <div
      className="relative pt-s-32 overflow-x-auto"
      id="candidate-negotiation-list"
      aria-labelledby={hash && `tab-${hash}`}
      role="tabpanel"
    >
      {showLoader && isLoading && (
        <div className="h-s-384 w-full flex justify-center items-center">
          <LoadingSpinner size="lg" />
        </div>
      )}

      {!isLoading && candidateNegotiations.length > 0 && (
        <ul className="flex flex-col gap-s-16">
          {candidateNegotiations.map((negotiation) => (
            <li key={negotiation.id}>
              <ListCard
                data={negotiation}
                candidateRequestChatRoomUnreadMessages={negotiation.rooms
                  .map(
                    (room) => room.candidateRequestChatRoomUnreadMessagesCount
                  )
                  .reduce((sum, count) => sum + count, 0)}
              />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && candidateNegotiations.length === 0 && (
        <NoContent title={t(`${hash}.title`)} />
      )}
    </div>
  );
};

export default CandidateNegotiationsList;
