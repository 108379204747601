import { gql } from 'urql';

import { DescriptionsFragment } from '../../../descriptions';

const CandidateNegotiationListFragment = gql`
  ${DescriptionsFragment}

  fragment CandidateNegotiationListFragment on CandidateNegotiation {
    id
    accountId
    candidateRequestId
    userId
    status
    createdAt
    updatedAt
    notificationCount
    roomsCount
    latestActivity
    candidateRequest {
      firstName
      lastName
      operationTerritoryCodes
      type
      jobSpecialty {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
      operationTerritories {
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
    rooms {
      candidateRequestChatRoomUnreadMessagesCount
      candidateOffer {
        account {
          logoUrl
          name
        }
      }
    }
  }
`;

export default CandidateNegotiationListFragment;
