import { DateTime } from 'luxon';

import {
  JobPaymentOtherTypesCode,
  JobScheduleTypeCode,
  LinkFileName,
  Offer,
  OfferCandidate,
  OfferCandidatesEvaluationProcessCode,
  OfferConditionOtherBonusCode,
  OfferMandateFollowUpCode,
  OfferRegularFollowUpMethodCode,
  OfferSimilarMandatesExperiencesCode,
  OfferTalentSourceCode,
  OperationTerritoryCode,
  RequestTypeCode,
} from '@domain/graphql.types';
import { parseNumber } from '@utils/data-utils';

import { OfferCandidateFormFields, OfferFormFields } from './types';

export const normalizeCandidateData = (data: OfferCandidateFormFields) => ({
  ...data,
  hourlyRateMin: data.hourlyRateMin?.toFixed(2) || null,
  hourlyRateMax: data.hourlyRateMax?.toFixed(2) || null,
  hourlyBonus: data.hourlyBonus?.toFixed(2) || null,
  remunerationAmount: data.remunerationAmount?.toFixed(2) || null,
  otherRemunerationAmount: data.otherRemunerationAmount?.toFixed(2) || null,
  metAt: data.metAt ? DateTime.fromISO(data.metAt).toJSDate() : null,
  experience: Number(data.experience) || undefined,
  linkFileNames: undefined,
});

export const normalizeOfferInputData = (data: OfferFormFields) => ({
  ...data,
  requestTypes: (data.requestTypes as RequestTypeCode[]) || [],
  offerCandidates: data.offerCandidates?.map((d) => normalizeCandidateData(d)),
  conditionDepositRequired: data.conditionDepositRequired,
  canGiveDetailsOnSimilarMandates:
    data.similarMandatesExperiences !==
    OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
      ? data.canGiveDetailsOnSimilarMandates
      : null,
  similarMandatesReferencesDescription:
    data.canGiveDetailsOnSimilarMandates &&
    data.similarMandatesExperiences !==
      OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
      ? data.similarMandatesReferencesDescription
      : null,
  whyRightToFillMandate:
    data.whyRightToFillMandate &&
    data.similarMandatesExperiences ===
      OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
      ? data.whyRightToFillMandate
      : null,
  whyNoSimilarMandatesReferences:
    !data.canGiveDetailsOnSimilarMandates &&
    data.similarMandatesExperiences !==
      OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
      ? data.whyNoSimilarMandatesReferences
      : null,
  talentSourcesOthersDescription: data.talentSources?.includes(
    OfferTalentSourceCode.Others
  )
    ? data.talentSourcesOthersDescription
    : null,
  candidatesEvaluationProcessesOthersDescription:
    data.candidatesEvaluationProcesses?.includes(
      OfferCandidatesEvaluationProcessCode.Others
    )
      ? data.candidatesEvaluationProcessesOthersDescription
      : null,
  mandateFollowUpsOthersDescription: data.mandateFollowUps?.includes(
    OfferMandateFollowUpCode.Others
  )
    ? data.mandateFollowUpsOthersDescription
    : null,
  regularFollowUpsMethods: data.mandateFollowUps?.includes(
    OfferMandateFollowUpCode.RegularFollowUps
  )
    ? data.regularFollowUpsMethods
    : null,
  regularFollowUpsPeriod: data.mandateFollowUps?.includes(
    OfferMandateFollowUpCode.RegularFollowUps
  )
    ? data.regularFollowUpsPeriod
    : null,
  conditionDeposit: data.conditionDeposit?.toFixed(2) || null,
  conditionRemunerationAmount:
    data.conditionRemunerationAmount?.toFixed(2) || null,
  conditionRemunerationHourlyRate:
    data.conditionRemunerationHourlyRate?.toFixed(2) || null,
  conditionBonus:
    data.conditionOtherBonusesAsked &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.CandidateCompanyLongivity
    )
      ? data.conditionBonus?.toFixed(2)
      : null,
  outsourcingConditionMaximumBudget:
    data.outsourcingConditionMaximumBudget?.toFixed(2) || null,
  temporaryPlacementConditionPossibleBuybackBonus:
    data.temporaryPlacementConditionPossibleBuybackBonus?.toFixed(2) || null,
  conditionHiredDateBonus:
    data.conditionOtherBonusesAsked &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.HiringSpeed
    )
      ? data.conditionHiredDateBonus?.toFixed(2)
      : null,
  conditionMinGuaranteedPeriod: data.conditionMinGuaranteedPeriodOffered
    ? data.conditionMinGuaranteedPeriod
    : null,
  conditionMinGuaranteedPeriodBonusAsked:
    data.conditionMinGuaranteedPeriodOffered
      ? data.conditionMinGuaranteedPeriodBonusAsked
      : null,
  conditionMinGuaranteedPeriodBonus:
    data.conditionMinGuaranteedPeriodOffered &&
    data.conditionMinGuaranteedPeriodBonusAsked
      ? data.conditionMinGuaranteedPeriodBonus?.toFixed(2)
      : null,
  conditionProbationPeriodAsked: data.conditionMinGuaranteedPeriodOffered
    ? data.conditionProbationPeriodAsked
    : null,
  conditionProbationPeriod:
    data.conditionMinGuaranteedPeriodOffered &&
    data.conditionProbationPeriodAsked
      ? data.conditionProbationPeriod
      : null,
  conditionOtherBonuses: data.conditionOtherBonusesAsked
    ? (data.conditionOtherBonuses as OfferConditionOtherBonusCode[]) || []
    : null,
  conditionBonusMonth:
    data.conditionOtherBonusesAsked &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.CandidateCompanyLongivity
    )
      ? data.conditionBonusMonth
      : null,
  conditionHiredDateBonusRequirement:
    data.conditionOtherBonusesAsked &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.HiringSpeed
    ) &&
    data.conditionHiredDateBonusRequirement
      ? DateTime.fromISO(data.conditionHiredDateBonusRequirement).toJSDate()
      : null,
  conditionOtherBonusDescription:
    data.conditionOtherBonusesAsked &&
    data.conditionOtherBonuses?.includes(OfferConditionOtherBonusCode.Other)
      ? data.conditionOtherBonusDescription
      : null,
  conditionOtherBonusValue:
    data.conditionOtherBonusesAsked &&
    data.conditionOtherBonuses?.includes(OfferConditionOtherBonusCode.Other)
      ? data.conditionOtherBonusValue?.toFixed(2)
      : null,
  conditionCancellationFeeAmount: data.conditionHasCancellationFee
    ? data.conditionCancellationFeeAmount?.toFixed(2) || null
    : null,
  conditionCancellationFeeTerms: data.conditionHasCancellationFee
    ? data.conditionCancellationFeeTerms
    : null,
});

export const normalizeOfferEditCandidateData = (data: OfferCandidate) => ({
  id: data.id,
  firstName: data.firstName,
  firstNameDisplay: data.firstNameDisplay,
  lastName: data.lastName,
  lastNameDisplay: data.lastNameDisplay,
  email: data.email,
  emailDisplay: data.emailDisplay,
  experience: data.experience,
  educationLevelCode: data.educationLevelCode,
  studyField: data.studyField,
  specialty: data.specialty,
  canWorkInCanadaOrQuebec: data.canWorkInCanadaOrQuebec,
  citiesSubRegions: (data.citiesSubRegions as string[]) || [],
  operationTerritoryCodes:
    data.operationTerritoryCodes as OperationTerritoryCode[],
  requestTypes: (data.requestTypes as RequestTypeCode[]) || [],
  resume: data.cvUrl,
  otherFiles:
    (data.otherFilesUrls?.map((otherFile) => otherFile.link) as string[]) || [],
  linkFileNames: (data.otherFilesUrls as LinkFileName[]) || [],
  notes: data.notes,
  metAt: data.metAt || null,
  jobTypeCode: data.jobTypeCode,
  jobDurationInMonths: data.jobDurationInMonths,
  jobModeCode: data.jobModeCode,
  fullTimeAvailability: data.fullTimeAvailability,
  partTimeAvailability: data.partTimeAvailability,
  hoursPerWeek: (data.hoursPerWeek as string[]) || [],
  schedules: (data.schedules as JobScheduleTypeCode[]) || [],
  allowedRemoteDays: data.allowedRemoteDays,
  annualSalaryMin: data.annualSalaryMin,
  annualSalaryMax: data.annualSalaryMax,
  paymentFrequencyCode: data.paymentFrequencyCode,
  hourlyRateMin: parseNumber(data.hourlyRateMin) || null,
  hourlyRateMax: parseNumber(data.hourlyRateMax) || null,
  hourlyBonus: parseNumber(data.hourlyBonus) || null,
  remunerationAmount: parseNumber(data.remunerationAmount) || null,
  otherPaymentTypes:
    (data.otherPaymentTypes as JobPaymentOtherTypesCode[]) || [],
  otherRemunerationAmount: parseNumber(data.otherRemunerationAmount) || null,
  softSkills: (data.softSkills as string[]) || [],
});

export const normalizeOfferEditData = (data: Offer): OfferFormFields => ({
  requestId: data.requestId,
  linkProfile: data.linkProfile,
  noCandidatesExplaination: data.noCandidatesExplaination,
  candidatesAvailable: data.candidatesAvailable,
  requestTypes: (data.requestTypes as RequestTypeCode[]) || [],
  salesPitch: data.salesPitch,
  similarMandatesExperiences: data.similarMandatesExperiences,
  whyRightToFillMandate: data.whyRightToFillMandate,
  canGiveDetailsOnSimilarMandates: data.canGiveDetailsOnSimilarMandates,
  similarMandatesReferencesDescription:
    data.similarMandatesReferencesDescription,
  whyNoSimilarMandatesReferences: data.whyNoSimilarMandatesReferences,
  talentSources: (data.talentSources as OfferTalentSourceCode[]) || [],
  talentSourcesOthersDescription: data.talentSourcesOthersDescription,
  talentSourcesMoreDetails: data.talentSourcesMoreDetails,
  talentSourcesMethodsToolsDescription:
    data.talentSourcesMethodsToolsDescription,
  candidatesEvaluationProcesses:
    (data.candidatesEvaluationProcesses as OfferCandidatesEvaluationProcessCode[]) ||
    [],
  candidatesEvaluationProcessesOthersDescription:
    data.candidatesEvaluationProcessesOthersDescription,
  candidatesEvaluationProcessesMoreDetails:
    data.candidatesEvaluationProcessesMoreDetails,
  mandateFollowUps: (data.mandateFollowUps as OfferMandateFollowUpCode[]) || [],
  regularFollowUpsMethods:
    (data.regularFollowUpsMethods as OfferRegularFollowUpMethodCode[]) || [],
  regularFollowUpsPeriod: data.regularFollowUpsPeriod,
  mandateFollowUpsOthersDescription: data.mandateFollowUpsOthersDescription,
  mandateFollowUpsMoreDetails: data.mandateFollowUpsMoreDetails,
  offerCandidates: data.candidates?.map((d) =>
    normalizeOfferEditCandidateData(d)
  ),

  // GENERAL CONDITIONS
  conditionMaxRecruitmentPeriod:
    data.generalContractualConditions.maxRecruitmentPeriod,
  conditionProbationPeriodAsked:
    data.generalContractualConditions.probationPeriodAsked,
  conditionOtherBonusesAsked:
    data.generalContractualConditions.otherBonusesAsked,
  conditionOtherBonuses: data.generalContractualConditions
    .otherBonuses as OfferConditionOtherBonusCode[],
  conditionProbationPeriod: data.generalContractualConditions.probationPeriod,
  conditionPaymentMethod: data.generalContractualConditions.paymentMethod,
  conditionStartOfAgreementPaymentMethod:
    data.generalContractualConditions.startOfAgreementPaymentMethod,
  conditionDepositRequired: data.generalContractualConditions.depositRequired,
  conditionDeposit: data.generalContractualConditions.depositRequired
    ? parseNumber(data.generalContractualConditions.deposit)
    : null,
  conditionPaymentFrequency: data.generalContractualConditions.paymentFrequency,
  conditionRemunerationType: data.generalContractualConditions.remunerationType,
  conditionRemunerationPercentage:
    data.generalContractualConditions.remunerationPercentage,
  conditionRemunerationAmount: parseNumber(
    data.generalContractualConditions.remunerationAmount
  ),
  conditionRemunerationHourlyRate: parseNumber(
    data.generalContractualConditions.remunerationHourlyRate
  ),
  conditionRemunerationEstimatedHoursMin:
    data.generalContractualConditions.remunerationEstimatedHoursMin,
  conditionRemunerationEstimatedHoursMax:
    data.generalContractualConditions.remunerationEstimatedHoursMax,

  conditionMinGuaranteedPeriodOffered:
    data.generalContractualConditions.minGuaranteedPeriodOffered,
  conditionMinGuaranteedPeriod:
    data.generalContractualConditions.minGuaranteedPeriod,

  conditionMinGuaranteedPeriodBonusAsked:
    data.generalContractualConditions.minGuaranteedPeriodBonusAsked,
  conditionMinGuaranteedPeriodBonus: parseNumber(
    data.generalContractualConditions?.minGuaranteedPeriodBonus
  ),
  conditionBonus: parseNumber(data.generalContractualConditions?.bonus),
  conditionBonusMonth: data.generalContractualConditions?.bonusMonth,
  conditionHiredDateBonusRequirement:
    data.generalContractualConditions.hiredDateBonusRequirement || null,
  conditionHiredDateBonus: parseNumber(
    data.generalContractualConditions?.hiredDateBonus
  ),
  conditionOtherBonusDescription:
    data.generalContractualConditions?.otherBonusDescription,
  conditionOtherBonusValue: parseNumber(
    data.generalContractualConditions?.otherBonusValue
  ),

  // CANCELLATION FEE CONDITIONS
  conditionHasCancellationFee:
    data.generalContractualConditions.hasCancellationFee,
  conditionCancellationFeeAmount: parseNumber(
    data.generalContractualConditions.cancellationFeeAmount
  ),
  conditionCancellationFeeTerms:
    data.generalContractualConditions.cancellationFeeTerms,

  // HEADHUNTER CONDITIONS
  headhunterConditionEstimatedRecruitmentPeriod:
    data.headhunterContractualConditions?.headhuntingEstimatedRecruitmentPeriod,
  headhunterConditionMaximumReplacement:
    data.headhunterContractualConditions?.maximumReplacement,
  headhunterConditionExclusivityPeriodAsked:
    data.headhunterContractualConditions?.headhuntingExclusivityPeriodAsked,
  headhunterConditionExclusivityPeriod: data.headhunterContractualConditions
    ?.headhuntingExclusivityPeriodAsked
    ? data.headhunterContractualConditions?.headhuntingExclusivityPeriod
    : null,

  // TEMPORARY PLACEMENT CONDITIONS
  temporaryPlacementConditionPossibleBuyback:
    data.temporaryPlacementContractualConditions?.possibleBuyback,
  temporaryPlacementConditionPossibleBuybackBonus: parseNumber(
    data.temporaryPlacementContractualConditions?.possibleBuybackBonus
  ),
  temporaryPlacementConditionRisks:
    data.temporaryPlacementContractualConditions?.risks,
  temporaryPlacementConditionTravelExpensesResponsibility:
    data.temporaryPlacementContractualConditions?.travelExpensesResponsibility,
  temporaryPlacementConditionTrainingExpensesResponsibility:
    data.temporaryPlacementContractualConditions
      ?.trainingExpensesResponsibility,
  temporaryPlacementConditionEmployeeHelpProgramResponsibility:
    data.temporaryPlacementContractualConditions
      ?.employeeHelpProgramResponsibility,
  temporaryPlacementConditionCnesstExpensesResponsibility:
    data.temporaryPlacementContractualConditions?.cnesstExpensesResponsibility,
  temporaryPlacementConditionOtherResponsibilities:
    data.temporaryPlacementContractualConditions?.otherResponsibilities,
  temporaryPlacementConditionForeignApplicants:
    data.temporaryPlacementContractualConditions?.foreignApplicants,

  // INTERNATIONAL RECRUITMENT CONDITIONS
  internationalRecruitmentConditionMandatoryWorkPermit:
    data.internationalRecruitmentContractualConditions?.mandatoryWorkPermit,
  internationalRecruitmentConditionExclusivityPeriod:
    data.internationalRecruitmentContractualConditions?.exclusivityPeriod,
  internationalRecruitmentConditionMaximumReplacement:
    data.internationalRecruitmentContractualConditions?.maximumReplacement,

  // OUTSOURCING CONDITIONS
  outsourcingConditionMaximumDuration:
    data.outsourcingContractualConditions?.maximumDuration,
  outsourcingConditionExclusivityPeriod:
    data.outsourcingContractualConditions?.exclusivityPeriod,
  outsourcingConditionMaximumBudget: parseNumber(
    data.outsourcingContractualConditions?.maximumBudget
  ),
  outsourcingConditionResponsibilities:
    data.outsourcingContractualConditions?.responsibilities,
});
