import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { Offer, OfferConditionOtherBonusCode } from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type OtherBonusesOfferedConditionsProps = {
  data: Offer | undefined;
};

const OtherBonusesOfferedConditions = ({
  data,
}: OtherBonusesOfferedConditionsProps) => {
  const { t, i18n } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tGlobal } = useTranslation();

  const recruitmentOpportunity = data?.recruitmentOpportunity;
  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.generalContractualConditions;
  const conditions = data?.generalContractualConditions;

  return (
    <>
      <SectionGridRow
        isOffer
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.bonusMonthValue
              ? `${recruitmentOpportunityConditions.bonusMonthValue} ${t('suffix.months')}`
              : tGlobal('boolean.false')}
          </span>
        }
      >
        <div className="flex items-center">
          {conditions?.otherBonuses?.includes(
            OfferConditionOtherBonusCode.CandidateCompanyLongivity
          ) ? (
            <div>
              <span className="mr-s-8">{t('labels.bonusMonth')}</span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.bonusMonth || '-'}
              </span>
              <span className="ml-s-8">{t('labels.bonusMonthEnding')}</span>
            </div>
          ) : (
            <span>{t('labels.noCandidateCompanyLongivityBonus')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.bonusValue
              ? tGlobal('suffix.currencyValue', {
                  value: recruitmentOpportunityConditions.bonusValue,
                })
              : tGlobal('boolean.false')}
          </span>
        }
      >
        <div className="flex items-center">
          <span className="mr-s-8">{t('labels.bonusValue')}</span>
          <span className="text-16 font-semibold text-primary">
            {conditions?.bonus &&
            conditions?.otherBonuses?.includes(
              OfferConditionOtherBonusCode.CandidateCompanyLongivity
            )
              ? tGlobal('suffix.currencyValue', {
                  value: conditions.bonus,
                })
              : tGlobal('labels.none')}
          </span>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.hiredDateBonusRequirement
              ? formatDate(
                  recruitmentOpportunityConditions?.hiredDateBonusRequirement,
                  i18n.language
                )
              : '-'}
          </span>
        }
      >
        <div className="flex items-center">
          {conditions?.otherBonuses?.includes(
            OfferConditionOtherBonusCode.HiringSpeed
          ) ? (
            <>
              <span className="mr-s-8">
                {t('labels.hiredDateBonusRequirement')}
              </span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.hiredDateBonusRequirement
                  ? formatDate(
                      conditions?.hiredDateBonusRequirement,
                      i18n.language
                    )
                  : '-'}
              </span>
            </>
          ) : (
            <span>{t('labels.noHiringSpeedBonus')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.hiredDateBonusValue
              ? tGlobal('suffix.currencyValue', {
                  value: recruitmentOpportunityConditions.hiredDateBonusValue,
                })
              : '-'}
          </span>
        }
      >
        <div className="flex items-center">
          <span className="mr-s-8">{t('labels.bonusValue')}</span>
          <span className="text-16 font-semibold text-primary">
            {conditions?.hiredDateBonus &&
            conditions?.otherBonuses?.includes(
              OfferConditionOtherBonusCode.HiringSpeed
            )
              ? tGlobal('suffix.currencyValue', {
                  value: conditions.hiredDateBonus,
                })
              : tGlobal('labels.none')}
          </span>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.otherBonusDescription ? (
              <TooltipEllipsis
                lineClamp={4}
                toolTip={
                  recruitmentOpportunityConditions?.otherBonusDescription
                }
              >
                <span>
                  {recruitmentOpportunityConditions?.otherBonusDescription}
                </span>
              </TooltipEllipsis>
            ) : (
              '-'
            )}
          </span>
        }
      >
        <div className="flex items-center">
          {conditions?.otherBonuses?.includes(
            OfferConditionOtherBonusCode.Other
          ) ? (
            <>
              <span className="mr-s-8">
                {t('labels.otherBonusDescription')}
              </span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.otherBonusDescription
                  ? conditions?.otherBonusDescription
                  : '-'}
              </span>
            </>
          ) : (
            <span>{t('labels.noOtherBonus')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.otherBonusValue
              ? tGlobal('suffix.currencyValue', {
                  value: recruitmentOpportunityConditions?.otherBonusValue,
                })
              : '-'}
          </span>
        }
      >
        <div className="flex items-center">
          <span className="mr-s-8">{t('labels.bonusValue')}</span>
          <span className="text-16 font-semibold text-primary">
            {conditions?.otherBonusValue &&
            conditions?.otherBonuses?.includes(
              OfferConditionOtherBonusCode.Other
            )
              ? tGlobal('suffix.currencyValue', {
                  value: conditions.otherBonusValue,
                })
              : tGlobal('labels.none')}
          </span>
        </div>
      </SectionGridRow>
    </>
  );
};

export default OtherBonusesOfferedConditions;
