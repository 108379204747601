import { useMemo } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions, PagePanel } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import useAgencyProOfferValidation from '@application/views/billing/useAgencyProOfferValidation';
import useIsViewOnlySubscription from '@application/views/billing/useIsViewOnlySubscription';
import { RecruitmentOpportunity, RequestTypeCode } from '@domain/graphql.types';

import { OfferFormFields } from '../../types';
import HeadHunterFields from './HeadHunterFields';
import InternationalRecruitmentFields from './InternationalRecruitmentFields';
import OutsourcingFields from './OutsourcingFields';
import TemporaryPlacementFields from './TemporaryPlacementFields';
import MainFields from './main-fields/MainFields';
import OtherBonusesOfferedFields from './main-fields/OtherBonusesOfferedFields';
import ReplacementGuaranteeFields from './main-fields/ReplacementGuaranteeFields';

type ConditionsPanelProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
  onPublish?: () => void;
  onSave?: () => void;
  isCreatingOffer?: boolean;
};

const ConditionsPanel = ({
  recruitmentOpportunity,
  onPublish,
  onSave,
  isCreatingOffer = false,
}: ConditionsPanelProps) => {
  const { t } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: TGlobal } = useTranslation();
  const hasViewOnlyRestriction = useIsViewOnlySubscription();
  const { canPublishOffer, remainingOffer } = useAgencyProOfferValidation();
  const { watch }: UseFormReturn<OfferFormFields> = useFormContext();

  const requestTypes = watch('requestTypes') || [];

  const publishButtonTooltips = useMemo(() => {
    let tooltipsMessage;

    if (hasViewOnlyRestriction) {
      tooltipsMessage = TGlobal('membership.permission.upgradePlan');
    } else if (!canPublishOffer) {
      tooltipsMessage = t('messages.agencyProLimitReached', {
        allowedOffers: remainingOffer,
      });
    }
    return tooltipsMessage;
  }, [TGlobal, canPublishOffer, hasViewOnlyRestriction, remainingOffer, t]);

  return (
    <PagePanel title={t('steps.conditions')} text={t('steps.conditionsText')}>
      <MainFields recruitmentOpportunity={recruitmentOpportunity} />
      <ReplacementGuaranteeFields
        recruitmentOpportunity={recruitmentOpportunity}
      />
      <OtherBonusesOfferedFields
        recruitmentOpportunity={recruitmentOpportunity}
      />
      {requestTypes.includes(RequestTypeCode.Headhunter) &&
        !recruitmentOpportunity?.generalContractualConditions
          ?.candidateInHandMandatoryValue && (
          <HeadHunterFields recruitmentOpportunity={recruitmentOpportunity} />
        )}
      {requestTypes.includes(RequestTypeCode.TemporaryHelp) && (
        <TemporaryPlacementFields
          recruitmentOpportunity={recruitmentOpportunity}
        />
      )}
      {requestTypes.includes(RequestTypeCode.Outsourcing) && (
        <OutsourcingFields recruitmentOpportunity={recruitmentOpportunity} />
      )}
      {requestTypes.includes(RequestTypeCode.InternationalRecruitment) && (
        <InternationalRecruitmentFields
          recruitmentOpportunity={recruitmentOpportunity}
        />
      )}
      <FormActions
        publishDisabled={hasViewOnlyRestriction || !canPublishOffer}
        publishToolTip={publishButtonTooltips}
        disabled={isCreatingOffer}
        isLastStep
        cancelPath={RootPrivatePage.RECRUITMENT_OPPORTUNITIES}
        onPublish={onPublish}
        onSave={onSave}
      />
    </PagePanel>
  );
};

export default ConditionsPanel;
