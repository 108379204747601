import { gql } from '@urql/core';

import { CandidateOfferFragment } from '../../offer/fragment';
import CandidateNegotiatingOfferFragment from '../../offer/fragment/candidate.negotiating.offer.fragment';
import { PlainCandidateRequestFragment } from '../../request/fragment';
import CandidateNegotiationGeneralPropertiesStateFragment from '../fragment/candidate.negotiation.general.properties.state.fragment';
import CandidateNegotiationOutsourcingPropertiesStateFragment from '../fragment/candidate.negotiation.outsourcing.properties.state.fragment';
import CandidateNegotiationRecruitmentPropertiesStateFragment from '../fragment/candidate.negotiation.recruitment.properties.state.fragment';
import CandidateNegotiationTemporaryPlacementPropertiesStateFragment from '../fragment/candidate.negotiation.temporary.placement.properties.state.fragment';

const CandidateNegotiationRoom = gql`
  ${CandidateNegotiationGeneralPropertiesStateFragment}
  ${CandidateNegotiationRecruitmentPropertiesStateFragment}
  ${CandidateNegotiationTemporaryPlacementPropertiesStateFragment}
  ${CandidateNegotiationOutsourcingPropertiesStateFragment}
  ${CandidateNegotiatingOfferFragment}
  ${CandidateOfferFragment}
  ${PlainCandidateRequestFragment}

  query CandidateNegotiationRoom($input: CandidateNegotiationRoomInput!) {
    candidateNegotiationRoom(input: $input) {
      room {
        id
        candidateNegotiationId
        candidateRequestId
        candidateRequestAccountId
        candidateRequestUserId
        candidateOfferId
        candidateOfferAccountId
        candidateOfferUserId
        negotiatingCandidateOfferId
        status
        candidateRequestChatRoomUnreadMessagesCount
        generalPropertiesState {
          ...CandidateNegotiationGeneralPropertiesStateFragment
        }
        recruitmentPropertiesState {
          ...CandidateNegotiationRecruitmentPropertiesStateFragment
        }
        temporaryPlacementPropertiesState {
          ...CandidateNegotiationTemporaryPlacementPropertiesStateFragment
        }
        outsourcingPropertiesState {
          ...CandidateNegotiationOutsourcingPropertiesStateFragment
        }
        createdAt
        updatedAt
        negotiatingCandidateOffer {
          ...CandidateNegotiatingOfferFragment
        }
        candidateOffer {
          ...CandidateOfferFragment
        }
        candidateRequest {
          ...PlainCandidateRequestFragment
        }
      }
    }
  }
`;

export default CandidateNegotiationRoom;
