import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CheckboxGroup,
  Fieldset,
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  TextAreaField,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { OfferConditionOtherBonusCode } from '@domain/graphql.types';
import { formattedNowDate } from '@utils/date-utils';
import { extractLanguage } from '@utils/i18n-utils';

import { RequestFormFields } from '../../schema';

type OtherBonusesOfferedFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
};

const OtherBonusesOfferedFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
  trigger,
}: OtherBonusesOfferedFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const otherBonusesOffered = watch('conditionOtherBonusesOffered');
  const otherBonuses = watch('conditionOtherBonuses') ?? [];

  const otherBonusesData = Object.values(OfferConditionOtherBonusCode).map(
    (type) => ({
      value: type,
      text: {
        fr: t(`enum.otherBonusesOfferedType.${type.toLowerCase()}`),
        en: t(`enum.otherBonusesOfferedType.${type.toLowerCase()}`),
      },
    })
  );

  const handleOnRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('conditionOtherBonuses', [
        ...otherBonuses,
        event.target.value as OfferConditionOtherBonusCode,
      ]);
    } else {
      setValue('conditionOtherBonuses', [
        ...otherBonuses.filter((p) => p !== event.target.value),
      ]);
    }
    trigger('conditionOtherBonuses');
  };

  return (
    <SectionGrid
      title={t('subtitles.otherBonusesOffered')}
      tooltipMessage={t('subtitles.otherBonusesOfferedTooltip')}
      className="mt-s-40"
      fullWidth
    >
      <SectionGridRow isTopRow className="flex-col items-start">
        <Controller
          name="conditionOtherBonusesOffered"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              legendClassName="max-w-[calc(100%-180px)]"
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.otherBonusesOffered')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value === 'true');
                trigger('conditionOtherBonusesOffered');
              }}
              legendSize="medium"
              alignRow
              value={otherBonusesOffered ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />
      </SectionGridRow>

      {otherBonusesOffered && (
        <SectionGridRow className="flex-col items-start">
          <Fieldset
            legend={t('labels.otherBonusesOfferedType')}
            legendSize="medium"
            className="w-full"
          >
            <Controller
              name="conditionOtherBonuses"
              control={control}
              render={({ field: { value } }) => (
                <CheckboxGroup
                  className="mb-s-24"
                  data={otherBonusesData}
                  language={extractLanguage(i18n.language)}
                  onChange={handleOnRadioChange}
                  legendSize="medium"
                  values={value ?? []}
                  invalid={!!errors.conditionOtherBonuses}
                  helperText={
                    errors.conditionOtherBonuses?.message &&
                    tGlobal(errors.conditionOtherBonuses?.message)
                  }
                />
              )}
            />

            {otherBonuses && (
              <>
                {otherBonuses.includes(
                  OfferConditionOtherBonusCode.CandidateCompanyLongivity
                ) && (
                  <SectionGridRow partialBorderTop className="block">
                    <FormInputWithSuffix
                      label={t('labels.otherBonusesOfferedMinDurationPosition')}
                      suffix={t('suffix.weeks')}
                      type="number"
                      mediumSuffix
                      noMargin
                      minValue={1}
                      invalid={!!errors.conditionBonusMonthValue}
                      helperText={
                        errors.conditionBonusMonthValue?.message &&
                        tGlobal(errors.conditionBonusMonthValue?.message, {
                          min: '1',
                        })
                      }
                      {...register('conditionBonusMonthValue')}
                    />

                    <FormInputWithSuffix
                      className="mt-s-20"
                      type="number"
                      minValue={0}
                      maxValue={99999999.99}
                      step={0.01}
                      invalid={!!errors.conditionBonusValue}
                      noMargin
                      helperText={
                        errors.conditionBonusValue?.message &&
                        tGlobal(errors.conditionBonusValue?.message, {
                          max: '99,999,999',
                          min: '1',
                        })
                      }
                      label={t('labels.otherBonusesOfferedAmount')}
                      suffix={t('suffix.currency')}
                      {...register('conditionBonusValue')}
                    />
                  </SectionGridRow>
                )}

                {otherBonuses.includes(
                  OfferConditionOtherBonusCode.HiringSpeed
                ) && (
                  <SectionGridRow className="flex-col items-start">
                    <FormInputWithSuffix
                      label={t('labels.ifHiredBefore')}
                      type="date"
                      minValue={formattedNowDate()}
                      noMargin
                      invalid={!!errors.conditionHiredDateBonusRequirement}
                      helperText={
                        errors.conditionHiredDateBonusRequirement?.message &&
                        tGlobal(
                          errors.conditionHiredDateBonusRequirement?.message,
                          {
                            min: '1',
                          }
                        )
                      }
                      {...register('conditionHiredDateBonusRequirement')}
                    />

                    <FormInputWithSuffix
                      className="mt-s-20"
                      type="number"
                      minValue={0}
                      maxValue={99999999.99}
                      step={0.01}
                      invalid={!!errors.conditionHiredDateBonusValue}
                      noMargin
                      helperText={
                        errors.conditionHiredDateBonusValue?.message &&
                        tGlobal(errors.conditionHiredDateBonusValue?.message, {
                          max: '99,999,999.99',
                          min: '1',
                        })
                      }
                      label={t('labels.otherBonusesOfferedAmount')}
                      suffix={t('suffix.currency')}
                      {...register('conditionHiredDateBonusValue')}
                    />
                  </SectionGridRow>
                )}

                {otherBonuses.includes(OfferConditionOtherBonusCode.Other) && (
                  <SectionGridRow className="flex-col items-start">
                    <Controller
                      name="conditionOtherBonusDescription"
                      control={control}
                      render={({ field: { onChange, name, value } }) => (
                        <TextAreaField
                          label={t('labels.otherBonusesOfferedOther')}
                          className="w-full"
                          name={name}
                          value={value || ''}
                          rows={4}
                          maxChar={2500}
                          onChange={onChange}
                          invalid={!!errors.conditionOtherBonusDescription}
                          helperText={
                            errors.conditionOtherBonusDescription?.message &&
                            tGlobal(
                              errors.conditionOtherBonusDescription?.message,
                              {
                                max: '1024',
                              }
                            )
                          }
                        />
                      )}
                    />

                    <FormInputWithSuffix
                      type="number"
                      minValue={0}
                      maxValue={99999999.99}
                      step={0.01}
                      invalid={!!errors.conditionOtherBonusValue}
                      noMargin
                      helperText={
                        errors.conditionOtherBonusValue?.message &&
                        tGlobal(errors.conditionOtherBonusValue?.message, {
                          max: '99,999,999.99',
                          min: '1',
                        })
                      }
                      label={t('labels.otherBonusesOfferedAmount')}
                      suffix={t('suffix.currency')}
                      {...register('conditionOtherBonusValue')}
                    />
                  </SectionGridRow>
                )}
              </>
            )}
          </Fieldset>
        </SectionGridRow>
      )}
    </SectionGrid>
  );
};

export default OtherBonusesOfferedFields;
