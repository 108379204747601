import { ChangeEvent, useMemo } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Cluster,
  Fieldset,
  FormInputWithSuffix,
  HelperText,
  Radio,
  SectionGrid,
  SectionGridRow,
  Stack,
} from '@application/components';
import {
  ConditionRemunerationType,
  JobPaymentFrequencyCode,
  RecruitmentOpportunity,
} from '@domain/graphql.types';

import { OfferFormFields } from '../../../types';
import PaymentMethodsFields from './PaymentMethodsFields';

type MainFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const MainFields = ({ recruitmentOpportunity }: MainFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const conditionRemunerationType = watch('conditionRemunerationType');

  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.generalContractualConditions;

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof OfferFormFields
  ) => {
    setValue(name, event.target.value as any);
    trigger(name);
  };

  const opportunityRemuneration = useMemo(() => {
    if (
      recruitmentOpportunity?.jobPaymentFrequencyCode ===
      JobPaymentFrequencyCode.Yearly
    ) {
      return t('labels.perYear', {
        min: recruitmentOpportunity?.jobSalaryMin,
        max: recruitmentOpportunity?.jobSalaryMax,
      });
    }

    if (
      recruitmentOpportunity?.jobPaymentFrequencyCode ===
      JobPaymentFrequencyCode.Hourly
    ) {
      return t('labels.perHour', {
        min: recruitmentOpportunity?.jobHourlyRateMin,
        max: recruitmentOpportunity?.jobHourlyRateMax,
      });
    }

    if (
      recruitmentOpportunity?.jobPaymentFrequencyCode ===
      JobPaymentFrequencyCode.FixedAmount
    ) {
      return t('labels.fixedAmount', {
        amount: recruitmentOpportunity?.jobRemunerationAmount,
      });
    }

    return '-';
  }, [recruitmentOpportunity, t]);

  return (
    <SectionGrid title={t('subtitles.generalConditions')} isOffer>
      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {opportunityRemuneration}
          </span>
        }
      >
        <Fieldset
          legend={`${t('labels.remunerationType')}:`}
          legendSize="medium"
        >
          <Controller
            name="conditionRemunerationType"
            control={control}
            render={({ field: { name } }) => (
              <Cluster space={16} as="ul" className="mt-s-16">
                {Object.values(ConditionRemunerationType).map((type) => (
                  <li key={type}>
                    <Radio
                      name={name}
                      value={type}
                      label={t(
                        `enum.conditionRemunerationType.${type.toLowerCase()}`
                      )}
                      asButton
                      onChange={(e) =>
                        handleOnRadioChange(
                          e as ChangeEvent<HTMLInputElement>,
                          name
                        )
                      }
                      checked={conditionRemunerationType === type}
                    />
                  </li>
                ))}
              </Cluster>
            )}
          />
          {errors.conditionRemunerationType?.message && (
            <HelperText id="conditionRemunerationType-error" invalid>
              {tGlobal(errors.conditionRemunerationType.message)}
            </HelperText>
          )}

          {conditionRemunerationType ===
            ConditionRemunerationType.AnnualSalaryPercentage && (
            <FormInputWithSuffix
              className="mt-s-16"
              label={t('labels.remunerationPercentage')}
              suffix={t('suffix.percentage')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              invalid={!!errors.conditionRemunerationPercentage}
              helperText={
                errors.conditionRemunerationPercentage?.message &&
                tGlobal(errors.conditionRemunerationPercentage?.message)
              }
              {...register('conditionRemunerationPercentage')}
            />
          )}

          {conditionRemunerationType ===
            ConditionRemunerationType.FixedAmount && (
            <FormInputWithSuffix
              className="mt-s-16"
              label={t('labels.remunerationAmount')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.conditionRemunerationAmount}
              helperText={
                errors.conditionRemunerationAmount?.message &&
                tGlobal(errors.conditionRemunerationAmount?.message, {
                  max: '99,999,999.99',
                  min: '1',
                })
              }
              {...register('conditionRemunerationAmount')}
            />
          )}

          {conditionRemunerationType ===
            ConditionRemunerationType.HourlyRate && (
            <div className="mt-s-16">
              <FormInputWithSuffix
                label={t('labels.remunerationHourlyRate')}
                suffix={t('suffix.currencyPerHour')}
                type="number"
                mediumSuffix
                noMargin
                minValue={1}
                maxValue={99999999.99}
                step={0.01}
                invalid={!!errors.conditionRemunerationHourlyRate}
                helperText={
                  errors.conditionRemunerationHourlyRate?.message &&
                  tGlobal(errors.conditionRemunerationHourlyRate?.message, {
                    max: '99,999,999.99',
                    min: '1',
                  })
                }
                {...register('conditionRemunerationHourlyRate')}
              />

              <Fieldset
                className="mt-s-16"
                legend={`${t('labels.remunerationEstimatedHours')}:`}
                legendSize="medium"
              >
                <div className="flex gap-s-16">
                  <FormInputWithSuffix
                    label={t('labels.remunerationEstimatedHoursMin')}
                    suffix={t('suffix.hours')}
                    type="number"
                    mediumSuffix
                    noMargin
                    minValue={1}
                    invalid={!!errors.conditionRemunerationEstimatedHoursMin}
                    helperText={
                      errors.conditionRemunerationEstimatedHoursMin?.message &&
                      tGlobal(
                        errors.conditionRemunerationEstimatedHoursMin?.message
                      )
                    }
                    {...register('conditionRemunerationEstimatedHoursMin')}
                  />

                  <FormInputWithSuffix
                    label={t('labels.remunerationEstimatedHoursMax')}
                    suffix={t('suffix.hours')}
                    type="number"
                    mediumSuffix
                    noMargin
                    minValue={1}
                    invalid={!!errors.conditionRemunerationEstimatedHoursMax}
                    helperText={
                      errors.conditionRemunerationEstimatedHoursMax?.message &&
                      tGlobal(
                        errors.conditionRemunerationEstimatedHoursMax?.message
                      )
                    }
                    {...register('conditionRemunerationEstimatedHoursMax')}
                  />
                </div>
              </Fieldset>
            </div>
          )}
        </Fieldset>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <div className="flex flex-col">
            <div className="mr-s-8">
              <span className="text-16 font-semibold text-primary">
                {recruitmentOpportunityConditions?.paymentMethodValue
                  ? t(
                      `enum.requestConditionPaymentMethodCode.short.${recruitmentOpportunityConditions?.paymentMethodValue?.toLowerCase()}`
                    )
                  : '-'}
              </span>
            </div>
            {recruitmentOpportunityConditions?.paymentFrequencyValue && (
              <div className="mr-s-8 mt-s-24">
                <span className="text-16 font-semibold text-primary">
                  {t(
                    `enum.requestConditionPaymentFrequencyCode.${recruitmentOpportunityConditions?.paymentFrequencyValue?.toLowerCase()}`
                  )}
                </span>
              </div>
            )}
          </div>
        }
      >
        <Stack className="w-full" space={16}>
          <PaymentMethodsFields />
        </Stack>
      </SectionGridRow>
    </SectionGrid>
  );
};

export default MainFields;
