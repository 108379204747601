import { useCallback, useEffect, useMemo, useState } from 'react';

import { RootPrivatePage } from '@application/enums/pagesUrl';
import { RequestChatRoom, RequestChatRoomMessage } from '@domain/graphql.types';

import { ChatRoom } from './components';
import { ChatContent } from './components/ChatRoom';
import {
  useCreateRequestChatRoomMessage,
  useMarkRequestChatRoomMessagesAsRead,
  useRequestChatRoomMessagesList,
  useRequestChatRoomsList,
} from './hooks';

type RecruitmentOpportunityChatContentProps = {
  opportunityId: string;
  accountId: string;
  title: string;
};
const RecruitmentOpportunityChatContent = ({
  opportunityId,
  accountId,
  title,
}: RecruitmentOpportunityChatContentProps) => {
  const [selectedChatRoom, setSelectedChatRoom] = useState<RequestChatRoom>();
  const [messages, setMessages] = useState<RequestChatRoomMessage[]>([]);
  const [enterpriseName, setEnterpriseName] = useState('');

  const { createRequestChatRoomMessage } = useCreateRequestChatRoomMessage();

  const { markMessagesAsRead } = useMarkRequestChatRoomMessagesAsRead();

  const { requestChatRooms } = useRequestChatRoomsList({
    requestId: opportunityId,
    agencyAccountId: accountId,
  });

  const {
    requestChatRoomMessages,
    isLoading: requestChatRoomMessagesIsLoading,
    refetchRequestChatRoomMessages,
  } = useRequestChatRoomMessagesList({
    requestChatRoomId: selectedChatRoom?.id ?? '',
  });

  const chatContent: ChatContent = useMemo(
    () => ({
      messageList: messages.map((message) => {
        const readAtDate = message.readAt ? new Date(message.readAt) : null;
        const now = new Date();

        return {
          position:
            message.accountId === accountId
              ? ('right' as const)
              : ('left' as const),
          date: message.sentAt,
          viewed: !!(readAtDate && readAtDate < now),
          content: message.message,
          isSystem: message.isSystem,
        };
      }),
    }),
    [accountId, messages]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createRequestChatRoomMessage({
        input: {
          requestChatRoomId: selectedChatRoom?.id ?? '',
          message,
        },
      });

      if (requestChatRoomMessages.length < 1) {
        refetchRequestChatRoomMessages();
      }
    },
    [
      createRequestChatRoomMessage,
      selectedChatRoom?.id,
      requestChatRoomMessages.length,
      refetchRequestChatRoomMessages,
    ]
  );

  const handleMarkMessagesAsRead = useCallback(async () => {
    await markMessagesAsRead({
      input: { requestChatRoomId: selectedChatRoom?.id ?? '' },
    });
    refetchRequestChatRoomMessages();
  }, [
    markMessagesAsRead,
    refetchRequestChatRoomMessages,
    selectedChatRoom?.id,
  ]);

  useEffect(() => {
    if (requestChatRooms?.length) {
      setSelectedChatRoom(requestChatRooms[0]);
    }
  }, [requestChatRooms]);

  useEffect(() => {
    if (
      selectedChatRoom &&
      !selectedChatRoom?.isEnterpriseAnonym &&
      selectedChatRoom.enterpriseName
    ) {
      setEnterpriseName(selectedChatRoom.enterpriseName);
    }
  }, [
    selectedChatRoom,
    selectedChatRoom?.enterpriseName,
    selectedChatRoom?.isEnterpriseAnonym,
  ]);

  useEffect(() => {
    if (!requestChatRoomMessages) {
      setMessages([]);
      return;
    }

    const hasSameContent =
      messages.length === requestChatRoomMessages.length &&
      messages.every(
        (message, idx) =>
          message.id === requestChatRoomMessages[idx].id &&
          message.readAt === requestChatRoomMessages[idx].readAt
      );

    if (!hasSameContent) {
      setMessages(requestChatRoomMessages);
    }
  }, [requestChatRoomMessages, messages]);

  return (
    <ChatRoom
      isLoading={requestChatRoomMessagesIsLoading}
      title={title}
      subtitle={enterpriseName}
      chatContent={chatContent}
      backTo={RootPrivatePage.RECRUITMENT_OPPORTUNITIES}
      onSendMessage={handleSendMessage}
      refresh={refetchRequestChatRoomMessages}
      markMessagesAsRead={handleMarkMessagesAsRead}
    />
  );
};

export default RecruitmentOpportunityChatContent;
