import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import {
  ButtonLink,
  PlaceholderSwitcher,
  SectionGrid,
} from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import { PrivatePage } from '@application/enums/pagesUrl';
import ServiceTypes from '@application/views/organization/components/ServiceTypes';
import { Account } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';
import { getExternalLink } from '@utils/link-utils';

type FirmPresentationProps = {
  agencyAccount?: Account;
};

const FirmPresentation = ({ agencyAccount }: FirmPresentationProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });

  const descriptionWrapperClassName =
    'flex flex-col  font-semibold gap-s-8 px-s-16 py-s-8';
  const valueTextClassName = 'text-18 font-normal';

  return (
    <>
      <SummaryHeader
        className="mt-s-16 rounded-t-md"
        headerTitle={t('subtitles.firmPresentation')}
      />
      <SectionGrid fullWidth className="my-s-0" readOnly hideTitle>
        <span className={descriptionWrapperClassName}>
          <span>{t('labels.firmServicesDescription')}</span>
          <PlaceholderSwitcher value={agencyAccount?.employerOfferDescription}>
            <span className={valueTextClassName}>
              {agencyAccount?.employerOfferDescription}
            </span>
          </PlaceholderSwitcher>
        </span>

        <PlaceholderSwitcher value={agencyAccount?.services}>
          <span className={cn('border-t-1', valueTextClassName)}>
            <ServiceTypes
              services={agencyAccount?.services ?? undefined}
              hideBorder
            />
          </span>
        </PlaceholderSwitcher>

        <span
          className={cn(
            descriptionWrapperClassName,
            'flex flex-row border-t-1 p-s-16'
          )}
        >
          <ButtonLink
            className="justify-center min-w-fit w-fit"
            to={generatePath(PrivatePage.ORGANIZATION_PUBLIC_PROFILE, {
              id: agencyAccount?.id || '',
            })}
          >
            {t('labels.viewProfile')}
          </ButtonLink>

          {agencyAccount?.website && (
            <ButtonLink
              className="justify-center min-w-fit w-fit"
              to={getExternalLink(agencyAccount?.website)}
              target="_blank"
            >
              {t('labels.viewWebsite')}
            </ButtonLink>
          )}
        </span>
      </SectionGrid>
    </>
  );
};

export default FirmPresentation;
