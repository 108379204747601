import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { Offer } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type ReplacementGuaranteeConditionsProps = {
  data: Offer | undefined;
};

const ReplacementGuaranteeConditions = ({
  data,
}: ReplacementGuaranteeConditionsProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tGlobal } = useTranslation();

  const recruitmentOpportunity = data?.recruitmentOpportunity;
  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.generalContractualConditions;
  const conditions = data?.generalContractualConditions;

  return (
    <>
      <SectionGridRow
        isOffer
        className={
          conditions?.minGuaranteedPeriodOffered ? 'py-s-0 min-h-[120px]' : ''
        }
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.minGuaranteedPeriodValue
              ? `${recruitmentOpportunityConditions.minGuaranteedPeriodValue} ${t('suffix.months')}`
              : tGlobal('boolean.false')}
          </span>
        }
      >
        <div className="w-full flex flex-col h-full">
          <div
            className={cn(
              'flex items-center',
              conditions?.minGuaranteedPeriodOffered && 'h-1/2'
            )}
          >
            <span className="mr-s-8">
              {t('labels.minGuaranteedPeriodOffered')}
            </span>
            <span className="text-16 font-semibold text-primary">
              {conditions?.minGuaranteedPeriodOffered
                ? tGlobal('boolean.true')
                : tGlobal('boolean.false')}
            </span>
          </div>
          {conditions?.minGuaranteedPeriodOffered && (
            <div className="relative flex items-center h-1/2">
              <div className="pt-2">
                <span className="mr-s-8">
                  {t('labels.minGuaranteedPeriod')}
                </span>
                <span className="text-16 font-semibold text-primary">
                  {conditions?.minGuaranteedPeriod}
                </span>
                <span className="ml-s-8">
                  {t('labels.minGuaranteedPeriodEnding')}
                </span>
              </div>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        className="py-s-0"
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.minGuaranteedPeriodBonusValue
              ? tGlobal('suffix.currencyValue', {
                  value:
                    recruitmentOpportunityConditions.minGuaranteedPeriodBonusValue,
                })
              : '-'}
          </span>
        }
      >
        <div className="flex items-center">
          {conditions?.minGuaranteedPeriodBonusAsked ? (
            <div>
              <span className="mr-s-8">
                {t('labels.minGuaranteedPeriodBonus')}
              </span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.minGuaranteedPeriodBonus
                  ? tGlobal('suffix.currencyValue', {
                      value: conditions.minGuaranteedPeriodBonus,
                    })
                  : '-'}
              </span>
              <span className="ml-s-8">
                {t('labels.minGuaranteedPeriodBonusEnding')}
              </span>
            </div>
          ) : (
            <span>{t('labels.minGuaranteedPeriodBonusAsked')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        className="py-s-0"
        rightChildren={
          <span className="text-16 font-semibold text-primary">
            {recruitmentOpportunityConditions?.probationPeriodValue
              ? `${recruitmentOpportunityConditions.probationPeriodValue} ${t('suffix.weeks')}`
              : '-'}
          </span>
        }
      >
        <div className="flex items-center">
          {conditions?.probationPeriodAsked ? (
            <>
              <span className="mr-s-8">{t('labels.probationPeriod')}</span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.probationPeriod
                  ? `${conditions.probationPeriod} ${t('suffix.weeks')}`
                  : '-'}
              </span>
            </>
          ) : (
            <span>{t('labels.probationPeriodAsked')}</span>
          )}
        </div>
      </SectionGridRow>
    </>
  );
};

export default ReplacementGuaranteeConditions;
