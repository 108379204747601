import { DateTime } from 'luxon';

import { mapCodeDescriptions, ValueText } from '@application/utils';
import {
  AccountAdvantageTypeCode,
  EducationLevel,
  EducationLevelCode,
  EducationLevelsResponse,
  JobDuration,
  JobDurationsResponse,
  JobExperienceLevel,
  JobExperienceLevelsResponse,
  JobMode,
  JobModeCode,
  JobModesResponse,
  JobPaymentFrequenciesResponse,
  JobPaymentFrequency,
  JobPaymentOtherType,
  JobPaymentOtherTypesCode,
  JobPaymentOtherTypesResponse,
  JobScheduleType,
  JobScheduleTypeCode,
  JobScheduleTypesResponse,
  OfferConditionOtherBonusCode,
  OperationTerritoryCode,
  Query,
  Request,
  RequestConditionOtherBonusCode,
  RequestTypeCode,
} from '@domain/graphql.types';
import { parseNumber } from '@utils/data-utils';

import { RequestFormFields } from './schema';

export const normalizeGetJobDurationsData = ({
  jobDurations: { values = [] } = {} as JobDurationsResponse,
}: Pick<Query, 'jobDurations'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as JobDuration[]);

export const normalizeGetJobExperienceLevelsData = ({
  jobExperienceLevels: { values = [] } = {} as JobExperienceLevelsResponse,
}: Pick<Query, 'jobExperienceLevels'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as JobExperienceLevel[]);

export const normalizeGetEducationLevelsData = ({
  educationLevels: { values = [] } = {} as EducationLevelsResponse,
}: Pick<Query, 'educationLevels'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as EducationLevel[]);

export const normalizeGetJobScheduleTypesData = ({
  jobScheduleTypes: { values = [] } = {} as JobScheduleTypesResponse,
}: Pick<Query, 'jobScheduleTypes'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as JobScheduleType[]);

export const normalizeGetJobModesData = ({
  jobModes: { values = [] } = {} as JobModesResponse,
}: Pick<Query, 'jobModes'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as JobMode[]);

export const normalizeGetJobPaymentFrequenciesData = ({
  jobPaymentFrequencies: { values = [] } = {} as JobPaymentFrequenciesResponse,
}: Pick<Query, 'jobPaymentFrequencies'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as JobPaymentFrequency[]);

export const normalizeGetJobPaymentOtherTypesData = ({
  jobPaymentOtherTypes: { values = [] } = {} as JobPaymentOtherTypesResponse,
}: Pick<Query, 'jobPaymentOtherTypes'> | Record<string, never>): ValueText[] =>
  mapCodeDescriptions(values as JobPaymentOtherType[]);

export const normalizeRequestInputData = (data: RequestFormFields) => ({
  ...data,
  antiGhostingConsent: undefined,
  types: (data.types ?? []).concat(
    data.openToInternationalRecruitment
      ? [RequestTypeCode.InternationalRecruitment]
      : []
  ),
  desiredStartDate: data.desiredStartDate
    ? DateTime.fromISO(data.desiredStartDate).toJSDate()
    : null,
  receivingOfferDeadline: data.receivingOfferDeadline
    ? DateTime.fromISO(data.receivingOfferDeadline).toJSDate()
    : null,
  jobHourlyBonus: data.jobHourlyBonus?.toFixed(2) || null,
  jobRemunerationAmount: data.jobRemunerationAmount?.toString() || null,
  jobOtherRemunerationAmount:
    data.jobOtherRemunerationAmount?.toFixed(2) || null,
  jobHourlyRateMin: data.jobHourlyRateMin?.toFixed(2) || null,
  jobHourlyRateMax: data.jobHourlyRateMax?.toFixed(2) || null,
  /* GENERAL CONDITIONS */
  conditionDepositValue: data.conditionDepositValue?.toFixed(2) || null,
  conditionMinGuaranteedPeriodValue: data.conditionMinGuaranteedPeriodAsked
    ? data.conditionMinGuaranteedPeriodValue
    : null,
  conditionMinGuaranteedPeriodBonusOffered:
    data.conditionMinGuaranteedPeriodAsked
      ? data.conditionMinGuaranteedPeriodBonusOffered
      : null,
  conditionMinGuaranteedPeriodBonusValue:
    data.conditionMinGuaranteedPeriodAsked &&
    data.conditionMinGuaranteedPeriodValue
      ? data.conditionMinGuaranteedPeriodBonusValue?.toFixed(2) || null
      : null,
  conditionProbationPeriodOffered: data.conditionMinGuaranteedPeriodAsked
    ? data.conditionProbationPeriodOffered
    : null,
  conditionProbationPeriodValue:
    data.conditionMinGuaranteedPeriodAsked &&
    data.conditionProbationPeriodOffered
      ? data.conditionProbationPeriodValue
      : null,
  conditionHiredDateBonusRequirement:
    data.conditionOtherBonusesOffered &&
    data.conditionHiredDateBonusRequirement &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.HiringSpeed
    )
      ? DateTime.fromISO(data.conditionHiredDateBonusRequirement).toJSDate()
      : null,
  conditionHiredDateBonusValue:
    data.conditionOtherBonusesOffered &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.HiringSpeed
    )
      ? (data.conditionHiredDateBonusValue?.toFixed(2) ?? null)
      : null,
  conditionBonusMonthValue:
    data.conditionOtherBonusesOffered &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.CandidateCompanyLongivity
    )
      ? (data.conditionBonusMonthValue ?? null)
      : null,
  conditionBonusValue:
    data.conditionOtherBonusesOffered &&
    data.conditionOtherBonuses?.includes(
      OfferConditionOtherBonusCode.CandidateCompanyLongivity
    )
      ? (data.conditionBonusValue?.toFixed(2) ?? null)
      : null,
  conditionMinimumBudgetValue:
    data.conditionMinimumBudgetValue?.toFixed(2) || null,
  conditionMaximumBudgetValue:
    data.conditionMaximumBudgetValue?.toFixed(2) || null,
  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumBudgetValue:
    data.outsourcingConditionMaximumBudgetValue?.toFixed(2) || null,
  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuybackBonus:
    data.temporaryPlacementConditionPossibleBuybackBonus?.toFixed(2) || null,
  industryType: undefined,
  industrySector: undefined,
  usePreferredSpecialties: undefined,
  openToInternationalRecruitment: undefined,
  citiesSubRegions:
    data.jobModeCode === JobModeCode.Remote ? null : data.citiesSubRegions,
  operationTerritoryCodes:
    data.jobModeCode === JobModeCode.Remote
      ? null
      : data.operationTerritoryCodes,
  conditionOtherBonusValue: data.conditionOtherBonusesOffered
    ? data.conditionOtherBonusValue?.toFixed(2)
    : null,
  conditionOtherBonuses:
    data.conditionOtherBonusesOffered && data.conditionOtherBonuses
      ? [...data.conditionOtherBonuses]
      : null,
  conditionOtherBonusDescription:
    data.conditionOtherBonusesOffered &&
    data.conditionOtherBonuses?.includes(OfferConditionOtherBonusCode.Other)
      ? data.conditionOtherBonusDescription
      : null,
  headhunterConditionExclusivityPeriodValue:
    data.headhunterConditionExclusivityPeriodOffered
      ? data.headhunterConditionExclusivityPeriodValue
      : null,
  internationalRecruitmentConditionMandatoryWorkPermitValue:
    data.openToInternationalRecruitment
      ? data.internationalRecruitmentConditionMandatoryWorkPermitValue
      : null,
});

export const normalizeRequestEditData = (data: Request): RequestFormFields => ({
  operationUnitId: data.operationUnitId,
  types: data.types?.filter(
    (type) => type !== RequestTypeCode.InternationalRecruitment
  ) as RequestTypeCode[],
  jobSpecialtyCode: data.jobSpecialtyCode,
  industryType: data.jobSpecialty?.sector?.industry?.code,
  industrySector: data.jobSpecialty?.sector?.code,
  specialty: data.specialty,
  desiredStartDate: data.desiredStartDate || null,
  receivingOfferDeadline: data.receivingOfferDeadline || null,
  operationTerritoryCodes:
    data.operationTerritoryCodes as OperationTerritoryCode[],
  jobDurationCode: data.jobDurationCode,
  jobDurationInMonths: data.jobDurationInMonths,
  jobWorkingHours: data.jobWorkingHours as JobScheduleTypeCode[],
  jobModeCode: data.jobModeCode,
  jobFullTimeAvailability: data.jobFullTimeAvailability,
  jobInternalTitle: data.jobInternalTitle,
  jobPartTimeAvailability: data.jobPartTimeAvailability,
  jobAvailabilityInHoursPerWeek:
    (data.jobAvailabilityInHoursPerWeek as string[]) || [],
  jobRemoteModeInDays: data.jobRemoteModeInDays,
  jobOpenPositions: data.jobOpenPositions,
  jobSalaryMin: parseNumber(data.jobSalaryMin),
  jobSalaryMax: parseNumber(data.jobSalaryMax),
  jobPaymentFrequencyCode: data.jobPaymentFrequencyCode,
  jobHourlyRateMin: parseNumber(data.jobHourlyRateMin),
  jobHourlyRateMax: parseNumber(data.jobHourlyRateMax),
  jobHourlyBonus: parseNumber(data.jobHourlyBonus),
  jobRemunerationAmount: parseNumber(data.jobRemunerationAmount),
  jobPaymentOtherTypesCodes:
    data.jobPaymentOtherTypesCodes as JobPaymentOtherTypesCode[],
  jobOtherRemunerationAmount: parseNumber(data.jobOtherRemunerationAmount),
  jobAdditionalNotes: data.jobAdditionalNotes,
  jobDescription: data.jobDescription,
  generalNotes: data.generalNotes,
  openToInternationalRecruitment: data.types?.includes(
    RequestTypeCode.InternationalRecruitment
  ),
  perks: (data.perks as AccountAdvantageTypeCode[]) || [],
  otherPerks: (data.otherPerks as string[]) || [],
  softSkills: (data.softSkills as string[]) || [],
  citiesSubRegions: (data.citiesSubRegions as string[]) || [],
  desiredEducationLevelCodes:
    (data.desiredEducationLevelCodes as EducationLevelCode[]) || [],
  desiredStudyField: data.desiredStudyField,
  jobExperienceLevelMax: data.jobExperienceLevelMax,
  jobExperienceLevelMin: data.jobExperienceLevelMin,
  sectorCode: data.sectorCode,

  /* GENERAL CONDITIONS */
  conditionMaxRecruitmentPeriodValue:
    data.generalContractualConditions.maxRecruitmentPeriodValue,
  conditionMaxRecruitmentPeriodDisplay:
    data.generalContractualConditions.maxRecruitmentPeriodDisplay,
  conditionCandidateInHandMandatoryValue:
    data.generalContractualConditions.candidateInHandMandatoryValue,
  conditionCandidateInHandMandatoryDisplay:
    data.generalContractualConditions.candidateInHandMandatoryDisplay,
  conditionMinGuaranteedPeriodValue:
    data.generalContractualConditions.minGuaranteedPeriodValue,
  conditionMinGuaranteedPeriodDisplay:
    data.generalContractualConditions.minGuaranteedPeriodDisplay,
  conditionProbationPeriodOffered:
    data.generalContractualConditions.probationPeriodOffered,
  conditionProbationPeriodValue:
    data.generalContractualConditions.probationPeriodValue,
  conditionProbationPeriodDisplay:
    data.generalContractualConditions.probationPeriodDisplay,
  conditionPaymentMethodValue:
    data.generalContractualConditions.paymentMethodValue,
  conditionPaymentAmountValue:
    data.generalContractualConditions.paymentAmountValue,
  conditionDepositValue: parseNumber(
    data.generalContractualConditions.depositValue
  ),
  conditionDepositDisplay: data.generalContractualConditions.depositDisplay,
  conditionPaymentFrequencyValue:
    data.generalContractualConditions.paymentFrequencyValue,
  conditionPaymentMethodDisplay:
    data.generalContractualConditions.paymentMethodDisplay,
  conditionBudgetDisplay: data.generalContractualConditions?.budgetDisplay,
  conditionBonusValue: parseNumber(
    data.generalContractualConditions?.bonusValue
  ),
  conditionBonusMonthValue: data.generalContractualConditions?.bonusMonthValue,
  conditionBonusDisplay: data.generalContractualConditions?.bonusDisplay,
  conditionMinimumBudgetValue: parseNumber(
    data.generalContractualConditions?.minimumBudgetValue
  ),
  conditionMaximumBudgetValue: parseNumber(
    data.generalContractualConditions?.maximumBudgetValue
  ),
  conditionMinGuaranteedPeriodBonusValue: parseNumber(
    data.generalContractualConditions?.minGuaranteedPeriodBonusValue
  ),
  conditionHiredDateBonusDisplay:
    data.generalContractualConditions.hiredDateBonusDisplay,

  conditionHiredDateBonusRequirement:
    data.generalContractualConditions.hiredDateBonusRequirement ?? null,
  conditionHiredDateBonusValue: parseNumber(
    data.generalContractualConditions?.hiredDateBonusValue
  ),
  conditionMinGuaranteedPeriodAsked:
    data.generalContractualConditions.minGuaranteedPeriodAsked,
  conditionMinGuaranteedPeriodBonusOffered:
    data.generalContractualConditions.minGuaranteedPeriodBonusOffered,
  conditionOtherBonusesOffered:
    data.generalContractualConditions.otherBonusesOffered,
  conditionOtherBonuses: data.generalContractualConditions.otherBonuses
    ? (data.generalContractualConditions
        .otherBonuses as RequestConditionOtherBonusCode[])
    : [],
  conditionOtherBonusDescription:
    data.generalContractualConditions.otherBonusDescription,
  conditionOtherBonusValue: parseNumber(
    data.generalContractualConditions.otherBonusValue
  ),

  /* HEADHUNTER CONDITIONS */
  headhunterConditionMaximumReplacementValue:
    data.headhunterContractualConditions?.maximumReplacementValue,
  headhunterConditionMaximumReplacementDisplay:
    data.headhunterContractualConditions?.maximumReplacementDisplay,
  headhunterConditionExclusivityPeriodValue:
    data.headhunterContractualConditions?.headhuntingExclusivityPeriodValue,
  headhunterConditionExclusivityPeriodOffered:
    data.headhunterContractualConditions?.headhuntingExclusivityPeriodOffered,
  headhunterConditionExclusivityPeriodDisplay:
    data.headhunterContractualConditions?.headhuntingExclusivityPeriodDisplay,
  /* INTERNATIONAL RECRUITMENT CONDITIONS */
  internationalRecruitmentConditionMandatoryWorkPermitValue:
    data.internationalRecruitmentContractualConditions
      ?.mandatoryWorkPermitValue,
  internationalRecruitmentConditionMandatoryWorkPermitDisplay:
    data.internationalRecruitmentContractualConditions
      ?.mandatoryWorkPermitDisplay,
  internationalRecruitmentConditionExclusivityPeriodDisplay:
    data.internationalRecruitmentContractualConditions
      ?.exclusivityPeriodDisplay,
  internationalRecruitmentConditionMaximumReplacementDisplay:
    data.internationalRecruitmentContractualConditions
      ?.maximumReplacementDisplay,
  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumDurationValue:
    data.outsourcingContractualConditions?.maximumDurationValue,
  outsourcingConditionMaximumDurationDisplay:
    data.outsourcingContractualConditions?.maximumDurationDisplay,
  outsourcingConditionExclusivityPeriodValue:
    data.outsourcingContractualConditions?.exclusivityPeriodValue,
  outsourcingConditionExclusivityPeriodDisplay:
    data.outsourcingContractualConditions?.exclusivityPeriodDisplay,
  outsourcingConditionMaximumBudgetValue: parseNumber(
    data.outsourcingContractualConditions?.maximumBudgetValue
  ),
  outsourcingConditionMaximumBudgetDisplay:
    data.outsourcingContractualConditions?.maximumBudgetDisplay,
  outsourcingConditionResponsibilities:
    data.outsourcingContractualConditions?.responsibilities,
  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuybackValue:
    data.temporaryPlacementContractualConditions?.possibleBuybackValue,
  temporaryPlacementConditionPossibleBuybackBonus: parseNumber(
    data.temporaryPlacementContractualConditions?.possibleBuybackBonus
  ),
  temporaryPlacementConditionPossibleBuybackDisplay:
    data.temporaryPlacementContractualConditions?.possibleBuybackDisplay,
  temporaryPlacementConditionRisksValue:
    data.temporaryPlacementContractualConditions?.risksValue,
  temporaryPlacementConditionRisksDisplay:
    data.temporaryPlacementContractualConditions?.risksDisplay,
  temporaryPlacementConditionTravelExpensesResponsibilityValue:
    data.temporaryPlacementContractualConditions
      ?.travelExpensesResponsibilityValue,
  temporaryPlacementConditionTrainingExpensesResponsibilityValue:
    data.temporaryPlacementContractualConditions
      ?.trainingExpensesResponsibilityValue,
  temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue:
    data.temporaryPlacementContractualConditions
      ?.employeeHelpProgramResponsibilityValue,
  temporaryPlacementConditionCnesstExpensesResponsibilityValue:
    data.temporaryPlacementContractualConditions
      ?.cnesstExpensesResponsibilityValue,
  temporaryPlacementConditionOtherResponsibilitiesValue:
    data.temporaryPlacementContractualConditions?.otherResponsibilitiesValue,
  temporaryPlacementConditionResponsibilitiesDisplay:
    data.temporaryPlacementContractualConditions?.responsibilitiesDisplay,
  temporaryPlacementConditionForeignApplicantsValue:
    data.temporaryPlacementContractualConditions?.foreignApplicantsValue,
  temporaryPlacementConditionForeignApplicantsDisplay:
    data.temporaryPlacementContractualConditions?.foreignApplicantsDisplay,
});
