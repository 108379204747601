import { gql } from '@urql/core';

const MutationCandidateRequestChatRoomMessagesMarkAsRead = gql`
  mutation CandidateRequestChatRoomMessagesMarkAsRead(
    $input: MarkCandidateRequestChatRoomMessagesAsReadInput!
  ) {
    markCandidateRequestChatRoomMessagesAsRead(input: $input) {
      ok
    }
  }
`;

export default MutationCandidateRequestChatRoomMessagesMarkAsRead;
