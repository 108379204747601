import useOfferCandidateSeenAtUpdate from '@domain/offer/useOfferCandidateSeenAtUpdate';

type UseOfferCandidateSeenAtViewModel = {
  data: any;
  isLoading: boolean;
  error?: Error;
};

const toViewModel = ({
  offerCandidate: { data, fetching, error },
}: {
  offerCandidate: {
    data: any;
    fetching: boolean;
    error?: Error;
  };
}): UseOfferCandidateSeenAtViewModel => ({
  data: data?.offerCandidateUpdateSeenAt?.offerCandidate,
  isLoading: fetching,
  error,
});

const useSeenAtOfferCandidate = () => {
  const { data, fetching, error, updateOfferCandidateSeenAt } =
    useOfferCandidateSeenAtUpdate();

  const viewModel = toViewModel({
    offerCandidate: { data, fetching, error },
  });

  const handleUpdateSeenAt = (offerCandidateId: string) => {
    updateOfferCandidateSeenAt({
      input: { id: offerCandidateId },
    });
  };

  return {
    viewModel,
    updateSeenAt: handleUpdateSeenAt,
  };
};

export default useSeenAtOfferCandidate;
