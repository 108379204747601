import { gql } from 'urql';

const OfferGeneralConditionsFragment = gql`
  fragment OfferGeneralConditionsFragment on OfferGeneralConditions {
    deposit
    depositRequired
    maxRecruitmentPeriod
    minGuaranteedPeriod
    minGuaranteedPeriodOffered
    minGuaranteedPeriodBonus
    minGuaranteedPeriodBonusAsked
    paymentAmount
    paymentFrequency
    paymentMethod
    probationPeriodAsked
    probationPeriod
    remunerationAmount
    remunerationEstimatedHoursMin
    remunerationEstimatedHoursMax
    remunerationHourlyRate
    remunerationPercentage
    remunerationType
    hiredDateBonusRequirement
    hiredDateBonus
    startOfAgreementPaymentMethod
    bonus
    bonusMonth
    otherBonusesAsked
    otherBonuses
    otherBonusDescription
    otherBonusValue
    hasCancellationFee
    cancellationFeeAmount
    cancellationFeeTerms
  }
`;

export default OfferGeneralConditionsFragment;
