import { ChangeEvent, useCallback, useContext, useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseQueryExecute } from 'urql';

import {
  Button,
  ConfirmationModal,
  Fieldset,
  FormInputWithSuffix,
  RadioGroup,
  TextAreaField,
} from '@application/components';
import { Accordion } from '@application/components/accordion';
import { AccordionItem } from '@application/components/accordion/accordion-item';
import { BOOLEAN_VALUES } from '@application/constants';
import { ModalContext } from '@application/context';
import { extractLanguage } from '@utils/i18n-utils';

import { OFFER_CANDIDATE_DEFAULT_VALUES } from '../../../constants';
import { OfferFormFields } from '../../../types';
import { CandidateSummary } from './components';
import { CandidateFields } from './fields';

type CandidatesProposalProps = {
  refetchOffer?: UseQueryExecute;
  isEditing?: boolean;
  mustProposeCandidates: boolean;
};

const CandidatesProposal = ({
  isEditing,
  refetchOffer,
  mustProposeCandidates,
}: CandidatesProposalProps) => {
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const { setModal } = useContext(ModalContext);

  const {
    trigger,
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const candidatesAvailable = watch('candidatesAvailable');

  const {
    fields: candidates,
    append,
    remove,
  } = useFieldArray({
    name: 'offerCandidates',
    control,
  });

  const handleAddCandidate = useCallback(async () => {
    const isValid = await trigger(`offerCandidates`);
    if (isValid || candidates.length === 0) {
      append({ ...(OFFER_CANDIDATE_DEFAULT_VALUES as any) });
    }
  }, [append, candidates.length, trigger]);

  const handleOnCandidatesAvailableChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isAvailable = event.target.value === 'true';

      if (!isAvailable && candidates && candidates.length > 0) {
        setModal({
          title: t('modal.availableCandidatsErrorTitle'),
          maxWidth: '2xl',
          content: (
            <ConfirmationModal
              content={t('modal.availableCandidatsErrorContent')}
              onCancel={() => setModal(null)}
              onConfirm={() => {
                setModal(null);
              }}
            />
          ),
        });
      } else {
        setValue('candidatesAvailable', isAvailable);
        trigger('candidatesAvailable');
        if (isAvailable && candidates.length === 0) {
          append({ ...(OFFER_CANDIDATE_DEFAULT_VALUES as any) });
        }
      }
    },
    [append, candidates, setModal, setValue, t, trigger]
  );

  // In dev it could add two new candidates because of <React.StrictMode>, but it should only be run once in prod.
  useEffect(() => {
    if (mustProposeCandidates && candidates.length === 0) {
      setValue('candidatesAvailable', true);
      append({ ...(OFFER_CANDIDATE_DEFAULT_VALUES as any) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fieldset
      className="w-full flex flex-col"
      contentClassName={
        !mustProposeCandidates ? 'border-1 rounded-md p-s-24' : 'mb-s-24'
      }
      legend={
        mustProposeCandidates
          ? t('subtitles.mustProvideCandidates')
          : t('subtitles.candidatesToSuggest')
      }
      legendSize="big"
    >
      {!mustProposeCandidates && (
        <Controller
          name="candidatesAvailable"
          control={control}
          render={({ field: { name } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.candidatesInStock')}
              language={extractLanguage(i18n.language)}
              onChange={handleOnCandidatesAvailableChange}
              legendSize="medium"
              alignRow
              invalid={!!errors.candidatesAvailable}
              helperText={
                errors.candidatesAvailable?.message &&
                tGlobal(errors.candidatesAvailable?.message)
              }
              value={candidatesAvailable ?? undefined}
            />
          )}
        />
      )}

      {candidatesAvailable !== undefined &&
        (candidatesAvailable ? (
          <>
            <Accordion space={24} className="my-s-24">
              {candidates?.map((candidate, index, array) => (
                <AccordionItem
                  key={candidate.id}
                  summary={<CandidateSummary index={index} />}
                  expanded={index === array.length - 1}
                >
                  <CandidateFields
                    index={index}
                    displayDeleteButton
                    onDelete={() => remove(index)}
                    isEditing={isEditing}
                    refetchOffer={refetchOffer}
                  />
                </AccordionItem>
              ))}
            </Accordion>

            <div className="flex justify-end">
              <Button
                primary
                icon={<i className="ri-add-line" />}
                onClick={handleAddCandidate}
              >
                {t('buttons.addNewCandidate')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Controller
              name="noCandidatesExplaination"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <TextAreaField
                  className="mt-s-40"
                  label={t('subtitles.whyNoCandidates')}
                  labelSize="medium"
                  name={name}
                  value={value || ''}
                  rows={4}
                  maxChar={3000}
                  onChange={onChange}
                  invalid={!!errors.noCandidatesExplaination}
                  helperText={
                    errors.noCandidatesExplaination?.message &&
                    tGlobal(errors.noCandidatesExplaination?.message, {
                      max: '3000',
                    })
                  }
                />
              )}
            />

            <FormInputWithSuffix
              label={t('labels.proposeCandidatesPeriod')}
              type="number"
              minValue={1}
              noMargin
              suffix={t('suffix.weeks')}
              mediumSuffix
              invalid={!!errors.conditionMaxRecruitmentPeriod}
              helperText={
                errors.conditionMaxRecruitmentPeriod?.message &&
                tGlobal(errors.conditionMaxRecruitmentPeriod?.message)
              }
              {...register('conditionMaxRecruitmentPeriod')}
            />
          </>
        ))}
    </Fieldset>
  );
};

export default CandidatesProposal;
