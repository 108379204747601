import { gql } from '@urql/core';

const MutationRequestChatRoomMessagesMarkAsRead = gql`
  mutation RequestChatRoomMessagesMarkAsRead(
    $input: MarkRequestChatRoomMessagesAsReadInput!
  ) {
    markRequestChatRoomMessagesAsRead(input: $input) {
      ok
    }
  }
`;

export default MutationRequestChatRoomMessagesMarkAsRead;
