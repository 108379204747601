import { ReactNode } from 'react';

import { ButtonLink } from '@application/components';
import { cn } from '@utils/lib-utils';

type Size = 'xSmall' | 'small' | 'medium';

type ButtonLinkCounterProps = {
  children?: ReactNode;
  count?: number;
  iconName: string;
  to: string;
  size?: Size;
  from?: string;
  disabled?: boolean;
};

const ButtonLinkCounter = ({
  children,
  count = 0,
  iconName,
  to,
  size = 'small',
  from,
  disabled,
}: ButtonLinkCounterProps) => {
  const className = cn('relative p-s-0 justify-self-end', {
    'min-w-[2rem] min-h-[2rem] h-s-32 w-s-32': size === 'xSmall',
    'min-w-[2.5rem] min-h-[2.5rem] h-s-40 w-s-40': size === 'small',
    'min-w-[3rem] min-h-[3rem] w-s-48 h-s-48 text-16': size === 'medium',
    'w-auto px-s-12 ': children != null,
  });

  return (
    <ButtonLink
      className={className}
      size="small"
      to={to}
      from={from}
      disabled={disabled}
    >
      <div className="flex gap-s-8 items-center">
        <i className={cn(iconName, 'font-normal text-20')} />
        {children}
      </div>
      {count > 0 && (
        <div className="flex justify-center items-center absolute bg-[#F80808] text-primary-content text-10 w-s-18 h-s-18 rounded-full top-[-0.50rem] right-[-0.60rem]">
          <span className="font-normal">{count}</span>
        </div>
      )}
    </ButtonLink>
  );
};
export default ButtonLinkCounter;
