import { gql } from 'urql';

const RequestGeneralConditionsFragment = gql`
  fragment RequestGeneralConditionsFragment on RequestGeneralConditions {
    maxRecruitmentPeriodValue
    maxRecruitmentPeriodDisplay
    candidateInHandMandatoryValue
    candidateInHandMandatoryDisplay
    hiredDateBonusDisplay
    hiredDateBonusRequirement
    hiredDateBonusValue
    minGuaranteedPeriodAsked
    minGuaranteedPeriodBonusValue
    minGuaranteedPeriodDisplay
    minGuaranteedPeriodBonusOffered
    minGuaranteedPeriodValue
    probationPeriodValue
    otherBonusesOffered
    otherBonuses
    otherBonusValue
    otherBonusDescription
    probationPeriodDisplay
    probationPeriodOffered
    paymentMethodValue
    startOfAgreementPaymentMethodValue
    paymentAmountValue
    depositDisplay
    depositValue
    paymentFrequencyValue
    paymentMethodDisplay
    minimumBudgetValue
    maximumBudgetValue
    budgetDisplay
    bonusValue
    bonusMonthValue
    bonusDisplay
  }
`;

export default RequestGeneralConditionsFragment;
