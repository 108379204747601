import React, { ForwardedRef, ReactNode, useMemo } from 'react';

import { SECONDARY_STYLES } from '@application/components/buttons/constants';
import { cn } from '@utils/lib-utils';
import { randomString } from '@utils/math-utils';

type CheckboxProps = {
  id?: string;
  label: ReactNode;
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  primary?: boolean;
  className?: string;
  labelTextClassName?: string;
  checkboxClassName?: string;
  backgroundClassName?: string;
  labelDirection?: 'left' | 'right' | 'top' | 'bottom';
  asButton?: boolean;
  withBackground?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

type Ref = HTMLLabelElement;

const Checkbox = React.forwardRef<Ref, CheckboxProps>(
  (
    {
      id,
      name,
      value,
      label,
      checked,
      disabled,
      primary,
      className: customClassName,
      labelTextClassName: customLabelTextClassName,
      checkboxClassName: customCheckboxClassName,
      backgroundClassName: customBackgroundClassName,
      labelDirection = 'right',
      onChange,
      onBlur,
      asButton,
      withBackground,
      ...rest
    }: CheckboxProps,
    ref: ForwardedRef<Ref>
  ) => {
    /**
     * Note: use a React.useMemo here in order to avoid generating
     * a new random string at each render
     */
    const inputId = useMemo(() => id || randomString(), [id]);

    const checkboxClassName = cn(
      'checkbox w-s-18 h-s-18 rounded-none border-2 border-primary peer z-[1]',
      { 'checkbox-primary': primary },
      customCheckboxClassName
    );

    const labelClassName = cn(
      'relative form-control justify-start items-center cursor-pointer gap-s-10',
      labelDirection === 'top' && 'flex-col-reverse',
      labelDirection === 'bottom' && 'flex-col',
      labelDirection === 'left' && 'flex-row-reverse',
      labelDirection === 'right' && 'flex-row',
      asButton &&
        `btn flex-nowrap border-none h-auto max-h-[3rem] ${SECONDARY_STYLES}`,
      !asButton && 'label',
      checked && asButton && 'bg-secondary',
      customClassName
    );

    const labelTextClassName = cn(
      'label-text font-normal normal-case text-16 z-[1]',
      customLabelTextClassName
    );

    const backgroundClassName = cn(
      'absolute inset-s-0 z-0 pointer-events-none peer-checked:bg-secondary',
      {
        'border border-stroke-default rounded-[inherit]': asButton,
      },
      customBackgroundClassName
    );

    return (
      <label ref={ref} htmlFor={inputId} className={labelClassName} {...rest}>
        <input
          type="checkbox"
          id={inputId}
          name={name}
          value={value}
          checked={checked}
          className={checkboxClassName}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <span className={labelTextClassName}>{label}</span>

        {(withBackground || asButton) && (
          <span className={backgroundClassName} />
        )}
      </label>
    );
  }
);

export default Checkbox;
