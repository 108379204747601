import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  RangeSlider,
  SectionGrid,
  SectionGridRow,
  Tooltip,
} from '@application/components';
import { Cluster, Stack } from '@application/components/container-layouts';

import { RequestFormFields } from '../../../schema';
import PaymentMethodsFields from './PaymentMethodsFields';

type MainFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const MainFields = ({
  control,
  errors,
  setValue,
  trigger,
  watch,
}: MainFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const conditionMinimumBudgetValue = watch('conditionMinimumBudgetValue');
  const conditionMaximumBudgetValue = watch('conditionMaximumBudgetValue');

  return (
    <SectionGrid title={t('subtitles.generalConditions')} fullWidth>
      <SectionGridRow isTopRow>
        <Cluster className="w-full">
          <div className="flex items-center mr-s-8">
            <Tooltip message={t('labels.baselineBudgetTooltips')}>
              <i className="ri-error-warning-line mr-s-8 text-20 leading-none text-neutral-secondary" />
            </Tooltip>
            {t('labels.baselineBudget')}:
          </div>
          <RangeSlider
            minLabel={t('labels.minBudgetInput')}
            maxLabel={t('labels.maxBudgetInput')}
            minName="conditionMinimumBudgetValue"
            maxName="conditionMaximumBudgetValue"
            onChange={(name: string, value: number | string) => {
              setValue(name as keyof RequestFormFields, value, {
                shouldDirty: true,
              });
              trigger(name as keyof RequestFormFields);
            }}
            minValue={conditionMinimumBudgetValue || undefined}
            maxValue={conditionMaximumBudgetValue || undefined}
            min={3500}
            max={100000}
            suffix="$"
            className="w-full"
            helperText={
              (errors.conditionMinimumBudgetValue?.message &&
                tGlobal(errors.conditionMinimumBudgetValue?.message)) ||
              (errors.conditionMaximumBudgetValue?.message &&
                tGlobal(errors.conditionMaximumBudgetValue?.message))
            }
          />
        </Cluster>
      </SectionGridRow>

      <SectionGridRow>
        <Stack space={16}>
          <PaymentMethodsFields
            control={control}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
          />
        </Stack>
      </SectionGridRow>
    </SectionGrid>
  );
};

export default MainFields;
