import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type OutsourcingConditionsProps = {
  data: Request | undefined;
};

const OutsourcingConditions = ({ data }: OutsourcingConditionsProps) => {
  const { t } = useTranslation('requests');

  return (
    <div>
      <SectionGridRow isTopRow>
        <span className="mr-s-8">{t('labels.maxMandateDuration')}</span>
        <PlaceholderSwitcher
          value={data?.outsourcingContractualConditions?.maximumDurationValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.outsourcingContractualConditions?.maximumDurationValue}
            &nbsp;
            {t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">
          {t('labels.outsourcingExclusivityPeriod')}
        </span>
        <PlaceholderSwitcher
          value={data?.outsourcingContractualConditions?.exclusivityPeriodValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.outsourcingContractualConditions?.exclusivityPeriodValue}
            &nbsp;{t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.maxCandidateBudget')}</span>
        <PlaceholderSwitcher
          value={!!data?.outsourcingContractualConditions?.maximumBudgetValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.outsourcingContractualConditions?.maximumBudgetValue}
            {t('suffix.currencyPerHour')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow className="col-span-2">
        <span className="mr-s-8">
          {t('labels.recruitmentResponsibilities')}
        </span>

        <span className="text-18 font-semibold text-primary">
          {data?.outsourcingContractualConditions?.responsibilities || '-'}
        </span>
      </SectionGridRow>
    </div>
  );
};

export default OutsourcingConditions;
