import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import { Offer, Request } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type ReplacementGuaranteeConditionsProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const ReplacementGuaranteeConditions = ({
  data,
  request,
}: ReplacementGuaranteeConditionsProps) => {
  const { t } = useTranslation('requests', { keyPrefix: 'viewOffer' });
  const { t: tGlobal } = useTranslation();

  const conditions = data?.generalContractualConditions;
  const requestConditions = request?.generalContractualConditions;

  return (
    <>
      <SectionGridRow
        isTopRow
        isOffer
        className={
          conditions?.minGuaranteedPeriodOffered ? 'py-s-0 min-h-[120px]' : ''
        }
        rightChildren={
          <PlaceholderSwitcher
            value={requestConditions?.minGuaranteedPeriodValue}
          >
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.minGuaranteedPeriodValue
                ? `${requestConditions.minGuaranteedPeriodValue} ${tGlobal('suffix.months')}`
                : tGlobal('boolean.false')}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="w-full flex flex-col h-full">
          <div
            className={cn(
              'flex items-center',
              conditions?.minGuaranteedPeriodOffered && 'h-1/2'
            )}
          >
            <span className="mr-s-8">
              {t('labels.minGuaranteedPeriodOffered')}
            </span>
            <span className="text-16 font-semibold text-primary">
              {conditions?.minGuaranteedPeriodOffered
                ? tGlobal('boolean.true')
                : tGlobal('boolean.false')}
            </span>
          </div>
          {conditions?.minGuaranteedPeriodOffered && (
            <div className="relative flex items-center h-1/2">
              <hr className="absolute top-s-0 h-px w-[calc(100%-2rem)] border-t" />
              <div className="pt-2">
                <span className="mr-s-8">
                  {t('labels.minGuaranteedPeriod')}
                </span>
                <span className="text-16 font-semibold text-primary">
                  {conditions?.minGuaranteedPeriod}
                </span>
                <span className="ml-s-8">
                  {t('labels.minGuaranteedPeriodEnding')}
                </span>
              </div>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        className="py-s-0"
        rightChildren={
          <PlaceholderSwitcher
            value={requestConditions?.minGuaranteedPeriodBonusValue}
          >
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.minGuaranteedPeriodBonusValue
                ? tGlobal('suffix.currencyValue', {
                    value: requestConditions.minGuaranteedPeriodBonusValue,
                  })
                : '-'}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          {conditions?.minGuaranteedPeriodBonusAsked ? (
            <div>
              <span className="mr-s-8">
                {t('labels.minGuaranteedPeriodBonus')}
              </span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.minGuaranteedPeriodBonus
                  ? tGlobal('suffix.currencyValue', {
                      value: conditions.minGuaranteedPeriodBonus,
                    })
                  : '-'}
              </span>
              <span className="ml-s-8">
                {t('labels.minGuaranteedPeriodBonusEnding')}
              </span>
            </div>
          ) : (
            <span>{t('labels.minGuaranteedPeriodBonusAsked')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        className="py-s-0"
        rightChildren={
          <PlaceholderSwitcher value={requestConditions?.probationPeriodValue}>
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.probationPeriodValue
                ? `${requestConditions.probationPeriodValue} ${tGlobal('suffix.weeks')}`
                : '-'}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          {conditions?.probationPeriodAsked ? (
            <>
              <span className="mr-s-8">{t('labels.probationPeriod')}</span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.probationPeriod
                  ? `${conditions.probationPeriod} ${tGlobal('suffix.weeks')}`
                  : '-'}
              </span>
            </>
          ) : (
            <span>{t('labels.probationPeriodAsked')}</span>
          )}
        </div>
      </SectionGridRow>
    </>
  );
};

export default ReplacementGuaranteeConditions;
