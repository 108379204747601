import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { extractLanguage } from '@utils/i18n-utils';

import { RequestFormFields } from '../../schema';

type ReplacementGuaranteeFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
};

const ReplacementGuaranteeFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
  trigger,
}: ReplacementGuaranteeFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const replacementGuarantee = watch('conditionMinGuaranteedPeriodAsked');
  const replacementGuaranteeBonus = watch(
    'conditionMinGuaranteedPeriodBonusOffered'
  );
  const conditionProbationPeriod = watch('conditionProbationPeriodOffered');

  return (
    <SectionGrid
      title={t('subtitles.replacementGuarantee')}
      tooltipMessage={t('subtitles.replacementGuaranteeTooltip')}
      className="mt-s-40"
      fullWidth
    >
      <SectionGridRow className="block" isTopRow>
        <Controller
          name="conditionMinGuaranteedPeriodAsked"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              legendClassName="max-w-[calc(100%-180px)]"
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.replacementGuarantee')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value === 'true');
                trigger('conditionMinGuaranteedPeriodAsked');
              }}
              legendSize="medium"
              alignRow
              value={replacementGuarantee ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />
      </SectionGridRow>

      {replacementGuarantee && (
        <>
          <SectionGridRow>
            <FormInputWithSuffix
              label={t('labels.replacementGuaranteeDuration')}
              suffix={t('suffix.months')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              invalid={!!errors.conditionMinGuaranteedPeriodValue}
              helperText={
                errors.conditionMinGuaranteedPeriodValue?.message &&
                tGlobal(errors.conditionMinGuaranteedPeriodValue?.message, {
                  min: '1',
                })
              }
              {...register('conditionMinGuaranteedPeriodValue')}
            />
          </SectionGridRow>

          <SectionGridRow>
            <Controller
              name="conditionMinGuaranteedPeriodBonusOffered"
              control={control}
              render={({ field: { name }, fieldState: { error } }) => (
                <RadioGroup
                  legendClassName="max-w-[calc(100%-180px)]"
                  name={name}
                  data={BOOLEAN_VALUES}
                  legend={t('labels.replacementGuaranteeOfferedBonus')}
                  language={extractLanguage(i18n.language)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, event.target.value === 'true');
                    trigger('conditionMinGuaranteedPeriodBonusOffered');
                  }}
                  legendSize="medium"
                  alignRow
                  value={replacementGuaranteeBonus ?? undefined}
                  invalid={!!error}
                  helperText={error?.message && tGlobal(error?.message)}
                />
              )}
            />
          </SectionGridRow>

          {replacementGuaranteeBonus && (
            <SectionGridRow>
              <FormInputWithSuffix
                type="number"
                minValue={0}
                maxValue={99999999.99}
                step={0.01}
                invalid={!!errors.conditionMinGuaranteedPeriodBonusValue}
                noMargin
                helperText={
                  errors.conditionMinGuaranteedPeriodBonusValue?.message &&
                  tGlobal(
                    errors.conditionMinGuaranteedPeriodBonusValue?.message,
                    {
                      max: '99,999,999.99',
                      min: '1',
                    }
                  )
                }
                label={t('labels.replacementGuaranteeProbationAmount')}
                suffix={t('suffix.currency')}
                {...register('conditionMinGuaranteedPeriodBonusValue')}
              />
            </SectionGridRow>
          )}

          <SectionGridRow>
            <Controller
              name="conditionProbationPeriodOffered"
              control={control}
              render={({ field: { name }, fieldState: { error } }) => (
                <RadioGroup
                  legendClassName="max-w-[calc(100%-180px)]"
                  name={name}
                  data={BOOLEAN_VALUES}
                  legend={t('labels.replacementGuaranteeProbation')}
                  language={extractLanguage(i18n.language)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, event.target.value === 'true');
                    trigger('conditionProbationPeriodOffered');
                  }}
                  legendSize="medium"
                  alignRow
                  value={conditionProbationPeriod ?? undefined}
                  invalid={!!error}
                  helperText={error?.message && tGlobal(error?.message)}
                />
              )}
            />
          </SectionGridRow>

          {conditionProbationPeriod && (
            <SectionGridRow>
              <FormInputWithSuffix
                inputClassName="min-w-s-96"
                label={t('labels.replacementGuaranteeProbationDuration')}
                suffix={t('suffix.weeks')}
                type="number"
                mediumSuffix
                noMargin
                minValue={1}
                invalid={!!errors.conditionProbationPeriodValue}
                helperText={
                  errors.conditionProbationPeriodValue?.message &&
                  tGlobal(errors.conditionProbationPeriodValue?.message, {
                    min: '1',
                  })
                }
                {...register('conditionProbationPeriodValue')}
              />
            </SectionGridRow>
          )}
        </>
      )}
    </SectionGrid>
  );
};

export default ReplacementGuaranteeFields;
