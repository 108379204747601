import { gql } from 'urql';

import { DescriptionsFragment } from '../../../descriptions';

const CandidateNegotiationRoomListFragment = gql`
  ${DescriptionsFragment}

  fragment CandidateNegotiationRoomListFragment on CandidateNegotiationRoom {
    id
    updatedAt
    notificationCount
    candidateRequestChatRoomUnreadMessagesCount
    candidateRequest {
      id
      type
      jobSpecialty {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
      operationTerritories {
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
  }
`;

export default CandidateNegotiationRoomListFragment;
