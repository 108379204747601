import { useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useLocation, useParams } from 'react-router';

import {
  Button,
  ButtonLink,
  ConfirmationModal,
  Link,
  PageHeader,
  PageLoader,
  RankBadge,
  Stack,
} from '@application/components';
import { ButtonLinkCounter } from '@application/components/buttons';
import { ModalContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import {
  useGetPlainCandidateOffer,
  useRejectCandidateOffers,
} from '@application/views/candidate/offer/hooks';
import { useGetCandidateRequest } from '@application/views/candidate/request/hooks';
import { OfferStatusTypeCode } from '@domain/graphql.types';

import { AgencySummary, CandidateSummary } from './offer-summary';

const OfferDetailsPage = () => {
  const { t } = useTranslation('candidates');
  const { t: tChat } = useTranslation('chatRoom', { keyPrefix: 'button' });

  const { id = '', offerId = '' } = useParams();
  const location = useLocation();

  const { setModal } = useContext(ModalContext);

  const {
    viewModel: { data, isLoading },
    refetchCandidateOffer,
  } = useGetPlainCandidateOffer(offerId);

  const {
    viewModel: { data: request },
  } = useGetCandidateRequest(id);

  const { rejectCandidateOffers } = useRejectCandidateOffers();

  const handleRejectOffer = useCallback(async () => {
    setModal(null);

    const result = await rejectCandidateOffers({ input: { ids: [offerId] } });

    if (result.data?.candidateOffersReject.candidateOffers) {
      refetchCandidateOffer();
    }
  }, [setModal, rejectCandidateOffers, offerId, refetchCandidateOffer]);

  const confirmOfferRejection = useCallback(() => {
    setModal({
      title: t('modal.titles.rejectOffer'),
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p className="whitespace-pre-line">
                <Trans
                  i18nKey="modal.contents.confirmRejectOffer"
                  t={t}
                  values={{
                    name: data?.account.name,
                  }}
                />
              </p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={handleRejectOffer}
        />
      ),
    });
  }, [setModal, t, data?.account.name, handleRejectOffer]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={
          data?.linkProfile ? (
            <span className="h3">
              <span>{t('title.offerDetailsWithoutName')}&nbsp;</span>

              <Link
                className="text-24"
                to={generatePath(PrivatePage.ORGANIZATION_PUBLIC_PROFILE, {
                  id: data?.accountId,
                })}
              >
                {data?.account?.name}
              </Link>
            </span>
          ) : (
            t('title.offerDetails', { accountName: data?.account?.name })
          )
        }
        complement={
          <RankBadge
            rank={Number(data?.rank)}
            className="mr-s-16 min-w-[2rem] text-center"
          />
        }
        backTo={generatePath(PrivatePage.CANDIDATE_OFFER_COMPARISON, { id })}
      >
        <div className="flex gap-s-16 items-center">
          {request?.candidateNegotiation && (
            <ButtonLink
              icon={<i className="ri-shake-hands-line" />}
              to={generatePath(PrivatePage.CANDIDATE_NEGOTIATION_DETAILS, {
                id: request?.candidateNegotiation?.id || '#',
              })}
              primary
              from={location.pathname}
            >
              {t('button.goToNegoRoom')}
            </ButtonLink>
          )}
          {/* If the offer has no nego room AND the offer is not rejected yet -> Show the reject button */}
          {/* TODO: Showing the status here when the offer is declined would be great instead of nothing */}
          {!data?.negotiationRoomId &&
            data?.status !== OfferStatusTypeCode.Rejected && (
              <Button
                icon={<i className="ri-prohibited-line" />}
                onClick={confirmOfferRejection}
              >
                {t('button.reject')}
              </Button>
            )}
          <ButtonLinkCounter
            iconName="ri-question-answer-line"
            to={generatePath(PrivatePage.CANDIDATE_REQUEST_CHAT, {
              id: data?.candidateRequestId ?? '',
            })}
            from={generatePath(PrivatePage.CANDIDATE_OFFER_DETAILS, {
              id: data?.candidateRequestId ?? '',
              offerId: data?.id ?? '',
            })}
            count={request?.unreadMessagesCount}
          >
            {tChat('chat')}
          </ButtonLinkCounter>
        </div>
      </PageHeader>

      <CandidateSummary offer={data} request={request} />
      <AgencySummary offer={data} request={request} />
    </>
  );
};

export default OfferDetailsPage;
