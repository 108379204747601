import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type ReplacementGuaranteeProps = {
  data: Request | undefined;
};

const ReplacementGuarantee = ({ data }: ReplacementGuaranteeProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow isTopRow>
        <span className="mr-s-8">{t('labels.replacementGuaranteeAsked')}</span>
        <PlaceholderSwitcher
          value={data?.generalContractualConditions.minGuaranteedPeriodAsked}
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${
                data?.generalContractualConditions.minGuaranteedPeriodAsked
              }`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      {data?.generalContractualConditions?.minGuaranteedPeriodAsked &&
        data?.generalContractualConditions.minGuaranteedPeriodValue && (
          <SectionGridRow>
            <span className="mr-s-8">
              {t('labels.replacementGuaranteeMinAskedPeriod1')}
              <span className="text-18 font-semibold text-primary">
                {data?.generalContractualConditions.minGuaranteedPeriodValue}
                &nbsp;{t('suffix.months')}
              </span>
              {t('labels.replacementGuaranteeMinAskedPeriod2')}
            </span>
          </SectionGridRow>
        )}

      {data?.generalContractualConditions.minGuaranteedPeriodBonusOffered &&
        data?.generalContractualConditions.minGuaranteedPeriodBonusValue && (
          <SectionGridRow>
            <span className="mr-s-8">
              {t('labels.replacementGuaranteeProbationAmountOffered1')}
              <span className="text-18 font-semibold text-primary">
                {t('suffix.currencyValue', {
                  value:
                    data?.generalContractualConditions
                      .minGuaranteedPeriodBonusValue,
                })}
              </span>
              {t('labels.replacementGuaranteeProbationAmountOffered2')}
            </span>
          </SectionGridRow>
        )}

      {data?.generalContractualConditions.probationPeriodOffered &&
        data?.generalContractualConditions.probationPeriodValue && (
          <SectionGridRow>
            <span className="mr-s-8">
              {t('labels.replacementGuaranteeProbationDurationAsked')}
            </span>
            <span className="text-18 font-semibold text-primary">
              {data?.generalContractualConditions.probationPeriodValue}&nbsp;
              {t('suffix.weeks')}
            </span>
          </SectionGridRow>
        )}
    </>
  );
};

export default ReplacementGuarantee;
