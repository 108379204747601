import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type HeadHunterConditionsProps = {
  data: Request | undefined;
};

const HeadHunterConditions = ({ data }: HeadHunterConditionsProps) => {
  const { t } = useTranslation('requests');

  return (
    <>
      <SectionGridRow isTopRow>
        <span className="mr-s-8">{t('labels.duration')}</span>
        <PlaceholderSwitcher
          value={data?.generalContractualConditions.maxRecruitmentPeriodValue}
        >
          <span className="text-18 font-semibold text-primary">
            {data?.generalContractualConditions.maxRecruitmentPeriodValue}
            &nbsp;
            {t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.exclusivityPeriod')}</span>
        <PlaceholderSwitcher
          value={
            data?.headhunterContractualConditions
              ?.headhuntingExclusivityPeriodValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {
              data?.headhunterContractualConditions
                ?.headhuntingExclusivityPeriodValue
            }
            &nbsp;{t('suffix.weeks')}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>
    </>
  );
};

export default HeadHunterConditions;
