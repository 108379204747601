import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGrid } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import { Request, RequestTypeCode } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import GeneralConditions from './GeneralConditions';
import HeadHunterConditions from './HeadHunterConditions';
import InternationalRecruitmentConditions from './InternationalRecrutmentConditions';
import OtherBonusesOffered from './OtherBonusesOffered';
import OutsourcingConditions from './OutsourcingConditions';
import ReplacementGuarantee from './ReplacementGuarantee';
import TemporaryPlacementConditions from './TemporaryPlacementConditions';

type ConditionsSummaryProps = {
  data: Request | undefined;
};

const ConditionsSummary = ({ data }: ConditionsSummaryProps) => {
  const { t } = useTranslation('requests');

  const conditions = useMemo(
    () => [
      {
        title: 'conditions',
        children: <GeneralConditions data={data} />,
        className: 'mt-s-16 rounded-t-md',
        visible: true,
      },
      {
        title: 'headHunter',
        children: <HeadHunterConditions data={data} />,
        visible:
          data?.types?.includes(RequestTypeCode.Headhunter) &&
          !data.generalContractualConditions.candidateInHandMandatoryValue,
      },
      {
        title: 'replacementGuarantee',
        children: <ReplacementGuarantee data={data} />,
        visible: true,
      },
      {
        title: 'otherBonusesOffered',
        children: <OtherBonusesOffered data={data} />,
        visible:
          data?.generalContractualConditions.otherBonusesOffered &&
          (data?.generalContractualConditions.bonusValue ||
            data?.generalContractualConditions.hiredDateBonusValue ||
            data?.generalContractualConditions.otherBonusValue),
      },
      {
        title: 'internationalRecruitment',
        children: <InternationalRecruitmentConditions data={data} />,
        visible: data?.types?.includes(
          RequestTypeCode.InternationalRecruitment
        ),
      },
      {
        title: 'outsourcing',
        children: <OutsourcingConditions data={data} />,
        visible: data?.types?.includes(RequestTypeCode.Outsourcing),
      },
      {
        title: 'temporaryPlacement',
        children: <TemporaryPlacementConditions data={data} />,
        visible: data?.types?.includes(RequestTypeCode.TemporaryHelp),
      },
    ],
    [data]
  );

  const filteredConditions = conditions.filter((c) => c.visible);

  return (
    <>
      {filteredConditions.map(
        (c, index) =>
          c.visible && (
            <Fragment key={c.title}>
              <SummaryHeader
                className={cn('rounded-none', c.className)}
                headerTitle={t(`subtitles.${c.title}`)}
              />

              <SectionGrid
                fullWidth
                gridColsClassName={cn({
                  'rounded-none border-b-0': !(
                    index ===
                    filteredConditions.length - 1
                  ),
                })}
                className="my-s-0"
                readOnly
                title={t(`subtitles.${c.title}`)}
                hideTitle
              >
                {c.children}
              </SectionGrid>
            </Fragment>
          )
      )}
    </>
  );
};

export default ConditionsSummary;
