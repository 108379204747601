import { gql } from 'urql';

const RecruitmentOpportunityGeneralConditionsFragment = gql`
  fragment RecruitmentOpportunityGeneralConditionsFragment on RequestGeneralConditions {
    bonusDisplay
    bonusMonthValue
    bonusValue
    budgetDisplay
    candidateInHandMandatoryDisplay
    candidateInHandMandatoryValue
    depositDisplay
    depositValue
    hiredDateBonusDisplay
    hiredDateBonusRequirement
    hiredDateBonusValue
    maxRecruitmentPeriodDisplay
    maxRecruitmentPeriodValue
    maximumBudgetValue
    minGuaranteedPeriodAsked
    minGuaranteedPeriodBonusValue
    minGuaranteedPeriodDisplay
    minGuaranteedPeriodBonusOffered
    minGuaranteedPeriodValue
    minimumBudgetValue
    otherBonusesOffered
    otherBonuses
    otherBonusValue
    otherBonusDescription
    paymentAmountValue
    paymentFrequencyValue
    paymentMethodDisplay
    paymentMethodValue
    probationPeriodDisplay
    probationPeriodOffered
    probationPeriodValue
    startOfAgreementPaymentMethodValue
  }
`;

export default RecruitmentOpportunityGeneralConditionsFragment;
