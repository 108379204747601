import * as yup from 'yup';

import {
  CandidateRequestFile,
  ConditionRemunerationType,
  EducationLevelCode,
  JobDurationCode,
  JobModeCode,
  JobPaymentFrequencyCode,
  JobPaymentOtherTypesCode,
  JobScheduleTypeCode,
  LinkFileName,
  OfferCandidatesEvaluationProcessCode,
  OfferConditionOtherBonusCode,
  OfferMandateFollowUpCode,
  OfferRegularFollowUpMethodCode,
  OfferRegularFollowUpPeriodCode,
  OfferSimilarMandatesExperiencesCode,
  OfferTalentSourceCode,
  OperationTerritoryCode,
  RequestConditionPaymentFrequencyCode,
  RequestConditionPaymentMethodCode,
  RequestConditionResponsibility,
  RequestConditionStartOfAgreementPaymentMethodCode,
  RequestTypeCode,
} from '@domain/graphql.types';
import { validateDecimal } from '@utils/yup-utils';

const GENERAL_CONDITIONS_SCHEMA = yup.object({
  conditionMaxRecruitmentPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionMinGuaranteedPeriodBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionProbationPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionPaymentMethod: yup
    .mixed<RequestConditionPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionPaymentMethodCode))
    .nullable(),
  conditionStartOfAgreementPaymentMethod: yup
    .mixed<RequestConditionStartOfAgreementPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionStartOfAgreementPaymentMethodCode))
    .nullable(),
  conditionDepositRequired: yup.boolean().nullable(),
  conditionDeposit: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionPaymentFrequency: yup
    .mixed<RequestConditionPaymentFrequencyCode>()
    .oneOf(Object.values(RequestConditionPaymentFrequencyCode))
    .nullable(),
  conditionRemunerationType: yup
    .mixed<ConditionRemunerationType>()
    .oneOf(Object.values(ConditionRemunerationType))
    .nullable(),
  conditionRemunerationPercentage: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  conditionRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionRemunerationHourlyRate: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionRemunerationEstimatedHoursMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  conditionRemunerationEstimatedHoursMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  conditionBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionBonusMonth: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .nullable()
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionHiredDateBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .nullable()
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionHiredDateBonusRequirement: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  conditionMinGuaranteedPeriodOffered: yup.boolean().nullable(),
  conditionMinGuaranteedPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive'),
  conditionMinGuaranteedPeriodBonusAsked: yup.boolean().nullable(),
  conditionProbationPeriodAsked: yup.boolean().nullable(),
  conditionOtherBonusesAsked: yup.boolean().nullable(),
  conditionOtherBonuses: yup
    .array(
      yup
        .mixed<OfferConditionOtherBonusCode>()
        .oneOf(Object.values(OfferConditionOtherBonusCode))
    )
    .nullable(),
  conditionOtherBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionOtherBonusDescription: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength'),
  conditionHasCancellationFee: yup.boolean().nullable(),
  conditionCancellationFeeAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  conditionCancellationFeeTerms: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength'),
});

const HEADHUNTER_CONDITIONS_SCHEMA = yup.object({
  headhunterConditionMaximumReplacement: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  headhunterConditionEstimatedRecruitmentPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  headhunterConditionExclusivityPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  headhunterConditionExclusivityPeriodAsked: yup.boolean().nullable(),
});

const TEMPORARY_PLACEMENT_CONDITIONS_SCHEMA = yup.object({
  temporaryPlacementConditionPossibleBuyback: yup.boolean().nullable(),
  temporaryPlacementConditionPossibleBuybackBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  temporaryPlacementConditionRisks: yup
    .string()
    .nullable()
    .max(1024, 'validations.maxLength'),
  temporaryPlacementConditionTravelExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionTrainingExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionEmployeeHelpProgramResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionCnesstExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .nullable(),
  temporaryPlacementConditionOtherResponsibilities: yup
    .string()
    .nullable()
    .max(1024, 'validations.maxLength'),
  temporaryPlacementConditionForeignApplicants: yup.boolean().nullable(),
});

const INTERNATIONAL_RECRUITMENT_CONDITIONS_SCHEMA = yup.object({
  internationalRecruitmentConditionMandatoryWorkPermit: yup
    .boolean()
    .nullable(),
  internationalRecruitmentConditionExclusivityPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  internationalRecruitmentConditionMaximumReplacement: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
});

const OUTSOURCING_CONDITIONS_SCHEMA = yup.object({
  outsourcingConditionMaximumDuration: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  outsourcingConditionExclusivityPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive'),
  outsourcingConditionMaximumBudget: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  outsourcingConditionResponsibilities: yup.string().nullable(),
});

export const OFFER_CANDIDATE_SCHEMA = yup.object({
  firstName: yup.string().nullable().max(128, 'validations.maxLength'),
  firstNameDisplay: yup.boolean().nullable(),
  lastName: yup.string().nullable().max(128, 'validations.maxLength'),
  lastNameDisplay: yup.boolean().nullable(),
  email: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .max(128, 'validations.maxLength')
    .email('validations.emailValid'),
  emailDisplay: yup.boolean().nullable(),
  experience: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(45, 'validations.maxNumber')
    .nullable(),
  specialty: yup.string().nullable().max(128, 'validations.maxLength'),
  educationLevelCode: yup
    .mixed<EducationLevelCode>()
    .oneOf(Object.values(EducationLevelCode))
    .nullable(),
  studyField: yup.string().nullable().max(128, 'validations.maxLength'),
  operationTerritoryCodes: yup
    .array(
      yup
        .mixed<OperationTerritoryCode>()
        .oneOf(Object.values(OperationTerritoryCode))
    )
    .nullable(),
  resume: yup.lazy((value) =>
    typeof value === 'string'
      ? yup.string().nullable()
      : yup.mixed<CandidateRequestFile>().nullable()
  ),
  otherFiles: yup.lazy(() =>
    yup.array(yup.mixed<File | string>().required()).nullable()
  ),
  linkFileNames: yup.array(yup.mixed<LinkFileName>()).nullable(),
  canWorkInCanadaOrQuebec: yup.boolean().nullable(),
  notes: yup.string().nullable().max(500, 'validations.maxLength'),
  metAt: yup
    .string()
    .transform((_, val) => val || null)
    .nullable(),
  jobTypeCode: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .nullable(),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber'),
  schedules: yup
    .array(
      yup.mixed<JobScheduleTypeCode>().oneOf(Object.values(JobScheduleTypeCode))
    )
    .nullable(),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .nullable(),
  fullTimeAvailability: yup.boolean().nullable(),
  partTimeAvailability: yup.boolean().nullable(),
  hoursPerWeek: yup.array(yup.string()).nullable(),
  allowedRemoteDays: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber'),
  annualSalaryMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  annualSalaryMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive'),
  paymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .nullable(),
  hourlyRateMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyRateMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber'),
  hourlyBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(999.99, 'validations.maxNumber')
    .lessThan(1000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  remunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  otherPaymentTypes: yup
    .array(
      yup
        .mixed<JobPaymentOtherTypesCode>()
        .oneOf(Object.values(JobPaymentOtherTypesCode))
    )
    .nullable(),
  otherRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    ),
  citiesSubRegions: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .nullable(),
  softSkills: yup
    .array(yup.string().max(128, 'validations.maxLength'))
    .nullable(),
});

export const OFFER_CANDIDATE_PUBLISH_SCHEMA = OFFER_CANDIDATE_SCHEMA.shape({
  experience: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(0, 'validations.minNumber')
    .max(45, 'validations.maxNumber')
    .required('validations.required.experience'),
  educationLevelCode: yup
    .mixed<EducationLevelCode>()
    .oneOf(Object.values(EducationLevelCode))
    .required('validations.required.educationLevelCode'),
  jobTypeCode: yup
    .mixed<JobDurationCode>()
    .oneOf(Object.values(JobDurationCode))
    .required('validations.required.jobDurationCode'),
  jobDurationInMonths: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when('jobTypeCode', ([jobTypeCode], schema) =>
      !!jobTypeCode &&
      (jobTypeCode === JobDurationCode.Contract ||
        jobTypeCode === JobDurationCode.Freelance ||
        jobTypeCode === JobDurationCode.Internship)
        ? schema.required('validations.required.jobDurationInMonths')
        : schema.nullable()
    ),

  operationTerritoryCodes: yup
    .array(
      yup
        .mixed<OperationTerritoryCode>()
        .oneOf(Object.values(OperationTerritoryCode))
    )
    .when('jobModeCode', ([jobModeCode]) =>
      jobModeCode === JobModeCode.Remote
        ? yup
            .array(
              yup
                .mixed<OperationTerritoryCode>()
                .oneOf(Object.values(OperationTerritoryCode))
            )
            .nullable()
        : yup
            .array(
              yup
                .mixed<OperationTerritoryCode>()
                .oneOf(Object.values(OperationTerritoryCode))
            )
            .test(
              'notEmptyArray',
              'validations.required.operationTerritoryCodes',
              (value) => Array.isArray(value) && value.length > 0
            )
    ),
  hoursPerWeek: yup
    .array(yup.string())
    .test(
      'notEmptyArray',
      'validations.required.jobAvailabilityInHoursPerWeek',
      (value) => Array.isArray(value) && value.length > 0
    ),
  partTimeAvailability: yup
    .boolean()
    .nullable()
    .test({
      name: 'partTimeAvailability',
      message: 'validations.required.jobAvailability',
      test() {
        return (
          this.parent.fullTimeAvailability || this.parent.partTimeAvailability
        );
      },
    }),
  fullTimeAvailability: yup
    .boolean()
    .nullable()
    .test({
      name: 'fullTimeAvailability',
      message: 'validations.required.jobAvailability',
      test() {
        return (
          this.parent.fullTimeAvailability || this.parent.partTimeAvailability
        );
      },
    }),
  jobModeCode: yup
    .mixed<JobModeCode>()
    .oneOf(Object.values(JobModeCode))
    .required('validations.required.jobModeCode'),
  paymentFrequencyCode: yup
    .mixed<JobPaymentFrequencyCode>()
    .oneOf(Object.values(JobPaymentFrequencyCode))
    .required('validations.required.jobPaymentFrequencyCode'),
  allowedRemoteDays: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .integer('validations.integer')
    .min(1, 'validations.minNumber')
    .max(7, 'validations.maxNumber')
    .when('jobModeCode', ([jobModeCode], schema) =>
      jobModeCode === JobModeCode.Hybrid
        ? schema.required('validations.required.jobRemoteModeInDays')
        : schema.nullable()
    ),
  remunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('paymentFrequencyCode', ([paymentFrequencyCode], schema) =>
      paymentFrequencyCode === JobPaymentFrequencyCode.FixedAmount
        ? schema.required('validations.required.enterAmount')
        : schema.nullable()
    ),
  otherRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('otherPaymentTypes', ([otherPaymentTypes], schema) =>
      otherPaymentTypes?.includes(JobPaymentOtherTypesCode.HiringBonus)
        ? schema.required('validations.required.enterAmount')
        : schema.nullable()
    ),
  schedules: yup
    .array(
      yup.mixed<JobScheduleTypeCode>().oneOf(Object.values(JobScheduleTypeCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.schedules',
      (value) => Array.isArray(value) && value.length > 0
    ),
});

export const OFFER_SCHEMA = yup
  .object({
    requestId: yup.string(),
    linkProfile: yup.boolean().nullable(),
    publishNow: yup.boolean(),
    requestTypes: yup
      .array(yup.mixed<RequestTypeCode>().oneOf(Object.values(RequestTypeCode)))
      .nullable(),
    candidatesAvailable: yup.boolean().nullable(),
    offerCandidates: yup.array().of(OFFER_CANDIDATE_SCHEMA).nullable(),
    salesPitch: yup.string().nullable().max(3000, 'validations.maxLength'),
    noCandidatesExplaination: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    similarMandatesExperiences: yup
      .mixed<OfferSimilarMandatesExperiencesCode>()
      .oneOf(Object.values(OfferSimilarMandatesExperiencesCode))
      .nullable(),
    whyRightToFillMandate: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    canGiveDetailsOnSimilarMandates: yup.boolean().nullable(),
    similarMandatesReferencesDescription: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    whyNoSimilarMandatesReferences: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    talentSources: yup
      .array(
        yup
          .mixed<OfferTalentSourceCode>()
          .oneOf(Object.values(OfferTalentSourceCode))
      )
      .nullable(),
    talentSourcesOthersDescription: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    talentSourcesMoreDetails: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    talentSourcesMethodsToolsDescription: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    candidatesEvaluationProcesses: yup.array(
      yup
        .mixed<OfferCandidatesEvaluationProcessCode>()
        .oneOf(Object.values(OfferCandidatesEvaluationProcessCode))
    ),
    candidatesEvaluationProcessesOthersDescription: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    candidatesEvaluationProcessesMoreDetails: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    mandateFollowUps: yup.array(
      yup
        .mixed<OfferMandateFollowUpCode>()
        .oneOf(Object.values(OfferMandateFollowUpCode))
    ),
    regularFollowUpsMethods: yup.array(
      yup
        .mixed<OfferRegularFollowUpMethodCode>()
        .oneOf(Object.values(OfferRegularFollowUpMethodCode))
    ),
    regularFollowUpsPeriod: yup
      .mixed<OfferRegularFollowUpPeriodCode>()
      .oneOf(Object.values(OfferRegularFollowUpPeriodCode))
      .nullable(),
    mandateFollowUpsOthersDescription: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
    mandateFollowUpsMoreDetails: yup
      .string()
      .nullable()
      .max(3000, 'validations.maxLength'),
  })
  .concat(GENERAL_CONDITIONS_SCHEMA)
  .concat(HEADHUNTER_CONDITIONS_SCHEMA)
  .concat(TEMPORARY_PLACEMENT_CONDITIONS_SCHEMA)
  .concat(INTERNATIONAL_RECRUITMENT_CONDITIONS_SCHEMA)
  .concat(OUTSOURCING_CONDITIONS_SCHEMA);

export const OFFER_SERVICE_INFORMATIONS_PUBLISH_SCHEMA = yup.object({
  similarMandatesExperiences: yup
    .mixed<OfferSimilarMandatesExperiencesCode>()
    .oneOf(Object.values(OfferSimilarMandatesExperiencesCode))
    .required('validations.required.similarMandatesExperiences'),
  whyRightToFillMandate: yup
    .string()
    .max(3000, 'validations.maxLength')
    .when(
      'similarMandatesExperiences',
      ([similarMandatesExperiences], schema) =>
        similarMandatesExperiences ===
        OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
          ? schema.required('validations.required.enterDescription')
          : schema.nullable()
    ),
  canGiveDetailsOnSimilarMandates: yup
    .boolean()
    .when(
      'similarMandatesExperiences',
      ([similarMandatesExperiences], schema) =>
        similarMandatesExperiences !==
        OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
          ? schema.required('validations.required.chooseAnAnswer')
          : schema.nullable()
    ),
  similarMandatesReferencesDescription: yup
    .string()
    .max(3000, 'validations.maxLength')
    .when(
      ['canGiveDetailsOnSimilarMandates', 'similarMandatesExperiences'],
      (
        [canGiveDetailsOnSimilarMandates, similarMandatesExperiences],
        schema
      ) =>
        canGiveDetailsOnSimilarMandates &&
        similarMandatesExperiences !==
          OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
          ? schema.required('validations.required.enterDescription')
          : schema.nullable()
    ),
  whyNoSimilarMandatesReferences: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength')
    .when(
      ['canGiveDetailsOnSimilarMandates', 'similarMandatesExperiences'],
      (
        [canGiveDetailsOnSimilarMandates, similarMandatesExperiences],
        schema
      ) =>
        !canGiveDetailsOnSimilarMandates &&
        similarMandatesExperiences !==
          OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
          ? schema.required('validations.required.enterDescription')
          : schema.nullable()
    ),
  talentSources: yup
    .array(
      yup
        .mixed<OfferTalentSourceCode>()
        .oneOf(Object.values(OfferTalentSourceCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.chooseAtLeastOneOption',
      (value) => Array.isArray(value) && value.length > 0
    ),
  talentSourcesOthersDescription: yup
    .string()
    .max(3000, 'validations.maxLength')
    .when('talentSources', ([talentSources], schema) =>
      talentSources?.includes(OfferTalentSourceCode.Others)
        ? schema.required('validations.required.others')
        : schema.nullable()
    ),
  talentSourcesMoreDetails: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength'),
  talentSourcesMethodsToolsDescription: yup
    .string()
    .max(3000, 'validations.maxLength')
    .required('validations.required.enterDescription'),
  candidatesEvaluationProcesses: yup
    .array(
      yup
        .mixed<OfferCandidatesEvaluationProcessCode>()
        .oneOf(Object.values(OfferCandidatesEvaluationProcessCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.chooseAtLeastOneOption',
      (value) => Array.isArray(value) && value.length > 0
    ),
  candidatesEvaluationProcessesOthersDescription: yup
    .string()
    .max(3000, 'validations.maxLength')
    .when(
      'candidatesEvaluationProcesses',
      ([candidatesEvaluationProcesses], schema) =>
        candidatesEvaluationProcesses?.includes(
          OfferCandidatesEvaluationProcessCode.Others
        )
          ? schema.required('validations.required.others')
          : schema.nullable()
    ),
  candidatesEvaluationProcessesMoreDetails: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength'),
  mandateFollowUps: yup
    .array(
      yup
        .mixed<OfferMandateFollowUpCode>()
        .oneOf(Object.values(OfferMandateFollowUpCode))
    )
    .test(
      'notEmptyArray',
      'validations.required.chooseAtLeastOneOption',
      (value) => Array.isArray(value) && value.length > 0
    ),
  regularFollowUpsMethods: yup
    .array(
      yup
        .mixed<OfferRegularFollowUpMethodCode>()
        .oneOf(Object.values(OfferRegularFollowUpMethodCode))
    )
    .when('mandateFollowUps', ([mandateFollowUps]) =>
      mandateFollowUps?.includes(OfferMandateFollowUpCode.RegularFollowUps)
        ? yup
            .array(
              yup
                .mixed<OfferRegularFollowUpMethodCode>()
                .oneOf(Object.values(OfferRegularFollowUpMethodCode))
            )
            .test(
              'notEmptyArray',
              'validations.required.chooseAtLeastOneOption',
              (value) => Array.isArray(value) && value.length > 0
            )
        : yup
            .array(
              yup
                .mixed<OfferRegularFollowUpMethodCode>()
                .oneOf(Object.values(OfferRegularFollowUpMethodCode))
            )
            .nullable()
    ),
  regularFollowUpsPeriod: yup
    .mixed<OfferRegularFollowUpPeriodCode>()
    .oneOf(Object.values(OfferRegularFollowUpPeriodCode))
    .when('mandateFollowUps', ([mandateFollowUps], schema) =>
      mandateFollowUps?.includes(OfferMandateFollowUpCode.RegularFollowUps)
        ? schema.required('validations.required.chooseAnOption')
        : schema.nullable()
    ),
  mandateFollowUpsOthersDescription: yup
    .string()
    .max(3000, 'validations.maxLength')
    .when('mandateFollowUps', ([mandateFollowUps], schema) =>
      mandateFollowUps?.includes(OfferMandateFollowUpCode.Others)
        ? schema.required('validations.required.others')
        : schema.nullable()
    ),
  mandateFollowUpsMoreDetails: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength'),
});

export const OFFER_CANDIDATE_INFORMATIONS_PUBLISH_SCHEMA = yup.object({
  candidatesAvailable: yup
    .boolean()
    .required('validations.required.candidatesAvailable'),
  requestTypes: yup
    .array(yup.mixed<RequestTypeCode>().oneOf(Object.values(RequestTypeCode)))
    .test(
      'notEmptyArray',
      'validations.required.recruitmentTypes',
      (value) => Array.isArray(value) && value.length > 0
    ),
  salesPitch: yup
    .string()
    .max(3000, 'validations.maxLength')
    .required('validations.required.salesPitch'),
  conditionMaxRecruitmentPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .when('candidatesAvailable', ([candidatesAvailable], schema) =>
      !candidatesAvailable
        ? schema.required(
            'validations.required.conditionMaxRecruitmentPeriodOffer'
          )
        : schema.nullable()
    ),
});

export const OFFER_HEADHUNTER_CONDITIONS_PUBLISH_SCHEMA = yup.object({
  conditionDepositRequired: yup.boolean().required('validations.boolean'),
  conditionDeposit: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .when('conditionDepositRequired', ([conditionDepositRequired], schema) =>
      conditionDepositRequired
        ? schema.required('validations.required.conditionDeposit')
        : schema.nullable()
    ),
  conditionHasCancellationFee: yup.boolean().required('validations.boolean'),
  conditionCancellationFeeAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionHasCancellationFee'],
      ([conditionHasCancellationFee], schema) =>
        conditionHasCancellationFee
          ? schema.required(
              'validations.required.conditionCancellationFeeAmount'
            )
          : schema.nullable()
    ),
  conditionCancellationFeeTerms: yup
    .string()
    .nullable()
    .max(2000, 'validations.maxLength')
    .when(
      ['conditionHasCancellationFee'],
      ([conditionHasCancellationFee], schema) =>
        conditionHasCancellationFee
          ? schema.required('validations.required.enterDescription')
          : schema.nullable()
    ),
  headhunterConditionExclusivityPeriodAsked: yup
    .boolean()
    .required('validations.boolean'),
  headhunterConditionExclusivityPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive')
    .when(
      'headhunterConditionExclusivityPeriodAsked',
      ([headhunterConditionExclusivityPeriodAsked], schema) =>
        headhunterConditionExclusivityPeriodAsked
          ? schema.required(
              'validations.required.headhunterConditionExclusivityPeriodValue'
            )
          : schema.nullable()
    ),
  headhunterConditionEstimatedRecruitmentPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive')
    .required(
      'validations.required.headhunterConditionEstimatedRecruitmentPeriod'
    ),
});

export const OFFER_CONDITIONS_PUBLISH_SCHEMA = yup.object({
  /* GENERAL CONDITIONS */
  conditionPaymentMethod: yup
    .mixed<RequestConditionPaymentMethodCode>()
    .oneOf(Object.values(RequestConditionPaymentMethodCode))
    .required('validations.required.conditionPaymentMethodValue'),

  conditionPaymentFrequency: yup
    .mixed<RequestConditionPaymentFrequencyCode>()
    .oneOf(Object.values(RequestConditionPaymentFrequencyCode))
    .when('conditionPaymentMethod', ([conditionPaymentMethod], schema) =>
      conditionPaymentMethod ===
      RequestConditionPaymentMethodCode.StartOfAgreement
        ? schema.required('validations.required.conditionPaymentFrequencyValue')
        : schema.nullable()
    ),
  conditionRemunerationType: yup
    .mixed<ConditionRemunerationType>()
    .oneOf(Object.values(ConditionRemunerationType))
    .required('validations.required.conditionRemunerationType'),
  conditionRemunerationPercentage: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when('conditionRemunerationType', ([conditionRemunerationType], schema) =>
      conditionRemunerationType ===
      ConditionRemunerationType.AnnualSalaryPercentage
        ? schema.required(
            'validations.required.conditionRemunerationPercentage'
          )
        : schema.nullable()
    ),
  conditionRemunerationAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('conditionRemunerationType', ([conditionRemunerationType], schema) =>
      conditionRemunerationType === ConditionRemunerationType.FixedAmount
        ? schema.required('validations.required.conditionRemunerationAmount')
        : schema.nullable()
    ),
  conditionRemunerationHourlyRate: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('conditionRemunerationType', ([conditionRemunerationType], schema) =>
      conditionRemunerationType === ConditionRemunerationType.HourlyRate
        ? schema.required(
            'validations.required.conditionRemunerationHourlyRate'
          )
        : schema.nullable()
    ),
  conditionRemunerationEstimatedHoursMin: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when('conditionRemunerationType', ([conditionRemunerationType], schema) =>
      conditionRemunerationType === ConditionRemunerationType.HourlyRate
        ? schema.required(
            'validations.required.conditionRemunerationEstimatedHours'
          )
        : schema.nullable()
    ),
  conditionRemunerationEstimatedHoursMax: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer('validations.integer')
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .when('conditionRemunerationType', ([conditionRemunerationType], schema) =>
      conditionRemunerationType === ConditionRemunerationType.HourlyRate
        ? schema.required(
            'validations.required.conditionRemunerationEstimatedHours'
          )
        : schema.nullable()
    )
    .when('conditionRemunerationEstimatedHoursMin', ([min], schema) =>
      min ? schema.min(min, 'validations.minHoursMustBeLessThanMax') : schema
    ),
  conditionBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesAsked'],
      ([conditionOtherBonuses, conditionOtherBonusesAsked], schema) =>
        conditionOtherBonuses?.includes(
          OfferConditionOtherBonusCode.CandidateCompanyLongivity
        ) && conditionOtherBonusesAsked
          ? schema.required('validations.required.conditionHiredDateBonus')
          : schema.nullable()
    ),
  conditionHiredDateBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesAsked'],
      ([conditionOtherBonuses, conditionOtherBonusesAsked], schema) =>
        conditionOtherBonuses?.includes(
          OfferConditionOtherBonusCode.HiringSpeed
        ) && conditionOtherBonusesAsked
          ? schema.required('validations.required.conditionHiredDateBonus')
          : schema.nullable()
    ),
  conditionMinGuaranteedPeriodOffered: yup
    .boolean()
    .required('validations.boolean'),
  conditionMinGuaranteedPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      'conditionMinGuaranteedPeriodOffered',
      ([conditionMinGuaranteedPeriodOffered], schema) =>
        conditionMinGuaranteedPeriodOffered
          ? schema.required('validations.required.conditionMinGuaranteedPeriod')
          : schema.nullable()
    ),
  conditionMinGuaranteedPeriodBonusAsked: yup
    .boolean()
    .when(
      'conditionMinGuaranteedPeriodOffered',
      ([conditionMinGuaranteedPeriodOffered], schema) =>
        conditionMinGuaranteedPeriodOffered
          ? schema.required('validations.boolean')
          : schema.nullable()
    ),
  conditionMinGuaranteedPeriodBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      [
        'conditionMinGuaranteedPeriodOffered',
        'conditionMinGuaranteedPeriodBonusAsked',
      ],
      (
        [
          conditionMinGuaranteedPeriodOffered,
          conditionMinGuaranteedPeriodBonusAsked,
        ],
        schema
      ) =>
        conditionMinGuaranteedPeriodOffered &&
        conditionMinGuaranteedPeriodBonusAsked
          ? schema.required(
              'validations.required.conditionMinGuaranteedPeriodBonus'
            )
          : schema.nullable()
    ),
  conditionProbationPeriodAsked: yup
    .boolean()
    .when(
      'conditionMinGuaranteedPeriodOffered',
      ([conditionMinGuaranteedPeriodOffered], schema) =>
        conditionMinGuaranteedPeriodOffered
          ? schema.required('validations.boolean')
          : schema.nullable()
    ),
  conditionProbationPeriod: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionMinGuaranteedPeriodOffered', 'conditionProbationPeriodAsked'],
      (
        [conditionMinGuaranteedPeriodOffered, conditionProbationPeriodAsked],
        schema
      ) =>
        conditionMinGuaranteedPeriodOffered && conditionProbationPeriodAsked
          ? schema.required('validations.required.conditionProbationPeriod')
          : schema.nullable()
    ),
  conditionOtherBonusesAsked: yup.boolean().required('validations.boolean'),
  conditionOtherBonuses: yup
    .array(
      yup
        .mixed<OfferConditionOtherBonusCode>()
        .oneOf(Object.values(OfferConditionOtherBonusCode))
    )
    .when(
      'conditionOtherBonusesAsked',
      ([conditionOtherBonusesAsked], schema) =>
        conditionOtherBonusesAsked
          ? schema
              .required('validations.required.conditionOtherBonuses')
              .test(
                'notEmptyArray',
                'validations.required.conditionOtherBonuses',
                (value) => Array.isArray(value) && value.length > 0
              )
          : schema.nullable()
    ),
  conditionOtherBonusValue: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesAsked'],
      ([conditionOtherBonuses, conditionOtherBonusesAsked], schema) =>
        conditionOtherBonuses?.includes(OfferConditionOtherBonusCode.Other) &&
        conditionOtherBonusesAsked
          ? schema.required('validations.required.conditionOtherBonusValue')
          : schema.nullable()
    ),
  conditionBonusMonth: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .nullable()
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesAsked'],
      ([conditionOtherBonuses, conditionOtherBonusesAsked], schema) =>
        conditionOtherBonuses?.includes(
          OfferConditionOtherBonusCode.CandidateCompanyLongivity
        ) && conditionOtherBonusesAsked
          ? schema.required('validations.required.conditionBonusMonthValue')
          : schema.nullable()
    ),
  conditionHiredDateBonusRequirement: yup
    .string()
    .transform((_, val) => val || null)
    .nullable()
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesAsked'],
      ([conditionOtherBonuses, conditionOtherBonusesAsked], schema) =>
        conditionOtherBonuses?.includes(
          OfferConditionOtherBonusCode.HiringSpeed
        ) && conditionOtherBonusesAsked
          ? schema.required(
              'validations.required.conditionHiredDateBonusRequirement'
            )
          : schema.nullable()
    ),
  conditionOtherBonusDescription: yup
    .string()
    .nullable()
    .max(3000, 'validations.maxLength')
    .when(
      ['conditionOtherBonuses', 'conditionOtherBonusesAsked'],
      ([conditionOtherBonuses, conditionOtherBonusesAsked], schema) =>
        conditionOtherBonuses?.includes(OfferConditionOtherBonusCode.Other) &&
        conditionOtherBonusesAsked
          ? schema.required(
              'validations.required.conditionOtherBonusDescription'
            )
          : schema.nullable()
    ),

  /* INTERNATIONAL RECRUITMENT CONDITIONS */
  internationalRecruitmentConditionMandatoryWorkPermit: yup
    .boolean()
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.InternationalRecruitment)
        ? schema.required(
            'validations.required.internationalRecruitmentConditionMandatoryWorkPermitValue'
          )
        : schema.nullable()
    ),

  /* OUTSOURCING CONDITIONS */
  outsourcingConditionMaximumDuration: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, 'validations.minNumber')
    .integer('validations.integer')
    .positive('validations.positive')
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.Outsourcing)
        ? schema.required('validations.required.durationInWeeks')
        : schema.nullable()
    ),
  outsourcingConditionMaximumBudget: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.Outsourcing)
        ? schema.required(
            'validations.required.outsourcingConditionMaximumBudgetValue'
          )
        : schema.nullable()
    ),

  /* TEMPORARY PLACEMENT CONDITIONS */
  temporaryPlacementConditionPossibleBuyback: yup
    .boolean()
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionPossibleBuybackValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionPossibleBuybackBonus: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .positive('validations.positive')
    .min(1, 'validations.minNumber')
    .max(99999999.99, 'validations.maxNumber')
    .lessThan(100000000, 'validations.maxNumber')
    .test('maxDigitsAfterDecimal', 'validations.maxDigits', (value) =>
      validateDecimal(value)
    )
    .when(
      'temporaryPlacementConditionPossibleBuyback',
      ([temporaryPlacementConditionPossibleBuyback], schema) =>
        temporaryPlacementConditionPossibleBuyback
          ? schema.required('validations.required.enterMaxAmount')
          : schema.nullable()
    ),
  temporaryPlacementConditionTravelExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionTravelExpensesResponsibilityValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionTrainingExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionTrainingExpensesResponsibilityValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionCnesstExpensesResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionCnesstExpensesResponsibilityValue'
          )
        : schema.nullable()
    ),
  temporaryPlacementConditionEmployeeHelpProgramResponsibility: yup
    .mixed<RequestConditionResponsibility>()
    .oneOf(Object.values(RequestConditionResponsibility))
    .when('requestTypes', ([requestTypes], schema) =>
      requestTypes &&
      requestTypes.length > 0 &&
      requestTypes.includes(RequestTypeCode.TemporaryHelp)
        ? schema.required(
            'validations.required.temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue'
          )
        : schema.nullable()
    ),
});

export const OFFER_PUBLISH_SCHEMA = (
  mandatory: boolean,
  isServiceRequest: boolean
) =>
  OFFER_SCHEMA.shape({
    offerCandidates: yup
      .array()
      .of(OFFER_CANDIDATE_PUBLISH_SCHEMA)
      .test(
        'candidatesPresentWhenMandatory',
        'validations.candidatesPresentWhenMandatory',
        (value) => !(value && value.length < 1 && mandatory)
      ),
  })
    .concat(OFFER_CANDIDATE_INFORMATIONS_PUBLISH_SCHEMA)
    .concat(
      isServiceRequest
        ? OFFER_SERVICE_INFORMATIONS_PUBLISH_SCHEMA
        : yup.object()
    )
    .concat(
      isServiceRequest
        ? OFFER_HEADHUNTER_CONDITIONS_PUBLISH_SCHEMA
        : yup.object()
    )
    .concat(OFFER_CONDITIONS_PUBLISH_SCHEMA);
