import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertContext } from '@application/context';
import { useRequestChatRoomMessagesMarkAsRead } from '@domain/chat';

import { toViewModel } from '../../view-models/message/mark-request-chat-room-messages-as-read.vm';

const useMarkRequestChatRoomMessagesAsRead = () => {
  const { setAlertContent } = useContext(AlertContext);
  const { data, fetching, error, markMessagesAsRead } =
    useRequestChatRoomMessagesMarkAsRead();
  const { t } = useTranslation('chatRoom', { keyPrefix: 'messages' });

  const viewModel = toViewModel({
    requestChatRoomMessagesMarkAsRead: {
      data,
      fetching,
      error,
    },
  });

  const { errorCodes } = viewModel;

  useEffect(() => {
    if (errorCodes.length === 0) {
      return;
    }

    setAlertContent({
      action: t('markMessagesAsReadError'),
      modifier: 'alert-error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodes.length]);

  return {
    viewModel,
    markMessagesAsRead,
  };
};

export default useMarkRequestChatRoomMessagesAsRead;
