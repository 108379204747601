import { ChangeEvent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';

import { ButtonLink, PageHeader, PageLoader } from '@application/components';
import { Badge } from '@application/components/badge';
import { ButtonLinkCounter } from '@application/components/buttons';
import { NavContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { getLocalizedDescription } from '@utils/i18n-utils';
import { cn } from '@utils/lib-utils';

import { ActionMenu } from './components';
import { withRecruitmentNegotiationProvider } from './context';
import RecruitmentNegotiationContext from './context/RecruitmentNegotiationContext';
import { RequestTable, RoomsTable } from './tables';

const ViewRecruitmentNegotiationPage = () => {
  const {
    isLoading,
    request,
    checkedRooms,
    setCheckedRooms,
    requestChatRoomUnreadMessagesCount,
  } = useContext(RecruitmentNegotiationContext);
  const { isBottomMenuOpen } = useContext(NavContext);
  const location = useLocation();
  const { t, i18n } = useTranslation('recruitment', {
    keyPrefix: 'negotiation',
  });
  const { t: tChat } = useTranslation('chatRoom', { keyPrefix: 'button' });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setCheckedRooms([...checkedRooms, event.target.id]);
    } else {
      setCheckedRooms([...checkedRooms.filter((c) => c !== event.target.id)]);
    }
  };

  const pathToRequestChatRoom = useMemo(
    () =>
      generatePath(PrivatePage.REQUEST_CHAT, {
        id: request?.id ?? '',
      }),
    [request?.id]
  );

  const marginBottom = useMemo(
    () => (isBottomMenuOpen ? 'mb-s-80' : 'mb-s-0'),
    [isBottomMenuOpen]
  );

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={getLocalizedDescription(
          request?.jobSpecialty?.descriptions,
          i18n.language
        )}
        subtitle={request?.operationUnit?.name}
        complement={
          <Badge
            className="px-s-18"
            value={request?.offersCount.toString()}
            placeholder="0"
          />
        }
        backTo={RootPrivatePage.RECRUITMENT_NEGOTIATIONS}
      >
        <ButtonLink
          className="mr-s-16"
          to={generatePath(PrivatePage.REQUEST_DETAILS, {
            id: request?.id || '#',
          })}
          from={location.pathname}
          outline
        >
          {t('buttons.requestDetails')}
        </ButtonLink>

        <ButtonLinkCounter
          count={requestChatRoomUnreadMessagesCount}
          iconName="ri-question-answer-line"
          to={pathToRequestChatRoom}
          size="medium"
          from={location.pathname}
        >
          {tChat('chat')}
        </ButtonLinkCounter>
      </PageHeader>

      <div className={cn('relative', marginBottom)}>
        <div className="mb-s-40">
          <h1 className="h2 mb-s-16 text-center">{t('summaryTitle')}</h1>
          <p className="pl-s-32 pr-s-32 text-16 text-neutral-secondary whitespace-pre-wrap">
            {t('summary')}
          </p>
        </div>
        <div className="overflow-x-auto">
          <div className="flex gap-s-16">
            {request && <RequestTable request={request} />}

            <RoomsTable onChange={handleChange} />
          </div>
        </div>
      </div>

      <ActionMenu />
    </>
  );
};

export const WrapperViewRecruitmentNegotiationPage =
  withRecruitmentNegotiationProvider(ViewRecruitmentNegotiationPage);
