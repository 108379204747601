import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { AccountContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import {
  OfferCandidateProgressionStatus,
  OfferProgressionStatus,
  OfferReceptionProgressionStatus,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import OfferProgressionStatusesIndicator from './OfferProgressionStatus';
import { OfferProgressionStatusColors } from './OfferProgressionStatusColors';

type ProgressionStatusesSize = 'small' | 'large';

interface ProgressionStatusesProps {
  progressionStatus: {
    receptionStatus: OfferReceptionProgressionStatus;
    candidateStatus: OfferCandidateProgressionStatus;
    contractualTermsStatus: OfferProgressionStatus;
    finalDecisionStatus: OfferProgressionStatus;
  };
  offerId: string;
  requestId?: string;
  recruitmentNegotiationId?: string;
  negotiationRoomId?: string;
  size?: ProgressionStatusesSize;
}

type ProgressionStatus =
  | OfferProgressionStatus
  | OfferReceptionProgressionStatus
  | OfferCandidateProgressionStatus;

const OfferProgressionStatusesIndicators = ({
  progressionStatus,
  offerId,
  requestId,
  negotiationRoomId,
  recruitmentNegotiationId,
  size = 'small',
}: ProgressionStatusesProps) => {
  const { t } = useTranslation('offers');
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const isEnterpriseAccount = account?.type === 'ENTERPRISE';
  const statusType = {
    reception: 'reception',
    candidate: 'candidate',
    terms: 'terms',
    final: 'final',
  };

  const location = useLocation();

  const pathGenerators = useMemo(
    () => ({
      offerDetails: () =>
        requestId &&
        generatePath(PrivatePage.OFFER_DETAILS, { id: requestId, offerId }),

      offerCandidates: () =>
        requestId &&
        generatePath(PrivatePage.OFFER_CANDIDATES, { id: requestId, offerId }),

      recruitmentNegotiationDetails: () =>
        recruitmentNegotiationId &&
        generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
          id: recruitmentNegotiationId,
        }),

      recruitmentNegotiationRoomDetails: () =>
        negotiationRoomId &&
        generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_ROOM_DETAILS, {
          id: negotiationRoomId,
        }),
    }),
    [requestId, offerId, recruitmentNegotiationId, negotiationRoomId]
  );

  const redirectionConfig: any = {
    reception: {
      [OfferReceptionProgressionStatus.Seen]: pathGenerators.offerDetails(),
      [OfferReceptionProgressionStatus.NotSeen]: pathGenerators.offerDetails(),
    },
    candidate: {
      [OfferCandidateProgressionStatus.OneCandidateHired]:
        pathGenerators.offerCandidates(),
      [OfferCandidateProgressionStatus.AllCandidatesRejected]:
        pathGenerators.offerCandidates(),
      [OfferCandidateProgressionStatus.AwaitingForDecision]:
        pathGenerators.offerCandidates(),
      [OfferCandidateProgressionStatus.OneCandidateNotSeen]:
        pathGenerators.offerCandidates(),
    },
    terms: {
      [OfferProgressionStatus.Awaiting]: pathGenerators.offerDetails(),
      [OfferProgressionStatus.InProgress]: isEnterpriseAccount
        ? pathGenerators.recruitmentNegotiationDetails()
        : pathGenerators.recruitmentNegotiationRoomDetails(),
      [OfferProgressionStatus.Accepted]: pathGenerators.offerDetails(),
    },
    final: {
      [OfferProgressionStatus.Awaiting]: pathGenerators.offerDetails(),
      [OfferProgressionStatus.Accepted]: pathGenerators.offerDetails(),
    },
  };

  const getRedirectConfig = (type: string, status: ProgressionStatus) =>
    redirectionConfig[type]?.[status] || null;

  const handleClick = (type: string, status: ProgressionStatus) => {
    const path = getRedirectConfig(type, status);
    if (path) {
      navigate(path, { state: { from: location.pathname } });
    }
  };

  const getTooltipText = (type: string, status: ProgressionStatus): string => {
    const accountType = isEnterpriseAccount ? 'enterprise' : 'agency';
    const statusKey = status.toLocaleLowerCase();
    return t(`offerProgressionStatuses.${accountType}.${type}.${statusKey}`);
  };

  const mapOfferReceptionProgressionToStatusType = (
    status: OfferReceptionProgressionStatus
  ): OfferProgressionStatusColors => {
    const statusMap = {
      [OfferReceptionProgressionStatus.Seen]:
        OfferProgressionStatusColors.Green,
      [OfferReceptionProgressionStatus.NotSeen]:
        OfferProgressionStatusColors.LightGray,
    };
    return statusMap[status] || OfferProgressionStatusColors.Gray;
  };

  const mapOfferCandidateProgressionToStatusType = (
    status: OfferCandidateProgressionStatus
  ): OfferProgressionStatusColors => {
    const statusMap = {
      [OfferCandidateProgressionStatus.OneCandidateHired]:
        OfferProgressionStatusColors.Green,
      [OfferCandidateProgressionStatus.AllCandidatesRejected]:
        OfferProgressionStatusColors.Red,
      [OfferCandidateProgressionStatus.AwaitingForDecision]:
        OfferProgressionStatusColors.Yellow,
      [OfferCandidateProgressionStatus.OneCandidateNotSeen]:
        OfferProgressionStatusColors.LightGray,
      [OfferCandidateProgressionStatus.NoCandidate]:
        OfferProgressionStatusColors.Gray,
    };
    return statusMap[status] || OfferProgressionStatusColors.Gray;
  };

  const mapOfferProgressionToStatusType = (
    status: OfferProgressionStatus
  ): OfferProgressionStatusColors => {
    const statusMap = {
      [OfferProgressionStatus.Accepted]: OfferProgressionStatusColors.Green,
      [OfferProgressionStatus.Rejected]: OfferProgressionStatusColors.Red,
      [OfferProgressionStatus.InProgress]: OfferProgressionStatusColors.Yellow,
      [OfferProgressionStatus.Awaiting]: OfferProgressionStatusColors.LightGray,
    };
    return statusMap[status] || OfferProgressionStatusColors.Gray;
  };

  const statusItems: {
    type: string;
    color: OfferProgressionStatusColors;
    status: ProgressionStatus;
    label: string;
  }[] = [
    {
      type: statusType.reception,
      status: progressionStatus.receptionStatus,
      color: mapOfferReceptionProgressionToStatusType(
        progressionStatus.receptionStatus
      ),
      label: 'reception',
    },
    {
      type: statusType.candidate,
      status: progressionStatus.candidateStatus,
      color: mapOfferCandidateProgressionToStatusType(
        progressionStatus.candidateStatus
      ),
      label: 'candidates',
    },
    {
      type: statusType.terms,
      status: progressionStatus.contractualTermsStatus,
      color: mapOfferProgressionToStatusType(
        progressionStatus.contractualTermsStatus
      ),
      label: 'terms',
    },
    {
      type: statusType.final,
      status: progressionStatus.finalDecisionStatus,
      color: mapOfferProgressionToStatusType(
        progressionStatus.finalDecisionStatus
      ),
      label: 'final',
    },
  ];

  return (
    <>
      {size === 'large' && (
        <div className="text-16 font-semibold mb-s-8">
          {t('offerProgressionStatuses.title')}
        </div>
      )}

      <ul
        className={cn(
          'flex items-center list-none p-0 m-0',
          size === 'large' ? 'gap-s-16' : 'gap-s-4'
        )}
      >
        {statusItems.map(({ type, status, color, label }) => {
          const hasRedirection = !!getRedirectConfig(type, status);
          const tooltipText = getTooltipText(type, status);
          const translatedLabel = t(`offerProgressionStatuses.status.${label}`);

          return (
            <li key={type}>
              <OfferProgressionStatusesIndicator
                color={color}
                tooltipText={tooltipText}
                size={size}
                label={size === 'large' ? translatedLabel : undefined}
                hasRedirection={hasRedirection}
                onClick={
                  hasRedirection ? () => handleClick(type, status) : undefined
                }
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default OfferProgressionStatusesIndicators;
