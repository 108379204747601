import { ReactElement, ReactNode } from 'react';

import { isDefined } from '@utils/type-utils';

import { Switch } from './SwitchMatch';

type PlaceholderSwitcherProps = {
  value: any;
  children: ReactNode;
  placeholder?: ReactElement;
};

const PlaceholderSwitcher = ({
  value,
  children,
  placeholder,
}: PlaceholderSwitcherProps) => {
  const displayChildren = isDefined(value);

  return (
    <Switch fallback={<span>{placeholder || '-'}</span>}>
      <Switch.Match when={displayChildren}>{children}</Switch.Match>
    </Switch>
  );
};

export default PlaceholderSwitcher;
