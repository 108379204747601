import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type InternationalRecruitmentConditionsProps = {
  data: Request | undefined;
};

const InternationalRecruitmentConditions = ({
  data,
}: InternationalRecruitmentConditionsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <SectionGridRow isTopRow>
      <span className="mr-s-8">{t('labels.validWorkPermit')}</span>
      <PlaceholderSwitcher
        value={
          data?.internationalRecruitmentContractualConditions
            ?.mandatoryWorkPermitValue
        }
      >
        <span className="text-18 font-semibold text-primary">
          {tGlobal(
            `boolean.${!!data?.internationalRecruitmentContractualConditions
              ?.mandatoryWorkPermitValue}`
          )}
        </span>
      </PlaceholderSwitcher>
    </SectionGridRow>
  );
};

export default InternationalRecruitmentConditions;
