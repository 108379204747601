import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

const RecruitmentOpportunityListFragment = gql`
  ${DescriptionsFragment}

  fragment RecruitmentOpportunityListFragment on RecruitmentOpportunity {
    id
    jobSpecialtyCode
    operationTerritoryCodes
    jobExperienceLevelMin
    jobExperienceLevelMax
    jobFullTimeAvailability
    jobPartTimeAvailability
    receivingOfferDeadline
    types
    createdAt
    updatedAt
    jobModeCode
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    accountSector {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    unreadMessagesCount
  }
`;

export default RecruitmentOpportunityListFragment;
