import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ConfirmationModal, Stack, Stepper } from '@application/components';
import { Form } from '@application/components/form';
import { ModalContext } from '@application/context';
import { useWizard } from '@application/hooks';

import { CREATE_CANDIDATE_REQUEST_STEPS, DEFAULT_VALUES } from './constants';
import { useCreateCandidateRequest } from './hooks';
import { normalizeCandidateRequestInputData } from './normalizers';
import {
  CANDIDATE_REQUEST_PUBLISH_SCHEMA,
  CANDIDATE_REQUEST_SCHEMA,
  CandidateRequestFormFields,
} from './schema';
import { ConditionsPanel, DescriptionPanel } from './steps';

const CreateCandidateRequestPage = () => {
  const [currentSchema, setCurrentSchema] = useState<yup.ObjectSchema<any>>(
    CANDIDATE_REQUEST_SCHEMA
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [publishing, setPublishing] = useState<boolean>(false);

  const { t } = useTranslation('candidates');

  const {
    createCandidateRequest,
    viewModel: { isLoading },
  } = useCreateCandidateRequest();

  const { setModal } = useContext(ModalContext);

  const methods = useForm<CandidateRequestFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(currentSchema),
  });

  const {
    control,
    register,
    formState,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    trigger,
    watch,
  } = methods;

  const { activeStep, nextStep, completedSteps, setActive } = useWizard({
    steps: CREATE_CANDIDATE_REQUEST_STEPS,
  });

  const onSubmit: SubmitHandler<CandidateRequestFormFields> = useCallback(
    async (data) => {
      await createCandidateRequest({
        input: normalizeCandidateRequestInputData(data) as any,
      });
    },
    [createCandidateRequest]
  );

  const handleOnSave = () => {
    setValue('publishNow', false);
    setCurrentSchema(CANDIDATE_REQUEST_SCHEMA);
    setSaving(true);
  };

  const handleOnPublish = () => {
    setValue('publishNow', true);
    setCurrentSchema(CANDIDATE_REQUEST_PUBLISH_SCHEMA);
    setSaving(false);
    setPublishing(true);
  };

  useEffect(() => {
    if (!publishing || Object.keys(formState.errors).length === 0) {
      return;
    }

    setModal({
      title: t('modal.titles.fillOutEmptyRequiredField', {
        count: Object.keys(formState.errors).length,
      }),
      maxWidth: '2xl',
      content: (
        <ConfirmationModal
          content={
            <Stack>
              <p>
                <Trans
                  i18nKey="modal.contents.requiredFieldError"
                  t={t}
                  values={{
                    count: Object.keys(formState.errors).length,
                    step: t(CREATE_CANDIDATE_REQUEST_STEPS[0].title),
                  }}
                />
              </p>
              <p>
                {t('modal.contents.confirmRedirectStep', {
                  count: Object.keys(formState.errors).length,
                })}
              </p>
            </Stack>
          }
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            /**
             * FIXME: This is a only temporary. The redirection is made explicitly
             * to the first step (0) of the form in case of error since all required fields are present in this step.
             * It should be handle dynamically by redirecting to the first step containing errors on required fields.
             */
            setActive(0);
          }}
        />
      ),
    });

    setPublishing(false);
    // We want to execute it only on saving AND presence of errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors, saving]);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="max-w-[66rem] w-full">
        <Stepper
          steps={CREATE_CANDIDATE_REQUEST_STEPS}
          activeStep={activeStep}
          completedSteps={completedSteps}
          setActive={setActive}
          t={t}
        />
        <FormProvider {...methods}>
          <Form className="md:px-s-48" onSubmit={handleSubmit(onSubmit)}>
            {activeStep === 0 && (
              <DescriptionPanel
                clearErrors={clearErrors}
                control={control}
                errors={formState.errors}
                nextStep={nextStep}
                register={register}
                setError={setError}
                setValue={setValue}
                trigger={trigger}
                watch={watch}
              />
            )}

            {activeStep === 1 && (
              <ConditionsPanel
                control={control}
                errors={formState.errors}
                onPublish={handleOnPublish}
                onSave={handleOnSave}
                register={register}
                setValue={setValue}
                trigger={trigger}
                watch={watch}
                saveLoading={isLoading && saving}
                publishLoading={isLoading && !saving}
              />
            )}
          </Form>
        </FormProvider>
      </div>
    </div>
  );
};

export default CreateCandidateRequestPage;
