import { useCallback, useEffect, useMemo, useState } from 'react';

import { RootPrivatePage } from '@application/enums/pagesUrl';
import {
  CandidateRequestChatRoom,
  CandidateRequestChatRoomMessage,
} from '@domain/graphql.types';

import { ChatRoom } from './components';
import { ChatContent } from './components/ChatRoom';
import {
  useCandidateRequestChatRoomMessagesList,
  useCandidateRequestChatRoomsList,
  useCreateCandidateRequestChatRoomMessage,
  useMarkCandidateRequestChatRoomMessagesAsRead,
} from './hooks';

type CandidateOpportunityChatContentProps = {
  candidateOpportunityId: string;
  accountId: string;
  title: string;
};
const CandidateOpportunityChatContent = ({
  candidateOpportunityId,
  accountId,
  title,
}: CandidateOpportunityChatContentProps) => {
  const [selectedChatRoom, setSelectedChatRoom] =
    useState<CandidateRequestChatRoom>();
  const [messages, setMessages] = useState<CandidateRequestChatRoomMessage[]>(
    []
  );
  const [agencyName, setAgencyName] = useState('');

  const { createCandidateRequestChatRoomMessage } =
    useCreateCandidateRequestChatRoomMessage();

  const { markMessagesAsRead } =
    useMarkCandidateRequestChatRoomMessagesAsRead();

  const { candidateRequestChatRooms } = useCandidateRequestChatRoomsList({
    candidateRequestId: candidateOpportunityId,
    enterpriseAccountId: accountId,
  });

  const {
    candidateRequestChatRoomMessages,
    isLoading: candidateRequestChatRoomMessagesIsLoading,
    refetchCandidateRequestChatRoomMessages,
  } = useCandidateRequestChatRoomMessagesList({
    candidateRequestChatRoomId: selectedChatRoom?.id ?? '',
  });

  const chatContent: ChatContent = useMemo(
    () => ({
      messageList: messages.map((message) => {
        const readAtDate = message.readAt ? new Date(message.readAt) : null;
        const now = new Date();

        return {
          position:
            message.accountId === accountId
              ? ('right' as const)
              : ('left' as const),
          date: message.sentAt,
          viewed: !!(readAtDate && readAtDate < now),
          content: message.message,
        };
      }),
    }),
    [accountId, messages]
  );

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createCandidateRequestChatRoomMessage({
        input: {
          candidateRequestChatRoomId: selectedChatRoom?.id ?? '',
          message,
        },
      });

      if (candidateRequestChatRoomMessages.length < 1) {
        refetchCandidateRequestChatRoomMessages();
      }
    },
    [
      createCandidateRequestChatRoomMessage,
      selectedChatRoom?.id,
      candidateRequestChatRoomMessages.length,
      refetchCandidateRequestChatRoomMessages,
    ]
  );

  const handleMarkMessagesAsRead = useCallback(async () => {
    await markMessagesAsRead({
      input: { candidateRequestChatRoomId: selectedChatRoom?.id ?? '' },
    });
    refetchCandidateRequestChatRoomMessages();
  }, [
    markMessagesAsRead,
    refetchCandidateRequestChatRoomMessages,
    selectedChatRoom?.id,
  ]);

  useEffect(() => {
    if (candidateRequestChatRooms?.length) {
      setSelectedChatRoom(candidateRequestChatRooms[0]);
    }
  }, [candidateRequestChatRooms]);

  useEffect(() => {
    if (
      selectedChatRoom &&
      !selectedChatRoom?.isAgencyAnonym &&
      selectedChatRoom.agencyName
    ) {
      setAgencyName(selectedChatRoom.agencyName);
    }
  }, [
    selectedChatRoom,
    selectedChatRoom?.isAgencyAnonym,
    selectedChatRoom?.agencyName,
  ]);

  useEffect(() => {
    if (!candidateRequestChatRoomMessages) {
      setMessages([]);
      return;
    }

    const hasSameContent =
      messages.length === candidateRequestChatRoomMessages.length &&
      messages.every(
        (message, idx) =>
          message.id === candidateRequestChatRoomMessages[idx].id &&
          message.readAt === candidateRequestChatRoomMessages[idx].readAt
      );

    if (!hasSameContent) {
      setMessages(candidateRequestChatRoomMessages);
    }
  }, [candidateRequestChatRoomMessages, messages]);

  return (
    <ChatRoom
      isLoading={candidateRequestChatRoomMessagesIsLoading}
      title={title}
      subtitle={agencyName}
      chatContent={chatContent}
      backTo={RootPrivatePage.CANDIDATE_OPPORTUNITIES}
      onSendMessage={handleSendMessage}
      refresh={refetchCandidateRequestChatRoomMessages}
      markMessagesAsRead={handleMarkMessagesAsRead}
    />
  );
};

export default CandidateOpportunityChatContent;
