import { useMutation } from 'urql';

import {
  Mutation,
  MutationOfferCandidateUpdateArgs,
} from '@domain/graphql.types';
import { MutationOfferCandidateUpdateSeenAt } from '@infrastructure/graphql/offer';

const useOfferCandidateSeenAtUpdate = () => {
  const [{ data, fetching, error }, updateOfferCandidateSeenAt] = useMutation<
    Pick<Mutation, 'offerCandidateUpdateSeenAt'>,
    MutationOfferCandidateUpdateArgs
  >(MutationOfferCandidateUpdateSeenAt);

  return {
    updateOfferCandidateSeenAt,
    data,
    fetching,
    error,
  };
};

export default useOfferCandidateSeenAtUpdate;
