import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import { RecruitmentOpportunity } from '@domain/graphql.types';

type ReplacementGuaranteeProps = {
  data: RecruitmentOpportunity | undefined;
};

const ReplacementGuarantee = ({ data }: ReplacementGuaranteeProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow isTopRow>
        <span className="mr-s-8">{t('labels.replacementGuaranteeAsked')}</span>
        <PlaceholderSwitcher
          value={data?.generalContractualConditions.minGuaranteedPeriodAsked}
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${
                data?.generalContractualConditions.minGuaranteedPeriodAsked
              }`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>

      {data?.generalContractualConditions?.minGuaranteedPeriodAsked && (
        <SectionGridRow>
          <span className="mr-s-8">
            {t('labels.replacementGuaranteeMinAskedPeriod1')}
            <span className="text-18 font-semibold text-primary">
              {data?.generalContractualConditions.minGuaranteedPeriodValue}
              &nbsp;{t('suffix.months')}
            </span>
            {t('labels.replacementGuaranteeMinAskedPeriod2')}
          </span>
        </SectionGridRow>
      )}

      {data?.generalContractualConditions.minGuaranteedPeriodBonusOffered && (
        <SectionGridRow>
          <span className="mr-s-8">
            {t('labels.replacementGuaranteeProbationAmountOffered1')}
            <span className="text-18 font-semibold text-primary">
              {t('suffix.currency', {
                value:
                  data?.generalContractualConditions
                    .minGuaranteedPeriodBonusValue,
              })}
            </span>
            {t('labels.replacementGuaranteeProbationAmountOffered2')}
          </span>
        </SectionGridRow>
      )}

      {data?.generalContractualConditions.probationPeriodOffered && (
        <SectionGridRow>
          <span className="mr-s-8">
            {t('labels.replacementGuaranteeProbationDurationAsked')}
          </span>
          <span className="text-18 font-semibold text-primary">
            {data?.generalContractualConditions.probationPeriodValue}&nbsp;
            {t('suffix.weeks')}
          </span>
        </SectionGridRow>
      )}
    </>
  );
};

export default ReplacementGuarantee;
