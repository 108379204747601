import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import {
  Offer,
  OfferGeneralConditions,
  OfferStatusTypeCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import { TD_CLASS_NAME, TH_CLASS_NAME } from './constants';

type GeneralConditionsProps = {
  offers: readonly Offer[];
  checkedOffers: string[];
};

const conditionsKey: (keyof OfferGeneralConditions)[] = [
  'paymentMethod',
  'deposit',
  'maxRecruitmentPeriod',
  'minGuaranteedPeriod',
];

const GeneralConditions = ({
  offers,
  checkedOffers,
}: GeneralConditionsProps) => {
  const { t: tGlobal } = useTranslation('translation');
  const { t } = useTranslation('offers', {
    keyPrefix: 'comparison',
  });

  const getOfferValue = useCallback(
    (condition: keyof OfferGeneralConditions, offer: Offer) => {
      const cellValue = offer.generalContractualConditions[condition];

      switch (condition) {
        case 'maxRecruitmentPeriod':
        case 'minGuaranteedPeriod':
          return (
            <span>
              {t(`columns.${condition as string}`, {
                count: cellValue as number,
              })}
            </span>
          );
        case 'paymentMethod':
          return (
            <span>
              {tGlobal(
                `enum.requestConditionPaymentMethodCode.short.${cellValue?.toString().toLowerCase()}`
              )}
            </span>
          );
        case 'deposit':
          return (
            <span>{tGlobal('suffix.currencyValue', { value: cellValue })}</span>
          );
        default:
          return <span>-</span>;
      }
    },
    [t, tGlobal]
  );

  return (
    <>
      {conditionsKey.map((condition: keyof OfferGeneralConditions) => (
        <tr key={condition}>
          <th className={TH_CLASS_NAME}>
            <TooltipEllipsis toolTip={t(`generalConditions.${condition}`)}>
              <div>{t(`generalConditions.${condition}`)}</div>
            </TooltipEllipsis>
          </th>

          {offers.map((offer) => (
            <td
              key={offer.id}
              className={cn(TD_CLASS_NAME, {
                'border-x-2 !border-x-main': checkedOffers.find(
                  (offerId) => offer.id === offerId
                ),
                'opacity-65': offer.status === OfferStatusTypeCode.Rejected,
              })}
            >
              {offer.generalContractualConditions[condition] ? (
                getOfferValue(condition, offer)
              ) : (
                <span>-</span>
              )}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default GeneralConditions;
