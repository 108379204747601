import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionGrid } from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import { Offer, RequestTypeCode } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

import GeneralConditions from './GeneralConditions';
import HeadhunterConditions from './HeadhunterConditions';
import InternationalRecruitmentConditions from './InternationalRecruitmentConditions';
import OtherBonusesOfferedConditions from './OtherBonusesOfferedConditions';
import OutsourcingConditions from './OutsourcingConditions';
import ReplacementGuaranteeConditions from './ReplacementGuaranteeConditions';
import TemporaryPlacementConditions from './TemporaryPlacementConditions';

type ConditionsSummaryProps = {
  offer: Offer | undefined;
};

const ConditionsSummary = ({ offer }: ConditionsSummaryProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });

  const conditions = useMemo(
    () => [
      {
        title: 'conditions',
        children: <GeneralConditions data={offer} />,
        className: 'mt-s-16 rounded-t-md',
        isVisible: true,
      },
      {
        title: 'replacementGuaranteeConditions',
        children: <ReplacementGuaranteeConditions data={offer} />,
        isVisible: true,
      },
      {
        title: 'otherBonusesOfferedConditions',
        children: <OtherBonusesOfferedConditions data={offer} />,
        isVisible: true,
      },
      {
        title: 'headHunter',
        children: <HeadhunterConditions data={offer} />,
        isVisible:
          offer?.requestTypes?.includes(RequestTypeCode.Headhunter) &&
          !offer.recruitmentOpportunity?.generalContractualConditions
            ?.candidateInHandMandatoryValue,
      },
      {
        title: 'internationalRecruitment',
        children: <InternationalRecruitmentConditions data={offer} />,
        isVisible: offer?.requestTypes?.includes(
          RequestTypeCode.InternationalRecruitment
        ),
      },
      {
        title: 'outsourcing',
        children: <OutsourcingConditions data={offer} />,
        isVisible: offer?.requestTypes?.includes(RequestTypeCode.Outsourcing),
      },
      {
        title: 'temporaryPlacement',
        children: <TemporaryPlacementConditions data={offer} />,
        isVisible: offer?.requestTypes?.includes(RequestTypeCode.TemporaryHelp),
      },
    ],
    [offer]
  );

  const filteredConditions = conditions.filter((c) => c.isVisible);

  return filteredConditions.map(
    (c, index) =>
      c.isVisible && (
        <Fragment key={c.title}>
          <SummaryHeader
            className={cn('rounded-none', c.className)}
            headerTitle={t(`subtitles.${c.title}`)}
          />

          <SectionGrid
            readOnly
            hideTitle
            className="my-s-0"
            gridColsClassName={cn(
              {
                'rounded-none': !(index === filteredConditions.length - 1),
                'border-b-0': !(index === filteredConditions.length - 1),
              },
              'grid-cols-[1fr_10rem] border-t-0'
            )}
          >
            {c.children}
          </SectionGrid>
        </Fragment>
      )
  );
};

export default ConditionsSummary;
