import { useTranslation } from 'react-i18next';

import { SectionGridRow, Stack, Tooltip } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Offer, Request } from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';

type HeadhunterProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const HeadhunterConditions = ({ data, request }: HeadhunterProps) => {
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tRequest } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const conditions = data?.headhunterContractualConditions;
  const generalConditions = data?.generalContractualConditions;
  const requestConditions = request?.headhunterContractualConditions;

  const wrapperClassName = 'flex flex-wrap items-center gap-s-8';
  const valueTextClassName = 'text-18 font-semibold text-primary';

  return (
    <>
      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <PlaceholderSwitcher
            value={
              request?.generalContractualConditions?.maxRecruitmentPeriodValue
            }
          >
            <span className={cn(valueTextClassName, 'text-16')}>
              {t('suffix.week', {
                count: Number(
                  request?.generalContractualConditions
                    ?.maxRecruitmentPeriodValue
                ),
              })}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <span className={wrapperClassName}>
          <div className="items-center">
            <span className="mr-s-8">
              {t(`labels.headhuntingEstimatedRecruitmentPeriod`)}
            </span>

            <PlaceholderSwitcher
              value={conditions?.headhuntingEstimatedRecruitmentPeriod}
            >
              <span className={valueTextClassName}>
                {t('suffix.week', {
                  count: Number(
                    conditions?.headhuntingEstimatedRecruitmentPeriod
                  ),
                })}
              </span>
            </PlaceholderSwitcher>
          </div>
        </span>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <PlaceholderSwitcher
            placeholder={
              <span className={valueTextClassName}>
                {tGlobal('labels.none')}
              </span>
            }
            value={requestConditions?.headhuntingExclusivityPeriodValue}
          >
            <span className={cn(valueTextClassName, 'text-16')}>
              {t('suffix.week', {
                count: Number(
                  requestConditions?.headhuntingExclusivityPeriodValue
                ),
              })}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <span className={wrapperClassName}>
          <span>{t(`labels.exclusivityPeriod`)}</span>

          <PlaceholderSwitcher
            placeholder={
              <span className={valueTextClassName}>
                {tGlobal('labels.none')}
              </span>
            }
            value={conditions?.headhuntingExclusivityPeriod}
          >
            <span className={valueTextClassName}>
              {t('suffix.week', {
                count: Number(conditions?.headhuntingExclusivityPeriod),
              })}
            </span>
          </PlaceholderSwitcher>
        </span>
      </SectionGridRow>

      <SectionGridRow className="border-r-0" isOffer rightChildren={<span />}>
        <Stack className="w-full flex flex-col h-full">
          <div>
            <span className="mr-s-8">{t('labels.depositRequired')}</span>
            <span className="text-16 font-semibold text-primary">
              {generalConditions?.depositRequired
                ? tGlobal('boolean.true')
                : tGlobal('boolean.false')}
            </span>
          </div>
          {generalConditions?.depositRequired && (
            <div>
              <span className="mr-s-8">{t('labels.deposit')}</span>
              <span className="text-16 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: generalConditions?.deposit,
                })}
              </span>
            </div>
          )}
        </Stack>
      </SectionGridRow>
      <SectionGridRow className="border-r-0" isOffer rightChildren={<span />}>
        <Stack space={24}>
          <div>
            <span className="text-18 font-bold">
              {tRequest('labels.cancellationFee.title')}
            </span>
            <Tooltip
              message={tRequest(
                'labels.cancellationFee.cancellationFeeTooltipsEnterprise'
              )}
            >
              <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
            </Tooltip>
          </div>
          <div className="flex items-center">
            <span className="mr-s-8">{t('labels.cancellationFee.amount')}</span>
            <span className="text-16 font-semibold text-primary">
              {generalConditions?.cancellationFeeAmount
                ? tGlobal('suffix.currencyValue', {
                    value: generalConditions.cancellationFeeAmount,
                  })
                : tGlobal('labels.none_m')}
            </span>
          </div>

          {generalConditions?.hasCancellationFee &&
            generalConditions?.cancellationFeeTerms && (
              <div className="flex items-center">
                <span className="mr-s-8">
                  {t('labels.cancellationFee.terms')}
                </span>
                <span className="text-16 font-semibold text-primary">
                  {generalConditions.cancellationFeeTerms}
                </span>
              </div>
            )}
        </Stack>
      </SectionGridRow>
    </>
  );
};

export default HeadhunterConditions;
