import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  Stack,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import i18n from '@application/providers/LanguageProvider/i18n';
import { RecruitmentOpportunity } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../types';
import CancellationFeeFields from './main-fields/CancellationFeeFields';

type HeadHunterFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const HeadHunterFields = ({
  recruitmentOpportunity,
}: HeadHunterFieldsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    formState: { errors },
    setValue,
    watch,
    trigger,
    control,
    register,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const headhunterConditionExclusivityPeriodAsked = watch(
    'headhunterConditionExclusivityPeriodAsked'
  );
  const conditionDepositRequired = watch('conditionDepositRequired');

  return (
    <SectionGrid
      title={t('subtitles.forHeadHunting')}
      className="mt-s-40"
      isOffer
    >
      <SectionGridRow
        isTopRow
        isOffer
        rightChildren={
          recruitmentOpportunity?.generalContractualConditions
            ?.maxRecruitmentPeriodValue ? (
            <span className="text-16 font-semibold text-primary">
              {t('suffix.week', {
                count:
                  recruitmentOpportunity.generalContractualConditions
                    .maxRecruitmentPeriodValue,
              })}
            </span>
          ) : (
            <span>-</span>
          )
        }
      >
        <Stack>
          <FormInputWithSuffix
            label={t('labels.headhunterConditionEstimatedRecruitmentPeriod')}
            suffix={t('suffix.weeks')}
            type="number"
            mediumSuffix
            noMargin
            minValue={1}
            invalid={!!errors.headhunterConditionEstimatedRecruitmentPeriod}
            helperText={
              errors.headhunterConditionEstimatedRecruitmentPeriod?.message &&
              tGlobal(
                errors.headhunterConditionEstimatedRecruitmentPeriod?.message,
                {
                  min: '1',
                }
              )
            }
            {...register('headhunterConditionEstimatedRecruitmentPeriod')}
          />
        </Stack>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          recruitmentOpportunity?.headhunterContractualConditions
            ?.headhuntingExclusivityPeriodValue ? (
            <span className="text-16 font-semibold text-primary">
              {t('suffix.week', {
                count:
                  recruitmentOpportunity.headhunterContractualConditions
                    .headhuntingExclusivityPeriodValue,
              })}
            </span>
          ) : (
            <span>-</span>
          )
        }
      >
        <Stack className="w-full" space={16}>
          <Controller
            name="headhunterConditionExclusivityPeriodAsked"
            control={control}
            render={({ field: { name }, fieldState: { error } }) => (
              <RadioGroup
                name={name}
                data={BOOLEAN_VALUES}
                legendSize="medium"
                legend={
                  <Trans
                    i18nKey="labels.headhunterConditionExclusivityPeriodAsked"
                    t={t}
                  />
                }
                language={extractLanguage(i18n.language)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue(name, event.target.value === 'true');
                  trigger(name);
                }}
                alignRow
                value={headhunterConditionExclusivityPeriodAsked ?? undefined}
                invalid={!!error}
                helperText={error?.message && tGlobal(error?.message)}
              />
            )}
          />
          {headhunterConditionExclusivityPeriodAsked && (
            <FormInputWithSuffix
              label={t('labels.exclusivityPeriod')}
              suffix={t('suffix.weeks')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              invalid={!!errors.headhunterConditionExclusivityPeriod}
              helperText={
                errors.headhunterConditionExclusivityPeriod?.message &&
                tGlobal(errors.headhunterConditionExclusivityPeriod?.message, {
                  min: '1',
                })
              }
              {...register('headhunterConditionExclusivityPeriod')}
            />
          )}
        </Stack>
      </SectionGridRow>
      <SectionGridRow className="border-r-0" isOffer rightChildren={<span />}>
        <Stack className="w-full" space={16}>
          <Controller
            name="conditionDepositRequired"
            control={control}
            render={({ field: { name }, fieldState: { error } }) => (
              <RadioGroup
                name={name}
                data={BOOLEAN_VALUES}
                legendSize="medium"
                legend={
                  <Trans i18nKey="labels.conditionDepositRequired" t={t} />
                }
                language={extractLanguage(i18n.language)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue(name, event.target.value === 'true');
                  trigger(name);
                }}
                alignRow
                value={conditionDepositRequired ?? undefined}
                invalid={!!error}
                helperText={error?.message && tGlobal(error?.message)}
              />
            )}
          />

          {conditionDepositRequired && (
            <FormInputWithSuffix
              className="mt-s-16"
              label={t('labels.conditionDeposit')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.conditionDeposit}
              helperText={
                errors.conditionDeposit?.message &&
                tGlobal(errors.conditionDeposit?.message, {
                  max: '99,999,999.99',
                  min: '1',
                })
              }
              {...register('conditionDeposit')}
            />
          )}
        </Stack>
      </SectionGridRow>
      <CancellationFeeFields />
    </SectionGrid>
  );
};

export default HeadHunterFields;
