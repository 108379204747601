import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import CandidateRequestOutsourcingConditionsFragment from '../../request/fragment/candidate.request.outsourcing.conditions.fragment';
import CandidateRequestRecruitmentConditionsFragment from '../../request/fragment/candidate.request.recruitment.conditions.fragment';
import CandidateRequestTemporaryPlacementConditionsFragment from '../../request/fragment/candidate.request.temporary.placement.conditions.fragment';

const CandidateOpportunityFragment = gql`
  ${CandidateRequestRecruitmentConditionsFragment}
  ${CandidateRequestOutsourcingConditionsFragment}
  ${CandidateRequestTemporaryPlacementConditionsFragment}
  ${DescriptionsFragment}

  fragment CandidateOpportunityFragment on CandidateOpportunity {
    id
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    experience
    canWorkInCanadaOrQuebec
    fullTimeAvailability
    partTimeAvailability
    desiredStartDate
    receivingOfferDeadline
    metAt
    jobType
    jobDuration {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobDurationInMonths
    seasons
    hoursPerWeek
    type
    schedules
    jobModeCode
    jobMode {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    remoteDaysAllowed
    paymentFrequencyCode
    annualSalaryMin
    annualSalaryMax
    hourlyRateMin
    hourlyRateMax
    hourlyBonus
    remunerationAmount
    otherPaymentTypeCode
    otherPaymentType {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    otherPaymentAmount
    softSkills
    additionalNotes
    resumeUrl
    createdAt
    updatedAt
    specialty
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
      sector {
        code
        descriptions {
          ...DescriptionsFragment
        }
        industry {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
      }
    }
    recruitmentConditions {
      ...CandidateRequestRecruitmentConditionsFragment
    }
    outsourcingConditions {
      ...CandidateRequestOutsourcingConditionsFragment
    }
    temporaryPlacementConditions {
      ...CandidateRequestTemporaryPlacementConditionsFragment
    }
    unreadMessagesCount
  }
`;

export default CandidateOpportunityFragment;
