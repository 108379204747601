import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';

import {
  BadgeGroup,
  PlaceholderSwitcher,
  SectionGrid,
} from '@application/components';
import { SummaryHeader } from '@application/components/summary';
import {
  Offer,
  OfferCandidatesEvaluationProcessCode,
  OfferMandateFollowUpCode,
  OfferSimilarMandatesExperiencesCode,
  OfferTalentSourceCode,
} from '@domain/graphql.types';
import { cn } from '@utils/lib-utils';
import { isDefined } from '@utils/type-utils';

type HeadhunterExpertiseProps = {
  offer: Offer | undefined;
};

const HeadhunterExpertise = ({ offer }: HeadhunterExpertiseProps) => {
  const { t: tGlobal } = useTranslation();
  const { t } = useTranslation('offers', { keyPrefix: 'viewOffer' });

  const wrapperClassName = 'flex flex-wrap items-center gap-s-8 px-s-16 py-s-8';
  const descriptionWrapperClassName = 'flex flex-col gap-s-8 px-s-16 py-s-8';
  const valueTextClassName = 'text-18 font-semibold text-primary';

  return (
    <Fragment>
      <SummaryHeader
        className="mt-s-16 rounded-t-md"
        headerTitle={t('subtitles.headhunterExpertise')}
      />
      <SectionGrid fullWidth className="my-s-0" readOnly hideTitle>
        {/*  similar mandates experiences */}

        <span className={wrapperClassName}>
          <span>{t('labels.similarMandatesExperiences')}</span>

          <span className={valueTextClassName}>
            <PlaceholderSwitcher value={offer?.similarMandatesExperiences}>
              {tGlobal(
                `enum.offerSimilarMandatesExperiencesCode.${offer?.similarMandatesExperiences?.toLowerCase()}`
              )}
            </PlaceholderSwitcher>
          </span>
        </span>
        {offer?.similarMandatesExperiences ===
          OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone &&
          offer?.whyRightToFillMandate && (
            <span className={descriptionWrapperClassName}>
              <span>{t('labels.whyRightToFillMandateEnterprise')}</span>
              <span className={valueTextClassName}>
                {offer?.whyRightToFillMandate}
              </span>
            </span>
          )}
        {offer?.similarMandatesExperiences !==
          OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone &&
          offer?.canGiveDetailsOnSimilarMandates &&
          offer?.similarMandatesReferencesDescription && (
            <span className={descriptionWrapperClassName}>
              <span>{t('labels.similarMandatesReferencesDescription')}</span>
              <span className={valueTextClassName}>
                {offer?.similarMandatesReferencesDescription}
              </span>
            </span>
          )}

        {offer?.similarMandatesExperiences !==
          OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone &&
          isDefined(offer?.canGiveDetailsOnSimilarMandates) &&
          !offer?.canGiveDetailsOnSimilarMandates &&
          offer?.whyNoSimilarMandatesReferences && (
            <span className={descriptionWrapperClassName}>
              <span>
                {t('labels.whyNoSimilarMandatesReferencesEnterprise')}
              </span>
              <span className={valueTextClassName}>
                {offer?.whyNoSimilarMandatesReferences}
              </span>
            </span>
          )}

        {/*  talent source */}

        {offer?.talentSources?.includes(OfferTalentSourceCode.Others) &&
        offer?.talentSources.length < 2 ? (
          <span className={cn(descriptionWrapperClassName, 'border-t-1')}>
            <span>{t('labels.talentSources')}</span>
            <span className={valueTextClassName}>
              {offer?.talentSourcesOthersDescription}
            </span>
          </span>
        ) : (
          <span className={cn(wrapperClassName, 'border-t-1')}>
            <span>{t('labels.talentSources')}</span>
            <span className={valueTextClassName}>
              <BadgeGroup
                variant="primary"
                elements={offer?.talentSources
                  ?.filter(
                    (talentSource) =>
                      talentSource !== OfferTalentSourceCode.Others
                  )
                  .map((talentSource) =>
                    tGlobal(
                      `enum.offerTalentSourceCode.${talentSource.toLowerCase()}`
                    )
                  )}
              />
            </span>
          </span>
        )}

        {offer?.talentSources?.includes(OfferTalentSourceCode.Others) &&
          offer?.talentSources.length > 1 &&
          offer?.talentSourcesOthersDescription && (
            <span className={descriptionWrapperClassName}>
              <span>{t('labels.others')}</span>
              <span className={valueTextClassName}>
                {offer?.talentSourcesOthersDescription}
              </span>
            </span>
          )}

        {offer?.talentSourcesMoreDetails && (
          <span className={descriptionWrapperClassName}>
            <span>{t('labels.moreDetails')}</span>
            <span className={valueTextClassName}>
              {offer?.talentSourcesMoreDetails}
            </span>
          </span>
        )}

        {/*  candidatesEvaluationProcesses */}

        {offer?.candidatesEvaluationProcesses?.includes(
          OfferCandidatesEvaluationProcessCode.Others
        ) && offer?.candidatesEvaluationProcesses.length < 2 ? (
          <span className={cn(descriptionWrapperClassName, 'border-t-1')}>
            <span>{t('labels.candidatesEvaluationProcesses')}</span>
            <span className={valueTextClassName}>
              {offer?.candidatesEvaluationProcessesOthersDescription}
            </span>
          </span>
        ) : (
          <span className={cn(wrapperClassName, 'border-t-1')}>
            <span>{t('labels.candidatesEvaluationProcesses')}</span>
            <span className={valueTextClassName}>
              <BadgeGroup
                variant="primary"
                elements={offer?.candidatesEvaluationProcesses
                  ?.filter(
                    (candidatesEvaluationProcesses) =>
                      candidatesEvaluationProcesses !==
                      OfferCandidatesEvaluationProcessCode.Others
                  )
                  .map((evaluationProcess) =>
                    tGlobal(
                      `enum.offerCandidatesEvaluationProcessCode.${evaluationProcess.toLowerCase()}`
                    )
                  )}
              />
            </span>
          </span>
        )}

        {offer?.candidatesEvaluationProcesses?.includes(
          OfferCandidatesEvaluationProcessCode.Others
        ) &&
          offer?.candidatesEvaluationProcesses.length > 1 &&
          offer?.candidatesEvaluationProcessesOthersDescription && (
            <span className={descriptionWrapperClassName}>
              <span>{t('labels.others')}</span>
              <span className={valueTextClassName}>
                {offer?.candidatesEvaluationProcessesOthersDescription}
              </span>
            </span>
          )}

        {offer?.candidatesEvaluationProcessesMoreDetails && (
          <span className={descriptionWrapperClassName}>
            <span>{t('labels.moreDetails')}</span>
            <span className={valueTextClassName}>
              {offer?.candidatesEvaluationProcessesMoreDetails}
            </span>
          </span>
        )}

        {/*  mandateFollowUps */}

        <span className={cn(wrapperClassName, 'border-t-1')}>
          <span>{t('labels.regularFollowUps')}</span>
          <span className={valueTextClassName}>
            <PlaceholderSwitcher value={offer?.mandateFollowUps}>
              {tGlobal(
                `boolean.${offer?.mandateFollowUps?.includes(
                  OfferMandateFollowUpCode.RegularFollowUps
                )}`
              )}
            </PlaceholderSwitcher>
          </span>
        </span>

        {offer?.mandateFollowUps?.includes(
          OfferMandateFollowUpCode.RegularFollowUps
        ) && (
          <>
            <span className={wrapperClassName}>
              <span>{t('labels.regularFollowUpsMethods')}</span>
              <span className={valueTextClassName}>
                <BadgeGroup
                  variant="primary"
                  elements={offer?.regularFollowUpsMethods?.map(
                    (regularFollowUpsMethod) =>
                      tGlobal(
                        `enum.offerRegularFollowUpMethodCode.${regularFollowUpsMethod.toLowerCase()}`
                      )
                  )}
                />
              </span>
            </span>

            <span className={wrapperClassName}>
              <span>{t('labels.regularFollowUpsPeriod')}</span>
              <span className={valueTextClassName}>
                <PlaceholderSwitcher value={offer?.regularFollowUpsPeriod}>
                  {tGlobal(
                    `enum.offerRegularFollowUpPeriodCode.${offer?.regularFollowUpsPeriod?.toLowerCase()}`
                  )}
                </PlaceholderSwitcher>
              </span>
            </span>
          </>
        )}

        <span className={wrapperClassName}>
          <span>{t('labels.finalReportFollowUp')}</span>
          <span className={valueTextClassName}>
            <PlaceholderSwitcher value={offer?.mandateFollowUps}>
              {tGlobal(
                `boolean.${offer?.mandateFollowUps?.includes(
                  OfferMandateFollowUpCode.DetailedFinalReport
                )}`
              )}
            </PlaceholderSwitcher>
          </span>
        </span>

        {offer?.mandateFollowUps?.includes(OfferMandateFollowUpCode.Others) &&
          offer?.mandateFollowUpsOthersDescription && (
            <span className={descriptionWrapperClassName}>
              <span>{t('labels.otherFollowUps')}</span>
              <span className={valueTextClassName}>
                {offer?.mandateFollowUpsOthersDescription}
              </span>
            </span>
          )}

        {offer?.mandateFollowUpsMoreDetails && (
          <span className={descriptionWrapperClassName}>
            <span>{t('labels.moreDetails')}</span>
            <span className={valueTextClassName}>
              {offer?.mandateFollowUpsMoreDetails}
            </span>
          </span>
        )}
      </SectionGrid>
    </Fragment>
  );
};

export default HeadhunterExpertise;
