import { useMemo } from 'react';

import { Tooltip } from '@application/components';

import { OfferProgressionStatusColors } from './OfferProgressionStatusColors';

interface OfferProgressionStatusProps {
  color: OfferProgressionStatusColors;
  tooltipText: string;
  size: 'small' | 'large';
  label?: string;
  hasRedirection: boolean;
  onClick?: () => void;
}

const OfferProgressionStatus = ({
  color,
  tooltipText,
  size,
  label,
  hasRedirection,
  onClick,
}: OfferProgressionStatusProps) => {
  const sizeClassName =
    size === 'small'
      ? 'min-w-[1.5rem] min-h-[1.5rem] rounded-full'
      : 'min-w-[9rem] min-h-[2rem] rounded-full';

  const getStatusColor = useMemo(() => {
    switch (color) {
      case OfferProgressionStatusColors.Green:
        return 'bg-progressionStatus-green';
      case OfferProgressionStatusColors.Red:
        return 'bg-progressionStatus-red';
      case OfferProgressionStatusColors.Yellow:
        return 'bg-progressionStatus-yellow';
      case OfferProgressionStatusColors.LightGray:
        return 'bg-progressionStatus-lightgray';
      default:
        return 'bg-progressionStatus-gray';
    }
  }, [color]);

  const baseClassName = `${sizeClassName} border border-primary ${getStatusColor}`;

  return (
    <div className="flex flex-col items-center">
      <Tooltip message={tooltipText}>
        {hasRedirection ? (
          <button
            onClick={onClick}
            className={baseClassName}
            aria-label={tooltipText}
          />
        ) : (
          <span
            className={`${baseClassName} inline-block`}
            aria-label={tooltipText}
            role="status"
          />
        )}
      </Tooltip>

      {size === 'large' && label && (
        <span className="pt-s-4 flex justify-center text-14">{label}</span>
      )}
    </div>
  );
};

export default OfferProgressionStatus;
