import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  RadioGroup,
  SectionGridRow,
  TextAreaField,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { Section } from '@application/views/recruitment/request/components';
import { OfferSimilarMandatesExperiencesCode } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../../../types';

const SimilarMandatesExperiencesFields = () => {
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const similarMandatesExperiences = watch('similarMandatesExperiences');
  const canGiveDetailsOnSimilarMandates = watch(
    'canGiveDetailsOnSimilarMandates'
  );

  const handleOnCanGiveDetailsOnSimilarMandatesChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof OfferFormFields
  ) => {
    setValue(name, event.target.value === 'true');
    trigger(name);
    trigger('similarMandatesReferencesDescription');
    trigger('whyNoSimilarMandatesReferences');
  };

  return (
    <Section legend={t('subtitles.similarMandatesExperiences')} padding={0}>
      <SectionGridRow isTopRow className="px-s-24">
        <Controller
          name="similarMandatesExperiences"
          control={control}
          render={({ field: { name, value }, fieldState: { error } }) => (
            <RadioGroup
              className="mx-auto"
              radioParentClassName="flex-col"
              data={Object.values(OfferSimilarMandatesExperiencesCode)
                .map((type) => ({
                  value: type,
                  text: {
                    fr: tGlobal(
                      `enum.offerSimilarMandatesExperiencesCode.${type.toLowerCase()}`
                    ),
                    en: tGlobal(
                      `enum.offerSimilarMandatesExperiencesCode.${type.toLowerCase()}`
                    ),
                  },
                }))
                .sort((a) =>
                  a.value ===
                  OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone
                    ? -1
                    : 1
                )}
              name={name}
              legendSize="medium"
              language={extractLanguage(i18n.language)}
              value={value as string | undefined}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(
                  name,
                  event.target.value as OfferSimilarMandatesExperiencesCode
                );
                trigger(name);
                trigger('whyRightToFillMandate');
                trigger('similarMandatesReferencesDescription');
                trigger('whyNoSimilarMandatesReferences');
              }}
              invalid={!!error}
              helperText={error?.message && tGlobal(error.message)}
            />
          )}
        />
      </SectionGridRow>
      {similarMandatesExperiences ===
        OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone && (
        <Controller
          name="whyRightToFillMandate"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              className="p-s-24"
              label={t('labels.whyRightToFillMandate')}
              labelSize="medium"
              name={name}
              value={value || ''}
              rows={4}
              maxChar={3000}
              onChange={(e) => {
                onChange(e);
                trigger(name);
              }}
              invalid={!!errors.whyRightToFillMandate}
              helperText={
                errors.whyRightToFillMandate?.message &&
                tGlobal(errors.whyRightToFillMandate?.message, {
                  max: '3000',
                })
              }
            />
          )}
        />
      )}
      {!!similarMandatesExperiences &&
        similarMandatesExperiences !==
          OfferSimilarMandatesExperiencesCode.SimilarMandatesExperiencesNone && (
          <>
            <Controller
              name="canGiveDetailsOnSimilarMandates"
              control={control}
              render={({ field: { name } }) => (
                <RadioGroup
                  className="p-s-24"
                  name={name}
                  legend={t('labels.canGiveDetailsOnSimilarMandates')}
                  data={BOOLEAN_VALUES}
                  language={extractLanguage(i18n.language)}
                  onChange={(e) => {
                    handleOnCanGiveDetailsOnSimilarMandatesChange(
                      e as ChangeEvent<HTMLInputElement>,
                      name
                    );
                  }}
                  legendSize="medium"
                  alignRow
                  invalid={!!errors.canGiveDetailsOnSimilarMandates}
                  helperText={
                    errors.canGiveDetailsOnSimilarMandates?.message &&
                    tGlobal(errors.canGiveDetailsOnSimilarMandates?.message)
                  }
                  value={canGiveDetailsOnSimilarMandates ?? undefined}
                />
              )}
            />
            {canGiveDetailsOnSimilarMandates && (
              <Controller
                name="similarMandatesReferencesDescription"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <TextAreaField
                    className="p-s-24"
                    label={t('labels.similarMandatesReferencesDescription')}
                    labelSize="medium"
                    name={name}
                    value={value || ''}
                    rows={4}
                    maxChar={3000}
                    onChange={(e) => {
                      onChange(e);
                      trigger(name);
                    }}
                    invalid={!!errors.similarMandatesReferencesDescription}
                    helperText={
                      errors.similarMandatesReferencesDescription?.message &&
                      tGlobal(
                        errors.similarMandatesReferencesDescription?.message,
                        {
                          max: '3000',
                        }
                      )
                    }
                  />
                )}
              />
            )}

            {canGiveDetailsOnSimilarMandates === false && (
              <Controller
                name="whyNoSimilarMandatesReferences"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <TextAreaField
                    label={t('labels.whyNoSimilarMandatesReferences')}
                    className="p-s-24"
                    labelSize="medium"
                    name={name}
                    value={value || ''}
                    rows={4}
                    maxChar={3000}
                    onChange={(e) => {
                      onChange(e);
                      trigger(name);
                    }}
                    invalid={!!errors.whyNoSimilarMandatesReferences}
                    helperText={
                      errors.whyNoSimilarMandatesReferences?.message &&
                      tGlobal(errors.whyNoSimilarMandatesReferences?.message, {
                        max: '3000',
                      })
                    }
                  />
                )}
              />
            )}
          </>
        )}
    </Section>
  );
};

export default SimilarMandatesExperiencesFields;
