import React from 'react';

import { chatDateFormatter } from '@utils/date-utils';
import { cn } from '@utils/lib-utils';

export type MessageItemProps = {
  position: 'left' | 'right';
  date: string;
  viewed?: boolean;
  content: string;
  isSystem?: boolean;
};

const MessageItem = React.forwardRef<HTMLDivElement, MessageItemProps>(
  ({ position, date, viewed, content, isSystem }, ref) => {
    const formattedDate = chatDateFormatter(date);

    const classMainWrapper = cn(
      {
        'mr-auto': position === 'left',
        'ml-auto': position === 'right',
      },
      'w-[80%] md:w-[60%] pt-s-16'
    );
    const classText = cn(
      {
        'bg-disabled-light': position === 'left',
        'bg-secondary': position === 'right',
        'bg-info whitespace-pre-line': isSystem,
      },
      'text-14 rounded-md p-s-16 selection:bg-accent'
    );
    const classDate = cn(
      {
        'justify-end': position === 'right',
      },
      'flex items-center gap-s-4 h-s-32 text-12 text-neutral-secondary'
    );
    const classViewedIcon = 'ri-check-double-line text-18';

    return (
      <div ref={ref} className={classMainWrapper}>
        <div className={classText}>{content}</div>
        <div className={classDate}>
          {viewed === true && <i className={classViewedIcon} />}
          {formattedDate}
        </div>
      </div>
    );
  }
);

export default MessageItem;
