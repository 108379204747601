import { useMutation } from 'urql';

import {
  Mutation,
  MutationMarkCandidateRequestChatRoomMessagesAsReadArgs,
} from '@domain/graphql.types';
import { CandidateRequestChatRoomMessagesMarkAsRead } from '@infrastructure/graphql/chat';

const useCandidateRequestChatRoomMessagesMarkAsRead = () => {
  const [{ data, fetching, error }, markMessagesAsRead] = useMutation<
    Pick<Mutation, 'markCandidateRequestChatRoomMessagesAsRead'>,
    MutationMarkCandidateRequestChatRoomMessagesAsReadArgs
  >(CandidateRequestChatRoomMessagesMarkAsRead);

  return {
    markMessagesAsRead,
    data,
    fetching,
    error,
  };
};

export default useCandidateRequestChatRoomMessagesMarkAsRead;
