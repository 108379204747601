import { ChangeEvent } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { extractLanguage } from '@utils/i18n-utils';

import { RequestFormFields } from '../../schema';

type HeadHunterFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
};

const HeadHunterFields = ({
  control,
  errors,
  register,
  setValue,
  watch,
  trigger,
}: HeadHunterFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const exclusivityPeriodOffered = watch(
    'headhunterConditionExclusivityPeriodOffered'
  );

  return (
    <SectionGrid
      title={t('subtitles.forHeadHunting')}
      className="mt-s-40"
      fullWidth
    >
      <SectionGridRow isTopRow>
        <FormInputWithSuffix
          label={t('labels.duration')}
          suffix={t('suffix.weeks')}
          type="number"
          mediumSuffix
          noMargin
          minValue={1}
          invalid={!!errors.conditionMaxRecruitmentPeriodValue}
          helperText={
            errors.conditionMaxRecruitmentPeriodValue?.message &&
            tGlobal(errors.conditionMaxRecruitmentPeriodValue?.message)
          }
          {...register('conditionMaxRecruitmentPeriodValue')}
        />
      </SectionGridRow>

      <SectionGridRow>
        <Controller
          name="headhunterConditionExclusivityPeriodOffered"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.isExclusivityPeriodOffered')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value === 'true');
                trigger('headhunterConditionExclusivityPeriodOffered');
              }}
              legendSize="medium"
              alignRow
              value={exclusivityPeriodOffered ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />
      </SectionGridRow>

      {exclusivityPeriodOffered && (
        <SectionGridRow>
          <FormInputWithSuffix
            label={t('labels.exclusivityPeriodOffered')}
            suffix={t('suffix.weeks')}
            type="number"
            mediumSuffix
            noMargin
            minValue={1}
            invalid={!!errors.headhunterConditionExclusivityPeriodValue}
            helperText={
              errors.headhunterConditionExclusivityPeriodValue?.message &&
              tGlobal(errors.headhunterConditionExclusivityPeriodValue?.message)
            }
            {...register('headhunterConditionExclusivityPeriodValue')}
          />
        </SectionGridRow>
      )}
    </SectionGrid>
  );
};

export default HeadHunterFields;
