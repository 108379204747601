import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RootPrivatePage } from '@application/enums/pagesUrl';
import { RequestChatRoom, RequestChatRoomMessage } from '@domain/graphql.types';

import { ChatRoom } from './components';
import { ChatListItemProps } from './components/ChatListItem';
import { ChatContent, ChatListProps } from './components/ChatRoom';
import {
  useCreateRequestChatRoomMessage,
  useMarkRequestChatRoomMessagesAsRead,
  useRequestChatRoomMessagesList,
  useRequestChatRoomsList,
} from './hooks';

type RequestChatContentProps = {
  requestId: string;
  accountId: string;
  title: string;
  subtitle: string;
};

const RequestChatContent = ({
  requestId,
  accountId,
  title,
  subtitle,
}: RequestChatContentProps) => {
  const [selectedChatRoomId, setSelectedChatRoomId] = useState<string>('');
  const [chatRooms, setChatRooms] = useState<RequestChatRoom[]>([]);
  const [messages, setMessages] = useState<RequestChatRoomMessage[]>([]);
  const { t } = useTranslation('chatRoom');

  const { createRequestChatRoomMessage } = useCreateRequestChatRoomMessage();
  const { markMessagesAsRead } = useMarkRequestChatRoomMessagesAsRead();

  const { requestChatRooms, refetchRequestChatRooms } = useRequestChatRoomsList(
    {
      requestId,
      enterpriseAccountId: accountId,
    }
  );

  const {
    requestChatRoomMessages,
    isLoading: requestChatRoomMessagesIsLoading,
    refetchRequestChatRoomMessages,
  } = useRequestChatRoomMessagesList({ requestChatRoomId: selectedChatRoomId });

  const [
    chatListUnderNegotiation,
    chatListNotUnderNegotiation,
    chatListNoOffer,
    chatListNoOfferNoMessage,
  ]: [
    ChatListProps | undefined,
    ChatListProps | undefined,
    ChatListProps | undefined,
    ChatListProps | undefined,
  ] = useMemo(() => {
    const underNegotiation: ChatListProps = {
      groupeTitle: t('labels.underNegotiation'),
      chatRoomList: [],
    };
    const notUnderNegotiation: ChatListProps = {
      groupeTitle: t('labels.notUnderNegotiation'),
      chatRoomList: [],
    };
    const noOffer: ChatListProps = {
      groupeTitle: t('labels.noOffer'),
      chatRoomList: [],
    };
    const noOfferNoMessage: ChatListProps = {
      groupeTitle: t('labels.noOfferNoMessage'),
      chatRoomList: [],
    };

    chatRooms.forEach((chatRoom) => {
      const chatItem: ChatListItemProps = {
        selected: selectedChatRoomId === chatRoom.id,
        rank: chatRoom.offerData?.rank ?? undefined,
        title: chatRoom.agencyName ?? '',
        preview: chatRoom.agencyLastMessage?.message ?? '',
        date: chatRoom.agencyLastMessage?.sentAt,
        newMessageCount: chatRoom.unreadMessagesCount,
        onClick: () => setSelectedChatRoomId(chatRoom.id),
      };

      if (chatRoom.offerData?.isUnderNegotiation) {
        underNegotiation.chatRoomList.push(chatItem);
      } else if (chatRoom.offerData?.hasMadeOffer) {
        notUnderNegotiation.chatRoomList.push(chatItem);
      } else if (chatRoom.agencyLastMessage) {
        noOffer.chatRoomList.push(chatItem);
      } else {
        noOfferNoMessage.chatRoomList.push(chatItem);
      }
    });

    return [
      underNegotiation.chatRoomList.length ? underNegotiation : undefined,
      notUnderNegotiation.chatRoomList.length ? notUnderNegotiation : undefined,
      noOffer.chatRoomList.length ? noOffer : undefined,
      noOfferNoMessage.chatRoomList.length ? noOfferNoMessage : undefined,
    ];
  }, [t, chatRooms, selectedChatRoomId]);

  const selectedChatRoom = useMemo(
    () =>
      requestChatRooms?.find((chatRoom) => chatRoom.id === selectedChatRoomId),
    [requestChatRooms, selectedChatRoomId]
  );

  const chatContent: ChatContent = useMemo(
    () => ({
      title: selectedChatRoom?.agencyName ?? '',
      messageList: messages.map((message) => {
        const readAtDate = message.readAt ? new Date(message.readAt) : null;
        const now = new Date();

        return {
          position:
            message.accountId === accountId
              ? ('right' as const)
              : ('left' as const),
          date: message.sentAt,
          viewed: !!(readAtDate && readAtDate < now),
          content: message.message,
          isSystem: message.isSystem,
        };
      }),
    }),
    [selectedChatRoom?.agencyName, messages, accountId]
  );

  const handleRefresh = useCallback(async () => {
    refetchRequestChatRooms();
    refetchRequestChatRoomMessages();
  }, [refetchRequestChatRooms, refetchRequestChatRoomMessages]);

  const handleSendMessage = useCallback(
    async (message: string) => {
      await createRequestChatRoomMessage({
        input: {
          requestChatRoomId: selectedChatRoomId,
          message,
        },
      });

      if (requestChatRoomMessages.length < 1) {
        refetchRequestChatRooms();
      }
    },
    [
      createRequestChatRoomMessage,
      selectedChatRoomId,
      requestChatRoomMessages.length,
      refetchRequestChatRooms,
    ]
  );

  const handleMarkMessagesAsRead = useCallback(async () => {
    await markMessagesAsRead({
      input: { requestChatRoomId: selectedChatRoomId },
    });
    refetchRequestChatRooms();
    refetchRequestChatRoomMessages();
  }, [
    markMessagesAsRead,
    refetchRequestChatRoomMessages,
    refetchRequestChatRooms,
    selectedChatRoomId,
  ]);

  useEffect(() => {
    if (!requestChatRooms) {
      setChatRooms([]);
      return;
    }

    const hasSameContent =
      chatRooms.length === requestChatRooms.length &&
      chatRooms.every(
        (room, idx) =>
          room.id === requestChatRooms[idx].id &&
          room.unreadMessagesCount === requestChatRooms[idx].unreadMessagesCount
      );

    if (!hasSameContent) {
      setChatRooms(requestChatRooms);
    }
  }, [requestChatRooms, chatRooms]);

  useEffect(() => {
    if (!requestChatRoomMessages) {
      setMessages([]);
      return;
    }

    const hasSameContent =
      messages.length === requestChatRoomMessages.length &&
      messages.every(
        (message, idx) =>
          message.id === requestChatRoomMessages[idx].id &&
          message.readAt === requestChatRoomMessages[idx].readAt
      );

    if (!hasSameContent) {
      setMessages(requestChatRoomMessages);
    }
  }, [requestChatRoomMessages, messages, requestChatRooms]);

  useEffect(() => {
    if (requestChatRooms?.length && !selectedChatRoomId) {
      setSelectedChatRoomId(requestChatRooms[0].id);
    }
  }, [requestChatRooms, selectedChatRoomId]);

  return (
    <ChatRoom
      isLoading={requestChatRoomMessagesIsLoading}
      title={title}
      subtitle={subtitle}
      chatListUnderNegotiation={chatListUnderNegotiation}
      chatListNotUnderNegotiation={chatListNotUnderNegotiation}
      chatListNoOffer={chatListNoOffer}
      chatListNoOfferNoMessage={chatListNoOfferNoMessage}
      chatContent={chatContent}
      backTo={RootPrivatePage.REQUESTS}
      onSendMessage={handleSendMessage}
      refresh={handleRefresh}
      markMessagesAsRead={handleMarkMessagesAsRead}
    />
  );
};

export default RequestChatContent;
