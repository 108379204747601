import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router';

import { Button, Link, Tooltip } from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { ModalContext } from '@application/context';
import { PrivatePage } from '@application/enums/pagesUrl';
import { getOfferCandidateName } from '@application/views/recruitment/request/candidate/OfferCandidateAnonymization';
import { OfferCandidate, OfferCandidatesFilters } from '@domain/graphql.types';

import useSeenAtOfferCandidate from '../../hooks/useOfferCandidateSeenAt';
import { CandidateActionMenu } from '../components/CandidateActionMenu';
import CandidatePrivateNotesModal from '../privateNotes/CandidatePrivateNotesModal';
import useGetOfferCandidates from './useGetOfferCandidates';
import useUpdateCandidatePrivateNotes from './useUpdateCandidatePrivateNotes';
import useUpdateCandidateStatusToHired from './useUpdateCandidateStatusToHired';
import useUpdateCandidateStatusToInterview from './useUpdateCandidateStatusToInterview';
import useUpdateCandidateStatusToRejected from './useUpdateCandidateStatusToRejected';

const columnIds = {
  name: 'name',
  status: 'status',
  experience: 'experience',
  cv: 'cv',
  notes: 'notes',
  actions: 'actions',
} as const;

const columnHelper = createColumnHelper<OfferCandidate>();

const getColumns = (
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>,
  handleUpdateStatusToHired: (
    candidateId: string,
    closeRequest: boolean
  ) => () => Promise<void>,
  handleUpdateStatusToInterview: (candidateId: string) => () => Promise<void>,
  handleUpdateStatusToRejected: (
    candidateId: string,
    rejectionReason: string,
    rejectionExplanation: string
  ) => () => Promise<void>,
  updateSeenAt: (candidateId: string) => void,
  openPrivateNotesModal: (candidateId: string, initialNotes: string) => void,
  disabledAction: boolean
) => [
  columnHelper.accessor(
    (row, index) => (
      <Link
        from={useLocation().pathname}
        to={generatePath(PrivatePage.OFFER_CANDIDATE_DETAILS, {
          candidateId: row.id,
        })}
      >
        {getOfferCandidateName(
          row,
          t('list.candidatePlaceHolder', { index: index + 1 })
        )}
      </Link>
    ),
    {
      id: columnIds.name,
      cell: (info) => (
        <div className="flex gap-s-8 items-center">
          {info.row.original.metAt && (
            <Tooltip
              message={t('certifiedResume', {
                date: new Date(info.row.original.metAt),
              })}
            >
              <i className="ri-verified-badge-fill text-18" />
            </Tooltip>
          )}

          <span>{info.getValue() || '-'}</span>
        </div>
      ),
      header: () => t('list.candidate'),
      size: 300,
      minSize: 300,
      maxSize: undefined,
    }
  ),
  columnHelper.accessor((row) => row.status, {
    id: columnIds.status,
    cell: (info) => (
      <span>
        {info.getValue() !== undefined
          ? tGlobal(`enum.offerCandidateStatusTypeCode.${info.getValue()}`)
          : '-'}
      </span>
    ),
    header: () => t('list.status'),
    size: 180,
    minSize: 100,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.experience, {
    id: columnIds.experience,
    cell: (info) => (
      <span>
        {info.getValue() !== undefined
          ? t('list.experienceYears', { count: info.getValue() as number })
          : '-'}
      </span>
    ),
    header: () => t('list.experience'),
    size: 180,
    minSize: 100,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.cvUrl, {
    id: columnIds.cv,
    cell: (info) => {
      const cvUrl = info.getValue();

      return cvUrl ? (
        <a
          href={cvUrl}
          target="_blank"
          className="underline text-16 font-semibold"
          rel="noreferrer"
          onClick={() => updateSeenAt(info.row.original.id)}
        >
          {t('list.cvButton')}
        </a>
      ) : (
        <span>-</span>
      );
    },
    header: () => t('list.cv'),
    size: 180,
    minSize: 120,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.notes, {
    id: columnIds.notes,
    cell: (info) => (
      <TooltipEllipsis toolTip={info.getValue() || '-'} lineClamp={2}>
        <div className="text-left">{info.getValue() || '-'}</div>
      </TooltipEllipsis>
    ),
    header: () => t('list.notes'),
    size: 180,
    minSize: 180,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row, {
    id: columnIds.actions,
    cell: (info) => (
      <div className="flex items-center justify-center w-full">
        <Tooltip
          message={
            <div className="line-clamp-4 overflow-hidden">
              {info.row.original.privateNotes}
            </div>
          }
        >
          <Button
            icon={<i className="ri-edit-2-line" />}
            type="button"
            onClick={() =>
              openPrivateNotesModal(
                info.row.original.id,
                info.row.original.privateNotes || ''
              )
            }
          >
            <div>{t('list.privateNotes')}</div>
          </Button>
        </Tooltip>
      </div>
    ),
    header: '',
    size: 250,
    minSize: 250,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row, {
    id: columnIds.actions,
    header: () => t('list.actions'),
    cell: (info) =>
      !disabledAction && (
        <CandidateActionMenu
          candidateId={info.row.original.id}
          status={info.row.original.status}
          onHire={handleUpdateStatusToHired}
          onInterview={handleUpdateStatusToInterview}
          onReject={handleUpdateStatusToRejected}
        />
      ),
    size: 32,
    minSize: 32,
    maxSize: undefined,
  }),
];

type UseAccountPreferredSpecialtiesListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<OfferCandidate>[];
  isLoading: boolean;
  offerCandidates: OfferCandidate[];
};

export const useOfferCandidatesList = (
  filters: OfferCandidatesFilters,
  requestId?: string,
  disabledAction?: boolean
): UseAccountPreferredSpecialtiesListProps => {
  const { t } = useTranslation('offers', {
    keyPrefix: 'candidates',
  });

  const navigate = useNavigate();

  const { setModal } = useContext(ModalContext);

  const { updateCandidateStatusToHired } = useUpdateCandidateStatusToHired();
  const { updateCandidateStatusToInterview } =
    useUpdateCandidateStatusToInterview();
  const { updateCandidateStatusToRejected } =
    useUpdateCandidateStatusToRejected();

  const handleUpdateStatusToHired = useCallback(
    (candidateId: string, closeRequest: boolean) => async () => {
      await updateCandidateStatusToHired({
        updateCandidateStatusToHiredInput: { candidateId, closeRequest },
      });

      if (closeRequest && requestId) {
        navigate(
          generatePath(PrivatePage.OFFER_COMPARISON, {
            id: requestId,
          }),
          { replace: true }
        );
      }
    },
    [navigate, requestId, updateCandidateStatusToHired]
  );

  const handleUpdateStatusToInterview = useCallback(
    (candidateId: string) => async () => {
      await updateCandidateStatusToInterview({
        updateCandidateStatusToInterviewInput: { candidateId },
      });
    },
    [updateCandidateStatusToInterview]
  );

  const handleUpdateStatusToRejected = useCallback(
    (
      candidateId: string,
      rejectionReason: string,
      rejectionExplanation: string
    ) =>
      async () => {
        await updateCandidateStatusToRejected({
          updateCandidateStatusToRejectedInput: {
            candidateId,
            rejectionReason,
            rejectionExplanation,
          },
        });
      },
    [updateCandidateStatusToRejected]
  );

  const { updateCandidatePrivateNotes } = useUpdateCandidatePrivateNotes();

  const handleUpdateNotes = useCallback(
    async (candidateId: string, notes: string) => {
      setModal(null);
      await updateCandidatePrivateNotes({
        updateCandidatePrivateNotesInput: {
          candidateId,
          privateNotes: notes,
        },
      });
    },
    [updateCandidatePrivateNotes, setModal]
  );

  const openPrivateNotesModal = useCallback(
    (candidateId: string, initialNotes: string) => {
      setModal({
        title: t('list.privateNotes'),
        maxWidth: '4xl',
        content: (
          <CandidatePrivateNotesModal
            initialNotes={initialNotes}
            onCancel={() => setModal(null)}
            onConfirm={(notes) => handleUpdateNotes(candidateId, notes)}
          />
        ),
      });
    },
    [setModal, t, handleUpdateNotes]
  );

  const { updateSeenAt } = useSeenAtOfferCandidate();

  const { t: tGlobal } = useTranslation();

  const {
    viewModel: { data, isLoading },
  } = useGetOfferCandidates(filters);

  const columns = getColumns(
    t,
    tGlobal,
    handleUpdateStatusToHired,
    handleUpdateStatusToInterview,
    handleUpdateStatusToRejected,
    updateSeenAt,
    openPrivateNotesModal,
    disabledAction || false
  );

  return {
    columnIds,
    columns: columns as ColumnDef<OfferCandidate>[],
    isLoading,
    offerCandidates: data,
  };
};
