import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';
import { validate as isUUID } from 'uuid';

import { Badge, PageHeader, PageLoader } from '@application/components';
import { Button, ButtonLinkCounter } from '@application/components/buttons';
import { Cluster, Stack } from '@application/components/container-layouts';
import { ButtonLink } from '@application/components/links';
import { ConfirmationModal } from '@application/components/modal';
import { PictureBadges } from '@application/components/picture-badges';
import { SelectField } from '@application/components/select-field';
import { OptionType } from '@application/components/select-field/select';
import { ModalContext } from '@application/context';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { useFreemiumPublishRequestValidation } from '@application/views/billing';
import { useCandidateOpportunitiesMatchingRequestModal } from '@application/views/candidate/opportunity/hooks';
import {
  CandidateOpportunity,
  RequestStatusTypeCode,
} from '@domain/graphql.types';
import { calculateTimeSince } from '@utils/date-utils';
import { cn } from '@utils/lib-utils';
import { getRequestDropDownStatusClassNames } from '@utils/styles-utils';

import {
  useDeleteRequest,
  useDuplicateRequest,
  useGetRequest,
  useUpdateRequestStatus,
} from './hooks';
import { ConditionsSummary, DescriptionSummary } from './summary';

const ViewRequestPage = () => {
  const { t } = useTranslation('requests');
  const { t: tChat } = useTranslation('chatRoom', { keyPrefix: 'button' });
  const { t: tGlobal } = useTranslation();

  const [
    hasOpenedMatchingCandidateOpportunitiesModalFromState,
    setHasOpenedMatchingCandidateOpportunitiesModalFromState,
  ] = useState(false);

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { setModal } = useContext(ModalContext);

  const { deleteRequest } = useDeleteRequest();
  const { duplicateRequest } = useDuplicateRequest();
  const { updateRequestStatus } = useUpdateRequestStatus();

  const { canPublishRequest } = useFreemiumPublishRequestValidation();
  const { openModal: openCandidateOpportunitiesMatchingRequestModal } =
    useCandidateOpportunitiesMatchingRequestModal();

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
    refetchRequest,
  } = useGetRequest(id);

  const timeSinceCreated = useMemo(
    () => calculateTimeSince(data?.createdAt),
    [data?.createdAt]
  );

  useEffect(() => {
    if (
      data?.matchingCandidateOpportunities &&
      location.state?.openMatchingCandidateOpportunitiesModal &&
      !hasOpenedMatchingCandidateOpportunitiesModalFromState
    ) {
      openCandidateOpportunitiesMatchingRequestModal(
        data?.matchingCandidateOpportunities as CandidateOpportunity[]
      );
      setHasOpenedMatchingCandidateOpportunitiesModalFromState(true);
    }
  }, [
    data?.matchingCandidateOpportunities,
    hasOpenedMatchingCandidateOpportunitiesModalFromState,
    location.state?.openMatchingCandidateOpportunitiesModal,
    openCandidateOpportunitiesMatchingRequestModal,
  ]);

  const statusOptions = useMemo(() => {
    const options: OptionType[] = data?.status
      ? [
          {
            value: data?.status,
            label: t(
              `enum.requestStatusTypeCode.${data?.status.toLowerCase()}`
            ),
          },
        ]
      : [];
    data?.availableTransitions.map((a) =>
      options.push({
        value: a,
        label: t(`enum.requestStatusTypeCode.${a.toLowerCase()}`),
      })
    );
    return options;
  }, [data, t]);

  const handleStatusUpdate = useCallback(
    async (status: RequestStatusTypeCode) => {
      await updateRequestStatus({
        requestStatusUpdateInput: { id, status },
      });
      refetchRequest();
    },
    [updateRequestStatus, id, refetchRequest]
  );

  const handleDuplicate = useCallback(async () => {
    await duplicateRequest({ input: { requestId: id } });
  }, [duplicateRequest, id]);

  const handleDelete = useCallback(async () => {
    setModal(null);
    await deleteRequest({ deleteRequestInput: { requestId: id } });
  }, [deleteRequest, setModal, id]);

  const confirmDelete = useCallback(() => {
    setModal({
      title: t('modal.titles.deleteRequest'),
      content: (
        <ConfirmationModal
          content={t('modal.contents.confirmDelete')}
          onCancel={() => setModal(null)}
          onConfirm={handleDelete}
        />
      ),
    });
  }, [setModal, t, handleDelete]);

  const statusClassName = getRequestDropDownStatusClassNames(
    data?.status || RequestStatusTypeCode.Draft
  );

  const editButtonVisible =
    data &&
    (new Date(data.receivingOfferDeadline) > new Date() ||
      data.status === RequestStatusTypeCode.Draft);

  const deleteButtonVisible =
    data?.status !== RequestStatusTypeCode.Agreement && data?.offersCount === 0;

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={t('title.details')}
        backTo={RootPrivatePage.REQUESTS}
      >
        {data?.recruitmentNegotiation && (
          <ButtonLink
            primary
            className="mr-s-16"
            icon={<i className="ri-shake-hands-line" />}
            to={generatePath(PrivatePage.RECRUITMENT_NEGOTIATION_DETAILS, {
              id: data?.recruitmentNegotiation?.id || null,
            })}
            from={location.pathname}
          >
            {t('button.negotiationsZone')}
          </ButtonLink>
        )}

        {data?.matchingCandidateOpportunities && (
          <Button
            primary
            icon={<i className="ri-alarm-warning-line" />}
            onClick={() =>
              openCandidateOpportunitiesMatchingRequestModal(
                data?.matchingCandidateOpportunities as CandidateOpportunity[]
              )
            }
            className="mr-s-16"
          >
            {t('button.seeMatchingCandidates')}
          </Button>
        )}

        {data?.hasAgencyToChat && (
          <ButtonLinkCounter
            count={data.unreadMessagesCount}
            iconName="ri-question-answer-line"
            to={generatePath(PrivatePage.REQUEST_CHAT, {
              id: data?.id ?? '',
            })}
            from={generatePath(PrivatePage.REQUEST_DETAILS, {
              id: data?.id ?? '',
            })}
            size="medium"
          >
            {tChat('chat')}
          </ButtonLinkCounter>
        )}
      </PageHeader>

      <Stack space={24}>
        <Cluster className="justify-between mb-s-24">
          <div className="flex flex-row items-center gap-s-24">
            {data?.status === RequestStatusTypeCode.Draft &&
            canPublishRequest ? (
              <SelectField
                label="status"
                hideLabel
                name="status"
                options={statusOptions}
                controlClassName={statusClassName}
                value={statusOptions.find(
                  (o: OptionType) => o.value === data?.status
                )}
                onChange={(option: any) =>
                  handleStatusUpdate(option.value as RequestStatusTypeCode)
                }
              />
            ) : (
              <Badge
                value={t(
                  `enum.requestStatusTypeCode.${data?.status.toLowerCase()}`
                )}
                placeholder="-"
                className={cn(
                  getRequestDropDownStatusClassNames(
                    data?.status || RequestStatusTypeCode.Draft
                  ),
                  'rounded-md h-s-48 w-s-112'
                )}
                valueClassName="text-16 font-semibold"
              />
            )}

            <PictureBadges
              pictures={[
                {
                  name: data?.user.name ?? undefined,
                  url: data?.user.avatarUrl,
                },
              ]}
              rounded="rounded-md"
              tooltipPosition="right"
            />

            <span>
              {timeSinceCreated.value > 1
                ? tGlobal('date.since', {
                    date: tGlobal(`date.options.${timeSinceCreated.unit}`, {
                      count: timeSinceCreated.value,
                    }),
                  })
                : tGlobal('date.today')}
            </span>
          </div>

          <div>
            <Button
              icon={<i className="ri-file-copy-line" />}
              className="ml-s-16"
              onClick={handleDuplicate}
            >
              {tGlobal('button.duplicate')}
            </Button>

            {deleteButtonVisible && (
              <Button
                icon={<i className="ri-delete-bin-line" />}
                className="ml-s-16"
                onClick={confirmDelete}
              >
                {tGlobal('button.delete')}
              </Button>
            )}

            {editButtonVisible && (
              <ButtonLink
                className="ml-s-16"
                to={generatePath(PrivatePage.REQUEST_EDIT, { id })}
                icon={<i className="ri-pencil-line" />}
              >
                {tGlobal('button.edit')}
              </ButtonLink>
            )}
          </div>
        </Cluster>
      </Stack>

      <DescriptionSummary data={data} />

      <ConditionsSummary data={data} />
    </>
  );
};

export default ViewRequestPage;
