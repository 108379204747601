import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import {
  OfferConditionOtherBonusCode,
  RecruitmentOpportunity,
} from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type OtherBonusesOfferedProps = {
  data: RecruitmentOpportunity | undefined;
};

const OtherBonusesOffered = ({ data }: OtherBonusesOfferedProps) => {
  const { t, i18n } = useTranslation('requests');

  const showCandidateCompanyLongivity =
    data?.generalContractualConditions.otherBonuses?.includes(
      OfferConditionOtherBonusCode.CandidateCompanyLongivity
    );
  const showHiringSpeed =
    data?.generalContractualConditions.otherBonuses?.includes(
      OfferConditionOtherBonusCode.HiringSpeed
    );
  const showOther = data?.generalContractualConditions.otherBonuses?.includes(
    OfferConditionOtherBonusCode.Other
  );

  return (
    <>
      {showCandidateCompanyLongivity && (
        <>
          <SectionGridRow isTopRow>
            <span className="mr-s-8">
              {t('labels.otherBonusesOfferedDuration')}
              <span className="text-18 font-semibold text-primary">
                {data?.generalContractualConditions.bonusMonthValue}
              </span>{' '}
              {t('suffix.weeks')}
            </span>
          </SectionGridRow>
          <SectionGridRow partialBorderTop>
            <span className="mr-s-8">
              {t('labels.otherBonusesOfferedAmountShort')}
            </span>
            <span className="text-18 font-semibold text-primary">
              {data?.generalContractualConditions.bonusValue}$
            </span>
          </SectionGridRow>
        </>
      )}

      {showHiringSpeed && (
        <>
          <SectionGridRow isTopRow={!showCandidateCompanyLongivity}>
            <span className="mr-s-8">
              {t('labels.otherBonusesOfferedHiringSpeed')}
              <span className="text-18 font-semibold text-primary">
                {formatDate(
                  data?.generalContractualConditions.hiredDateBonusRequirement,
                  i18n.language
                )}
              </span>
            </span>
          </SectionGridRow>

          <SectionGridRow partialBorderTop>
            <span className="mr-s-8">
              {t('labels.otherBonusesOfferedAmountShort')}
            </span>
            <span className="text-18 font-semibold text-primary">
              {data?.generalContractualConditions.hiredDateBonusValue}$
            </span>
          </SectionGridRow>
        </>
      )}

      {showOther && (
        <>
          <SectionGridRow
            isTopRow={!showCandidateCompanyLongivity && !showHiringSpeed}
            className="block"
          >
            <div className="mr-s-8">{t('labels.otherBonusesOfferedOther')}</div>
            <span className="text-18 font-semibold text-primary">
              {data?.generalContractualConditions.otherBonusDescription}
            </span>
          </SectionGridRow>

          <SectionGridRow partialBorderTop>
            <span className="mr-s-8">
              {t('labels.otherBonusesOfferedAmountShort')}
            </span>
            <span className="text-18 font-semibold text-primary">
              {data?.generalContractualConditions.otherBonusValue}$
            </span>
          </SectionGridRow>
        </>
      )}
    </>
  );
};

export default OtherBonusesOffered;
