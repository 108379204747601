import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  CheckboxGroup,
  FormInputWithSuffix,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  Stack,
  TextAreaField,
  Tooltip,
} from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import { BOOLEAN_VALUES } from '@application/constants';
import {
  OfferConditionOtherBonusCode,
  RecruitmentOpportunity,
} from '@domain/graphql.types';
import { formatDate, formattedNowDate } from '@utils/date-utils';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../../types';

type OtherBonusesOfferedFieldsProps = {
  recruitmentOpportunity?: RecruitmentOpportunity;
};

const OtherBonusesOfferedFields = ({
  recruitmentOpportunity,
}: OtherBonusesOfferedFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();
  const conditionOtherBonusesAsked = watch('conditionOtherBonusesAsked');
  const conditionOtherBonuses = watch('conditionOtherBonuses') || [];

  const recruitmentOpportunityConditions =
    recruitmentOpportunity?.generalContractualConditions;

  return (
    <>
      {/* TO DO: Utiliser le section grid title tooltips de Giovanni */}
      <div className="flex items-center mt-s-32">
        <h4>{t('labels.additionalBonus')}</h4>
        <Tooltip message={t('labels.additionalBonusOfferTooltips')}>
          <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
        </Tooltip>
      </div>

      <SectionGrid hideTitle isOffer>
        <SectionGridRow isTopRow isOffer>
          <Stack className="w-full" space={16}>
            <Controller
              name="conditionOtherBonusesAsked"
              control={control}
              render={({ field: { name }, fieldState: { error } }) => (
                <RadioGroup
                  name={name}
                  data={BOOLEAN_VALUES}
                  legendSize="medium"
                  legend={<Trans i18nKey="labels.otherBonusesAsked" t={t} />}
                  language={extractLanguage(i18n.language)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, event.target.value === 'true');
                    trigger(name);
                  }}
                  alignRow
                  value={conditionOtherBonusesAsked ?? undefined}
                  invalid={!!error}
                  helperText={error?.message && tGlobal(error?.message)}
                />
              )}
            />

            {conditionOtherBonusesAsked && (
              <Controller
                name="conditionOtherBonuses"
                control={control}
                render={({ field: { value } }) => (
                  <CheckboxGroup
                    data={Object.values(OfferConditionOtherBonusCode).map(
                      (type) => ({
                        value: type,
                        text: {
                          fr: t(`enum.otherBonuses.${type.toLowerCase()}`),
                          en: t(`enum.otherBonuses.${type.toLowerCase()}`),
                        },
                      })
                    )}
                    legend={t('labels.otherBonuses')}
                    language={extractLanguage(i18n.language)}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setValue('conditionOtherBonuses', [
                          ...conditionOtherBonuses,
                          event.target.value as OfferConditionOtherBonusCode,
                        ]);
                      } else {
                        setValue('conditionOtherBonuses', [
                          ...conditionOtherBonuses.filter(
                            (p) => p !== event.target.value
                          ),
                        ]);
                      }

                      trigger('conditionOtherBonuses');
                    }}
                    legendSize="medium"
                    values={value}
                    invalid={!!errors.conditionOtherBonuses}
                    helperText={
                      errors.conditionOtherBonuses?.message &&
                      tGlobal(errors.conditionOtherBonuses?.message)
                    }
                  />
                )}
              />
            )}
          </Stack>
        </SectionGridRow>
        <span />

        {conditionOtherBonusesAsked &&
          conditionOtherBonuses.includes(
            OfferConditionOtherBonusCode.CandidateCompanyLongivity
          ) && (
            <>
              <SectionGridRow
                isOffer
                rightChildren={
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.bonusMonthValue
                      ? `${recruitmentOpportunityConditions.bonusMonthValue} ${t('suffix.months')}`
                      : '-'}
                  </span>
                }
              >
                <Stack>
                  <FormInputWithSuffix
                    label={t('labels.bonusMonth')}
                    suffix={t('suffix.months')}
                    type="number"
                    mediumSuffix
                    noMargin
                    minValue={1}
                    invalid={!!errors.conditionBonusMonth}
                    helperText={
                      errors.conditionBonusMonth?.message &&
                      tGlobal(errors.conditionBonusMonth?.message, {
                        min: '1',
                      })
                    }
                    {...register('conditionBonusMonth')}
                  />
                </Stack>
              </SectionGridRow>
              <SectionGridRow
                isTopRow
                isOffer
                rightChildren={
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.bonusValue
                      ? tGlobal('suffix.currencyValue', {
                          value: recruitmentOpportunityConditions?.bonusValue,
                        })
                      : '-'}
                  </span>
                }
              >
                <Stack className="w-full" space={16}>
                  <FormInputWithSuffix
                    label={t('labels.otherBonusValue')}
                    suffix={t('suffix.currency')}
                    type="number"
                    mediumSuffix
                    noMargin
                    minValue={1}
                    maxValue={99999999.99}
                    step={0.01}
                    invalid={!!errors.conditionBonus}
                    helperText={
                      errors.conditionBonus?.message &&
                      tGlobal(errors.conditionBonus?.message, {
                        max: '99,999,999.99',
                        min: '1',
                      })
                    }
                    {...register('conditionBonus')}
                  />
                </Stack>
              </SectionGridRow>
            </>
          )}

        {conditionOtherBonusesAsked &&
          conditionOtherBonuses.includes(
            OfferConditionOtherBonusCode.HiringSpeed
          ) && (
            <>
              <SectionGridRow
                isOffer
                rightChildren={
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.hiredDateBonusRequirement
                      ? formatDate(
                          recruitmentOpportunityConditions?.hiredDateBonusRequirement,
                          i18n.language
                        )
                      : '-'}
                  </span>
                }
              >
                <FormInputWithSuffix
                  label={t('labels.hiredDateBonusRequirement')}
                  type="date"
                  minValue={formattedNowDate()}
                  invalid={!!errors.conditionHiredDateBonusRequirement}
                  helperText={
                    errors.conditionHiredDateBonusRequirement?.message &&
                    tGlobal(errors.conditionHiredDateBonusRequirement?.message)
                  }
                  {...register('conditionHiredDateBonusRequirement')}
                />
              </SectionGridRow>
              <SectionGridRow
                isTopRow
                isOffer
                rightChildren={
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.hiredDateBonusValue
                      ? tGlobal('suffix.currencyValue', {
                          value:
                            recruitmentOpportunityConditions?.hiredDateBonusValue,
                        })
                      : '-'}
                  </span>
                }
              >
                <Stack className="w-full" space={16}>
                  <FormInputWithSuffix
                    label={t('labels.otherBonusValue')}
                    suffix={t('suffix.currency')}
                    type="number"
                    mediumSuffix
                    noMargin
                    minValue={1}
                    maxValue={99999999.99}
                    step={0.01}
                    invalid={!!errors.conditionHiredDateBonus}
                    helperText={
                      errors.conditionHiredDateBonus?.message &&
                      tGlobal(errors.conditionHiredDateBonus?.message, {
                        max: '99,999,999.99',
                        min: '1',
                      })
                    }
                    {...register('conditionHiredDateBonus')}
                  />
                </Stack>
              </SectionGridRow>
            </>
          )}

        {conditionOtherBonusesAsked &&
          conditionOtherBonuses.includes(
            OfferConditionOtherBonusCode.Other
          ) && (
            <>
              <SectionGridRow
                isOffer
                rightChildren={
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.otherBonusDescription ? (
                      <TooltipEllipsis
                        lineClamp={5}
                        toolTip={
                          recruitmentOpportunityConditions?.otherBonusDescription
                        }
                      >
                        <span>
                          {
                            recruitmentOpportunityConditions?.otherBonusDescription
                          }
                        </span>
                      </TooltipEllipsis>
                    ) : (
                      '-'
                    )}
                  </span>
                }
              >
                <Controller
                  name="conditionOtherBonusDescription"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <TextAreaField
                      label={t('labels.otherBonusDescription')}
                      name={name}
                      value={value || ''}
                      rows={3}
                      className="w-full"
                      maxChar={3000}
                      onChange={onChange}
                      invalid={!!errors.conditionOtherBonusDescription}
                      helperText={
                        errors.conditionOtherBonusDescription?.message &&
                        tGlobal(
                          errors.conditionOtherBonusDescription?.message,
                          {
                            max: '3000',
                          }
                        )
                      }
                    />
                  )}
                />
              </SectionGridRow>
              <SectionGridRow
                isTopRow
                isOffer
                rightChildren={
                  <span className="text-16 font-semibold text-primary">
                    {recruitmentOpportunityConditions?.otherBonusValue
                      ? tGlobal('suffix.currencyValue', {
                          value:
                            recruitmentOpportunityConditions?.otherBonusValue,
                        })
                      : '-'}
                  </span>
                }
              >
                <Stack className="w-full" space={16}>
                  <FormInputWithSuffix
                    label={t('labels.otherBonusValue')}
                    suffix={t('suffix.currency')}
                    type="number"
                    mediumSuffix
                    noMargin
                    minValue={1}
                    maxValue={99999999.99}
                    step={0.01}
                    invalid={!!errors.conditionOtherBonusValue}
                    helperText={
                      errors.conditionOtherBonusValue?.message &&
                      tGlobal(errors.conditionOtherBonusValue?.message, {
                        max: '99,999,999.99',
                        min: '1',
                      })
                    }
                    {...register('conditionOtherBonusValue')}
                  />
                </Stack>
              </SectionGridRow>
            </>
          )}
      </SectionGrid>
    </>
  );
};

export default OtherBonusesOfferedFields;
