import { ChangeEvent } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  FormInputWithSuffix,
  RadioGroup,
  SectionGridRow,
  Stack,
  TextAreaField,
  Tooltip,
} from '@application/components';
import { BOOLEAN_VALUES } from '@application/constants';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../../types';

const CancellationFeeFields = () => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const conditionHasCancellationFee = watch('conditionHasCancellationFee');

  return (
    <SectionGridRow className="border-r-0" rightChildren={<span />}>
      <Stack className="w-full" space={16}>
        <div className="flex items-center">
          <h4>{t('labels.cancellationFee.title')}</h4>
          <Tooltip
            message={t('labels.cancellationFee.cancellationFeeTooltips')}
          >
            <i className="ri-information-line ml-s-8 text-20 leading-none text-neutral-secondary" />
          </Tooltip>
        </div>

        <Controller
          name="conditionHasCancellationFee"
          control={control}
          render={({ field: { name }, fieldState: { error } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legendSize="medium"
              legend={
                <Trans
                  i18nKey="labels.cancellationFee.addCancellationFee"
                  t={t}
                />
              }
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value === 'true');
                trigger(name);
              }}
              alignRow
              value={conditionHasCancellationFee ?? undefined}
              invalid={!!error}
              helperText={error?.message && tGlobal(error?.message)}
            />
          )}
        />

        {conditionHasCancellationFee && (
          <>
            <FormInputWithSuffix
              className="mt-s-16"
              label={t('labels.cancellationFee.cancellationFeeAmount')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.conditionCancellationFeeAmount}
              helperText={
                errors.conditionCancellationFeeAmount?.message &&
                tGlobal(errors.conditionCancellationFeeAmount?.message, {
                  max: '99,999,999.99',
                  min: '1',
                })
              }
              {...register('conditionCancellationFeeAmount')}
            />

            <Controller
              name="conditionCancellationFeeTerms"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <TextAreaField
                  label={t('labels.cancellationFee.cancellationFeeTerms')}
                  name={name}
                  value={value || ''}
                  rows={3}
                  className="w-full mt-s-16"
                  maxChar={3000}
                  onChange={onChange}
                  invalid={!!errors.conditionCancellationFeeTerms}
                  helperText={
                    errors.conditionCancellationFeeTerms?.message &&
                    tGlobal(errors.conditionCancellationFeeTerms?.message, {
                      max: '3000',
                    })
                  }
                />
              )}
            />
          </>
        )}
      </Stack>
    </SectionGridRow>
  );
};

export default CancellationFeeFields;
