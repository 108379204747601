import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';

import { ButtonLink, PageHeader, Tooltip } from '@application/components';
import { ButtonLinkCounter } from '@application/components/buttons';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { flattenEdges } from '@utils/data-utils';

import { useGetCandidateOffers } from '../offer/hooks';
import { useGetCandidateOpportunity } from './hooks';
import { ConditionsSummary, DescriptionSummary } from './summary';

const ViewCandidateOpportunityPage = () => {
  const { t } = useTranslation('candidates');
  const { t: tChat } = useTranslation('chatRoom', { keyPrefix: 'button' });

  const { id = '' } = useParams();

  const {
    viewModel: { data },
  } = useGetCandidateOpportunity(id);

  const {
    viewModel: { data: candidateOffersData },
  } = useGetCandidateOffers(undefined, { candidateRequestId: data?.id });

  const candidateOffer = flattenEdges(
    candidateOffersData?.candidateOffers.page.edges?.slice()
  )[0];

  const showMakeOfferButton = useMemo(
    () =>
      data &&
      !candidateOffer &&
      DateTime.now().startOf('day') <=
        DateTime.fromISO(data.receivingOfferDeadline).startOf('day'),
    [candidateOffer, data]
  );

  return (
    <>
      <PageHeader
        titleAs="h1"
        title={t('title.opportunityDetails')}
        backTo={RootPrivatePage.CANDIDATE_OPPORTUNITIES}
      >
        {candidateOffer && (
          <ButtonLink
            primary
            className="mr-s-16"
            to={generatePath(PrivatePage.VIEW_CANDIDATE_OFFER, {
              id: candidateOffer.id,
            })}
          >
            {t('button.seeMyOffer')}
          </ButtonLink>
        )}
        {showMakeOfferButton && (
          <ButtonLink
            primary
            className="mr-s-16"
            to={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_OFFER_CREATE, {
              id,
            })}
          >
            {t('button.makeOffer')}
          </ButtonLink>
        )}

        <Tooltip message={t('button.talkToAgent')} position="top">
          <div className="inline-block">
            <ButtonLinkCounter
              count={data?.unreadMessagesCount}
              iconName="ri-question-answer-line"
              to={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_CHAT, {
                id: data?.id ?? '',
              })}
              from={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_DETAILS, {
                id: data?.id ?? '',
              })}
              size="medium"
            >
              {tChat('chat')}
            </ButtonLinkCounter>
          </div>
        </Tooltip>
      </PageHeader>
      <div>
        <DescriptionSummary data={data} />
        <ConditionsSummary data={data} />
      </div>
    </>
  );
};

export default ViewCandidateOpportunityPage;
