import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxGroup, TextAreaField } from '@application/components';
import { Section } from '@application/views/recruitment/request/components';
import { OfferCandidatesEvaluationProcessCode } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { OfferFormFields } from '../../../../types';

const CandidatesEvaluationProcessesFields = () => {
  const { t, i18n } = useTranslation('recruitment', { keyPrefix: 'offer' });
  const { t: tGlobal } = useTranslation();

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    trigger,
  }: UseFormReturn<OfferFormFields> = useFormContext();

  const candidatesEvaluationProcesses =
    watch('candidatesEvaluationProcesses') || [];

  const handleCandidatesEvaluationProcessesChange = (event: any): void => {
    if (event.target.checked) {
      setValue('candidatesEvaluationProcesses', [
        ...candidatesEvaluationProcesses,
        event.target.value,
      ]);
    } else {
      setValue('candidatesEvaluationProcesses', [
        ...candidatesEvaluationProcesses.filter(
          (s) => s !== event.target.value
        ),
      ]);
    }
    trigger('candidatesEvaluationProcesses');
    trigger('candidatesEvaluationProcessesOthersDescription');
  };

  return (
    <Section legend={t('subtitles.candidatesEvaluationProcesses')} padding={0}>
      <Controller
        name="candidatesEvaluationProcesses"
        control={control}
        render={({ field: { value }, fieldState: { error } }) => (
          <CheckboxGroup
            className="p-s-24"
            data={Object.values(OfferCandidatesEvaluationProcessCode)
              .map((type) => ({
                value: type,
                text: {
                  fr: tGlobal(
                    `enum.offerCandidatesEvaluationProcessCode.${type.toLowerCase()}`
                  ),
                  en: tGlobal(
                    `enum.offerCandidatesEvaluationProcessCode.${type.toLowerCase()}`
                  ),
                },
              }))
              .sort((a) =>
                a.value === OfferCandidatesEvaluationProcessCode.Others ? 1 : -1
              )}
            language={extractLanguage(i18n.language)}
            onChange={handleCandidatesEvaluationProcessesChange}
            legendSize="small"
            values={value}
            invalid={!!error}
            helperText={error?.message && tGlobal(error?.message)}
          />
        )}
      />
      {candidatesEvaluationProcesses.includes(
        OfferCandidatesEvaluationProcessCode.Others
      ) && (
        <Controller
          name="candidatesEvaluationProcessesOthersDescription"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              className="p-s-24"
              label={t('labels.othersDescription')}
              name={name}
              value={value || ''}
              rows={4}
              maxChar={3000}
              onChange={(e) => {
                onChange(e);
                trigger(name);
              }}
              invalid={!!errors.candidatesEvaluationProcessesOthersDescription}
              helperText={
                errors.candidatesEvaluationProcessesOthersDescription
                  ?.message &&
                tGlobal(
                  errors.candidatesEvaluationProcessesOthersDescription
                    ?.message,
                  {
                    max: '3000',
                  }
                )
              }
            />
          )}
        />
      )}
      <Controller
        name="candidatesEvaluationProcessesMoreDetails"
        control={control}
        render={({ field: { onChange, name, value } }) => (
          <TextAreaField
            className="p-s-24"
            label={t('labels.moreDetails')}
            name={name}
            value={value || ''}
            rows={4}
            maxChar={3000}
            onChange={onChange}
            invalid={!!errors.candidatesEvaluationProcessesMoreDetails}
            helperText={
              errors.candidatesEvaluationProcessesMoreDetails?.message &&
              tGlobal(
                errors.candidatesEvaluationProcessesMoreDetails?.message,
                {
                  max: '3000',
                }
              )
            }
          />
        )}
      />
    </Section>
  );
};

export default CandidatesEvaluationProcessesFields;
