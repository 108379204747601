import { ChangeEvent, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Fieldset,
  FormInputWithSuffix,
  HelperText,
  Radio,
  RadioGroup,
  SectionGrid,
  SectionGridRow,
  TextAreaField,
} from '@application/components';
import { Cluster } from '@application/components/container-layouts';
import { BOOLEAN_VALUES } from '@application/constants';
import { RequestConditionResponsibility } from '@domain/graphql.types';
import { extractLanguage } from '@utils/i18n-utils';

import { RequestFormFields } from '../../schema';

type TemporaryPlacementFieldsProps = {
  control: Control<RequestFormFields, any>;
  errors: FieldErrors<RequestFormFields>;
  register: UseFormRegister<RequestFormFields>;
  setValue: UseFormSetValue<RequestFormFields>;
  trigger: UseFormTrigger<RequestFormFields>;
  watch: UseFormWatch<RequestFormFields>;
};

const TemporaryPlacementFields = ({
  control,
  errors,
  register,
  setValue,
  trigger,
  watch,
}: TemporaryPlacementFieldsProps) => {
  const { t, i18n } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  const [showNotes, setShowNotes] = useState(false);

  const buyback = watch('temporaryPlacementConditionPossibleBuybackValue');
  const foreignApplicants = watch(
    'temporaryPlacementConditionForeignApplicantsValue'
  );
  const travelExpenses = watch(
    'temporaryPlacementConditionTravelExpensesResponsibilityValue'
  );
  const trainingExpenses = watch(
    'temporaryPlacementConditionTrainingExpensesResponsibilityValue'
  );
  const employeeHelpProgram = watch(
    'temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue'
  );
  const cnesstExpenses = watch(
    'temporaryPlacementConditionCnesstExpensesResponsibilityValue'
  );

  const handleOnRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: keyof RequestFormFields
  ) => {
    setValue(name, event.target.value as any);
  };

  const showBuybackBonus = useMemo(() => {
    // If buyback is set from html, it will be a string because of radio buttons
    // But if we get it from backend, it's a boolean
    if (typeof buyback === 'string') {
      return buyback === 'true';
    }

    return buyback;
  }, [buyback]);

  return (
    <SectionGrid
      title={t('subtitles.forTemporaryPlacement')}
      className="mt-s-40"
      fullWidth
    >
      <SectionGridRow isTopRow>
        <div className="flex flex-col gap-s-16">
          <Controller
            name="temporaryPlacementConditionPossibleBuybackValue"
            control={control}
            render={({ field: { name } }) => (
              <RadioGroup
                name={name}
                data={BOOLEAN_VALUES}
                legend={t('labels.buyback')}
                language={extractLanguage(i18n.language)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue(name, event.target.value as any);
                  trigger(name);
                }}
                legendSize="medium"
                alignRow
                value={buyback ?? undefined}
                invalid={
                  !!errors.temporaryPlacementConditionPossibleBuybackValue
                }
                helperText={
                  errors.temporaryPlacementConditionPossibleBuybackValue
                    ?.message &&
                  tGlobal(
                    errors.temporaryPlacementConditionPossibleBuybackValue
                      ?.message
                  )
                }
              />
            )}
          />

          {showBuybackBonus && (
            <FormInputWithSuffix
              label={t('labels.buybackBonus')}
              suffix={t('suffix.currency')}
              type="number"
              mediumSuffix
              noMargin
              minValue={1}
              maxValue={99999999.99}
              step={0.01}
              invalid={!!errors.temporaryPlacementConditionPossibleBuybackBonus}
              helperText={
                errors.temporaryPlacementConditionPossibleBuybackBonus
                  ?.message &&
                tGlobal(
                  errors.temporaryPlacementConditionPossibleBuybackBonus
                    ?.message,
                  {
                    max: '99,999,999.99',
                    min: '1',
                  }
                )
              }
              {...register('temporaryPlacementConditionPossibleBuybackBonus')}
            />
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow>
        <Controller
          name="temporaryPlacementConditionRisksValue"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <TextAreaField
              name={name}
              className="w-full"
              value={value || ''}
              label={t('labels.risks')}
              labelSize="medium"
              rows={4}
              onChange={onChange}
            />
          )}
        />
      </SectionGridRow>

      <SectionGridRow>
        <Fieldset
          legend={t('labels.defineResponsibilities')}
          legendSize="medium"
        >
          <Controller
            name="temporaryPlacementConditionTravelExpensesResponsibilityValue"
            control={control}
            render={({ field: { name }, fieldState: { error } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.travelCosts')}
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
                invalid={!!error}
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) => {
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            );
                            trigger(name);
                          }}
                          checked={travelExpenses === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>

                {error?.message && (
                  <HelperText
                    id="temporaryPlacementConditionTravelExpensesResponsibilityValue-error"
                    invalid
                  >
                    {tGlobal(error?.message)}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          <Controller
            name="temporaryPlacementConditionTrainingExpensesResponsibilityValue"
            control={control}
            render={({ field: { name }, fieldState: { error } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.trainingCosts')}
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
                invalid={!!error}
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) => {
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            );
                            trigger(name);
                          }}
                          checked={trainingExpenses === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>

                {error?.message && (
                  <HelperText
                    id="temporaryPlacementConditionTrainingExpensesResponsibilityValue-error"
                    invalid
                  >
                    {tGlobal(error?.message)}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          <Controller
            name="temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue"
            control={control}
            render={({ field: { name }, fieldState: { error } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.employeeHelpProgram')}
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
                invalid={!!error}
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) => {
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            );
                            trigger(name);
                          }}
                          checked={employeeHelpProgram === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>

                {error?.message && (
                  <HelperText
                    id="temporaryPlacementConditionEmployeeHelpProgramResponsibilityValue-error"
                    invalid
                  >
                    {tGlobal(error?.message)}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          <Controller
            name="temporaryPlacementConditionCnesstExpensesResponsibilityValue"
            control={control}
            render={({ field: { name }, fieldState: { error } }) => (
              <Fieldset
                legend={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    <span>
                      {t('labels.cnesst')}
                      <Button
                        ghost
                        className="btn-link hover:!bg-transparent !min-w-fit px-s-8"
                        onClick={() => setShowNotes((state) => !state)}
                      >
                        {t('responsibilitiesNotes.notesHere')}
                      </Button>
                    </span>
                  </div>
                }
                legendSize="medium"
                className="mb-s-16"
                invalid={!!error}
              >
                <Cluster space={16} as="ul" className="-mt-s-8">
                  {Object.values(RequestConditionResponsibility).map(
                    (responsibility) => (
                      <li key={responsibility}>
                        <Radio
                          name={name}
                          value={responsibility}
                          label={t(
                            `enum.requestConditionResponsibility.${responsibility.toLowerCase()}`
                          )}
                          onChange={(e) => {
                            handleOnRadioChange(
                              e as ChangeEvent<HTMLInputElement>,
                              name
                            );
                            trigger(name);
                          }}
                          checked={cnesstExpenses === responsibility}
                          asButton
                        />
                      </li>
                    )
                  )}
                </Cluster>

                {error?.message && (
                  <HelperText
                    id="temporaryPlacementConditionCnesstExpensesResponsibilityValue-error"
                    invalid
                  >
                    {tGlobal(error?.message)}
                  </HelperText>
                )}
              </Fieldset>
            )}
          />

          {showNotes && (
            <div className="my-s-16 p-s-24 bg-neutral rounded-md">
              <div className="flex justify-between items-center mb-s-16">
                <span>{t('responsibilitiesNotes.title')}</span>

                <Button
                  ghost
                  className="btn-link hover:!bg-transparent bg-transparent !min-w-fit px-s-0"
                  onClick={() => setShowNotes((state) => !state)}
                >
                  {t('responsibilitiesNotes.close')}
                </Button>
              </div>

              <ul className="flex flex-col gap-s-16 text-14 mb-s-16 list-disc	pl-s-16">
                <li>{t('responsibilitiesNotes.firstResponsibility')}</li>
                <li>{t('responsibilitiesNotes.secondResponsibility')}</li>
              </ul>
            </div>
          )}

          <Controller
            name="temporaryPlacementConditionOtherResponsibilitiesValue"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <TextAreaField
                name={name}
                value={value || ''}
                label={
                  <div className="flex items-start">
                    <i className="ri-circle-fill text-6 m-s-8" />
                    {t('labels.otherResponsibilities')}
                  </div>
                }
                labelSize="medium"
                rows={4}
                onChange={onChange}
              />
            )}
          />
        </Fieldset>
      </SectionGridRow>

      <SectionGridRow>
        <Controller
          name="temporaryPlacementConditionForeignApplicantsValue"
          control={control}
          render={({ field: { name } }) => (
            <RadioGroup
              name={name}
              data={BOOLEAN_VALUES}
              legend={t('labels.foreignCandidates')}
              language={extractLanguage(i18n.language)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(name, event.target.value as any);
              }}
              legendSize="medium"
              alignRow
              value={foreignApplicants ?? undefined}
            />
          )}
        />
      </SectionGridRow>
    </SectionGrid>
  );
};

export default TemporaryPlacementFields;
