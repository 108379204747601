import { gql } from 'urql';

import { CandidateOpportunityMatchingRequestListFragment } from '@infrastructure/graphql/candidate/opportunity/fragment';
import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import RequestGeneralConditionsFragment from './request.general.conditions.fragment';
import RequestHeadhunterConditionsFragment from './request.headhunter.conditions.fragment';
import RequestInternationalRecruitmentConditionsFragment from './request.international.recruitment.conditions.fragment';
import RequestOutsourcingConditionsFragment from './request.outsourcing.conditions.fragment';
import RequestTemporaryPlacementConditionsFragment from './request.temporary.placement.conditions.fragment';

const RequestFragment = gql`
  ${DescriptionsFragment}
  ${RequestGeneralConditionsFragment}
  ${RequestHeadhunterConditionsFragment}
  ${RequestTemporaryPlacementConditionsFragment}
  ${RequestInternationalRecruitmentConditionsFragment}
  ${RequestOutsourcingConditionsFragment}
  ${CandidateOpportunityMatchingRequestListFragment}

  fragment RequestFragment on Request {
    id
    accountId
    citiesSubRegions
    desiredStudyField
    userId
    operationUnitId
    status
    priority
    isImportant
    jobSpecialtyCode
    sector {
      code
      descriptions {
        ...DescriptionsFragment
      }
      industry {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
    sectorCode
    softSkills
    specialty
    desiredEducationLevels {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    desiredEducationLevelCodes
    desiredStartDate
    receivingOfferDeadline
    operationTerritoryCodes
    perks
    jobExperienceLevelMin
    jobExperienceLevelMax
    jobDurationCode
    jobDurationInMonths
    jobModeCode
    jobFullTimeAvailability
    jobInternalTitle
    jobPartTimeAvailability
    jobAvailabilityInHoursPerWeek
    jobWorkingHours
    jobRemoteModeInDays
    jobOpenPositions
    jobSalaryMax
    jobSalaryMin
    jobPaymentFrequencyCode
    jobHourlyRateMax
    jobHourlyRateMin
    jobHourlyBonus
    jobRemunerationAmount
    jobPaymentOtherTypesCodes
    jobOtherRemunerationAmount
    jobAdditionalNotes
    jobDescription
    generalNotes
    generalContractualConditions {
      ...RequestGeneralConditionsFragment
    }
    headhunterContractualConditions {
      ...RequestHeadhunterConditionsFragment
    }
    temporaryPlacementContractualConditions {
      ...RequestTemporaryPlacementConditionsFragment
    }
    internationalRecruitmentContractualConditions {
      ...RequestInternationalRecruitmentConditionsFragment
    }
    outsourcingContractualConditions {
      ...RequestOutsourcingConditionsFragment
    }
    createdAt
    updatedAt
    availableTransitions
    jobDuration {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobMode {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobPaymentFrequency {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    jobSpecialty {
      code
      descriptions {
        ...DescriptionsFragment
      }
      sector {
        code
        descriptions {
          ...DescriptionsFragment
        }
        industry {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
      }
    }
    offersCount
    types
    otherPerks
    operationUnit {
      name
    }
    operationTerritories {
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    recruitmentNegotiation {
      id
    }
    user {
      name
      avatarUrl
    }
    account {
      name
      sector {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
      industryType {
        code
        descriptions {
          ...DescriptionsFragment
        }
      }
    }
    hasAgencyToChat
    unreadMessagesCount
    matchingCandidateOpportunities {
      ...CandidateOpportunityMatchingRequestListFragment
    }
  }
`;

export default RequestFragment;
