import { gql } from 'urql';

const CandidateRequestChatRoomMessages = gql`
  query CandidateRequestChatRoomMessages(
    $input: CandidateRequestChatRoomMessagesInput!
  ) {
    candidateRequestChatRoomMessages(input: $input) {
      results {
        id
        candidateRequestChatRoomId
        message
        sentAt
        readAt
        accountId
        userId
      }
      totalCount
    }
  }
`;

export default CandidateRequestChatRoomMessages;
