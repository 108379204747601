import { createContext } from 'react';

import { Account } from '@domain/graphql.types';

type AccountContextProps = {
  account: Account | undefined;
  error: Error | undefined;
  initializeAccount: () => void;
  isLoading: boolean;
  preferredSpecialtyCodes: string[];
  refreshAccount: () => void;
  updatePreferredSpecialtyCode: (newCode: string) => void;
};

const AccountContext = createContext<AccountContextProps>({
  account: undefined,
  error: undefined,
  initializeAccount: () => {},
  isLoading: false,
  preferredSpecialtyCodes: [],
  refreshAccount: () => {},
  updatePreferredSpecialtyCode: () => {},
});

export default AccountContext;
