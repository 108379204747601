import { gql } from 'urql';

import { DescriptionsFragment } from '@infrastructure/graphql/descriptions';

import CandidateRequestOutsourcingConditionsFragment from './candidate.request.outsourcing.conditions.fragment';
import CandidateRequestRecruitmentConditionsFragment from './candidate.request.recruitment.conditions.fragment';
import CandidateRequestTemporaryPlacementConditionsFragment from './candidate.request.temporary.placement.conditions.fragment';

const CandidateRequestFragment = gql`
  ${DescriptionsFragment}
  ${CandidateRequestOutsourcingConditionsFragment}
  ${CandidateRequestRecruitmentConditionsFragment}
  ${CandidateRequestTemporaryPlacementConditionsFragment}

  fragment CandidateRequestFragment on CandidateRequest {
    id
    accountId
    account {
      name
    }
    userId
    status
    firstName
    lastName
    email
    jobSpecialtyId
    specialty
    desiredStartDate
    receivingOfferDeadline
    operationTerritoryCodes
    operationTerritories {
      descriptions {
        ...DescriptionsFragment
      }
      code
      provinceCode
    }
    experience
    metAt
    type
    canWorkInCanadaOrQuebec
    jobType
    jobDurationInMonths
    jobDuration {
      descriptions {
        ...DescriptionsFragment
      }
      code
    }
    seasons
    hoursPerWeek
    fullTimeAvailability
    partTimeAvailability
    schedules
    jobModeCode
    jobMode {
      descriptions {
        ...DescriptionsFragment
      }
      code
    }
    remoteDaysAllowed
    paymentFrequencyCode
    annualSalaryMin
    annualSalaryMax
    hourlyRateMin
    hourlyRateMax
    hourlyBonus
    remunerationAmount
    otherPaymentTypeCode
    otherPaymentType {
      descriptions {
        ...DescriptionsFragment
      }
      code
    }
    candidateNegotiation {
      id
    }
    otherPaymentAmount
    softSkills
    additionalNotes
    recruitmentConditions {
      ...CandidateRequestRecruitmentConditionsFragment
    }
    temporaryPlacementConditions {
      ...CandidateRequestTemporaryPlacementConditionsFragment
    }
    outsourcingConditions {
      ...CandidateRequestOutsourcingConditionsFragment
    }
    createdAt
    updatedAt
    availableTransitions
    jobSpecialty {
      id
      sector {
        code
        descriptions {
          ...DescriptionsFragment
        }
        industry {
          code
          descriptions {
            ...DescriptionsFragment
          }
        }
      }
      code
      descriptions {
        ...DescriptionsFragment
      }
    }
    candidateOffersCount
    resumeUrl
    testUrl
    user {
      name
      avatarUrl
    }
    hasEnterpriseToChat
    unreadMessagesCount
  }
`;

export default CandidateRequestFragment;
