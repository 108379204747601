import { useTranslation } from 'react-i18next';

import { PlaceholderSwitcher, SectionGridRow } from '@application/components';
import { TooltipEllipsis } from '@application/components/tooltip-ellipsis';
import {
  Offer,
  OfferConditionOtherBonusCode,
  Request,
} from '@domain/graphql.types';
import { formatDate } from '@utils/date-utils';

type OtherBonusesOfferedConditionsProps = {
  data: Offer | undefined;
  request: Request | undefined;
};

const OtherBonusesOfferedConditions = ({
  data,
  request,
}: OtherBonusesOfferedConditionsProps) => {
  const { t, i18n } = useTranslation('offers', { keyPrefix: 'viewOffer' });
  const { t: tGlobal } = useTranslation();

  const conditions = data?.generalContractualConditions;
  const requestConditions = request?.generalContractualConditions;

  return (
    <>
      <SectionGridRow
        isTopRow
        isOffer
        rightChildren={
          <PlaceholderSwitcher value={requestConditions?.bonusMonthValue}>
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.bonusMonthValue
                ? `${requestConditions.bonusMonthValue} ${t('suffix.months')}`
                : '-'}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          {conditions?.otherBonuses?.includes(
            OfferConditionOtherBonusCode.CandidateCompanyLongivity
          ) ? (
            <div>
              <span className="mr-s-8">{t('labels.bonusMonth')}</span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.bonusMonth || '-'}
              </span>
              <span className="ml-s-8">{t('labels.bonusMonthEnding')}</span>
            </div>
          ) : (
            <span>{t('labels.noCandidateCompanyLongivityBonus')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <PlaceholderSwitcher value={requestConditions?.bonusValue}>
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.bonusValue
                ? tGlobal('suffix.currencyValue', {
                    value: requestConditions.bonusValue,
                  })
                : tGlobal('boolean.false')}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          <span className="mr-s-8">{t('labels.bonusValue')}</span>
          <span className="text-16 font-semibold text-primary">
            {conditions?.bonus &&
            conditions?.otherBonuses?.includes(
              OfferConditionOtherBonusCode.CandidateCompanyLongivity
            )
              ? tGlobal('suffix.currencyValue', {
                  value: conditions.bonus,
                })
              : tGlobal('labels.none')}
          </span>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <PlaceholderSwitcher
            value={requestConditions?.hiredDateBonusRequirement}
          >
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.hiredDateBonusRequirement
                ? formatDate(
                    requestConditions?.hiredDateBonusRequirement,
                    i18n.language
                  )
                : '-'}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          {conditions?.otherBonuses?.includes(
            OfferConditionOtherBonusCode.HiringSpeed
          ) ? (
            <>
              <span className="mr-s-8">
                {t('labels.hiredDateBonusRequirement')}
              </span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.hiredDateBonusRequirement
                  ? formatDate(
                      conditions?.hiredDateBonusRequirement,
                      i18n.language
                    )
                  : '-'}
              </span>
            </>
          ) : (
            <span>{t('labels.noHiringSpeedBonus')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <PlaceholderSwitcher value={requestConditions?.hiredDateBonusValue}>
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.hiredDateBonusValue
                ? tGlobal('suffix.currencyValue', {
                    value: requestConditions.hiredDateBonusValue,
                  })
                : '-'}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          <span className="mr-s-8">{t('labels.bonusValue')}</span>
          <span className="text-16 font-semibold text-primary">
            {conditions?.hiredDateBonus &&
            conditions?.otherBonuses?.includes(
              OfferConditionOtherBonusCode.HiringSpeed
            )
              ? tGlobal('suffix.currencyValue', {
                  value: conditions.hiredDateBonus,
                })
              : tGlobal('labels.none')}
          </span>
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        rightChildren={
          <PlaceholderSwitcher value={requestConditions?.otherBonusDescription}>
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.otherBonusDescription ? (
                <TooltipEllipsis
                  lineClamp={4}
                  toolTip={requestConditions?.otherBonusDescription}
                >
                  <span>{requestConditions?.otherBonusDescription}</span>
                </TooltipEllipsis>
              ) : (
                '-'
              )}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          {conditions?.otherBonuses?.includes(
            OfferConditionOtherBonusCode.Other
          ) ? (
            <>
              <span className="mr-s-8">
                {t('labels.otherBonusDescription')}
              </span>
              <span className="text-16 font-semibold text-primary">
                {conditions?.otherBonusDescription
                  ? conditions?.otherBonusDescription
                  : '-'}
              </span>
            </>
          ) : (
            <span>{t('labels.noOtherBonus')}</span>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow
        isOffer
        isTopRow
        rightChildren={
          <PlaceholderSwitcher value={requestConditions?.otherBonusValue}>
            <span className="text-16 font-semibold text-primary">
              {requestConditions?.otherBonusValue
                ? tGlobal('suffix.currencyValue', {
                    value: requestConditions?.otherBonusValue,
                  })
                : '-'}
            </span>
          </PlaceholderSwitcher>
        }
      >
        <div className="flex items-center">
          <span className="mr-s-8">{t('labels.bonusValue')}</span>
          <span className="text-16 font-semibold text-primary">
            {conditions?.otherBonusValue &&
            conditions?.otherBonuses?.includes(
              OfferConditionOtherBonusCode.Other
            )
              ? tGlobal('suffix.currencyValue', {
                  value: conditions.otherBonusValue,
                })
              : tGlobal('labels.none')}
          </span>
        </div>
      </SectionGridRow>
    </>
  );
};

export default OtherBonusesOfferedConditions;
