import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';

import { RootPrivatePage } from '@application/enums/pagesUrl';
import {
  RecruitmentNegotiationPropertyState,
  RecruitmentNegotiationRoomStatus,
  RecruitmentNegotiationStateUpdateInput,
} from '@domain/graphql.types';

import {
  useAcceptRecruitmentNegotiationRoomValues,
  useGetRecruitmentNegotiation,
  useRejectRecruitmentNegotiationRoomValues,
} from '../hooks';
import RecruitmentNegotiationContext from './RecruitmentNegotiationContext';

type RecruitmentNegotiationProviderProps = {
  children: ReactNode;
};

const RecruitmentNegotiationProvider = ({
  children,
}: RecruitmentNegotiationProviderProps) => {
  const [isComparing, setIsComparing] = useState<boolean>(false);
  const [checkedRooms, setCheckedRooms] = useState<string[]>([]);

  const { id: negotiationId = '' } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!(negotiationId && isUUID(negotiationId))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [navigate, negotiationId]);

  const {
    viewModel: { data, isLoading },
    refreshRecruitmentNegotiation,
  } = useGetRecruitmentNegotiation(negotiationId);

  const { acceptNegotiationRoomValues } =
    useAcceptRecruitmentNegotiationRoomValues();
  const { rejectNegotiationRoomValues } =
    useRejectRecruitmentNegotiationRoomValues();

  const rooms = useMemo(
    () =>
      (isComparing
        ? data?.rooms.filter((o) => checkedRooms.includes(o.id))
        : data?.rooms) || [],
    [isComparing, data?.rooms, checkedRooms]
  );

  const handleAcceptValues = useCallback(
    <T extends object>(
      id: string,
      key: keyof RecruitmentNegotiationStateUpdateInput,
      properties: (keyof T)[]
    ) =>
      async () => {
        const propertiesState = properties.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: RecruitmentNegotiationPropertyState.Accepted,
          }),
          {}
        ) as T;

        await acceptNegotiationRoomValues({
          input: {
            id,
            [key]: {
              ...propertiesState,
            },
          },
        });

        refreshRecruitmentNegotiation();
      },
    [acceptNegotiationRoomValues, refreshRecruitmentNegotiation]
  );

  const handleRejectValues = useCallback(
    <T extends object>(
      id: string,
      key: keyof RecruitmentNegotiationStateUpdateInput,
      properties: (keyof T)[]
    ) =>
      async () => {
        const propertiesState = properties.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: RecruitmentNegotiationPropertyState.Rejected,
          }),
          {}
        ) as T;

        await rejectNegotiationRoomValues({
          input: {
            id,
            [key]: {
              ...propertiesState,
            },
          },
        });

        refreshRecruitmentNegotiation();
      },
    [refreshRecruitmentNegotiation, rejectNegotiationRoomValues]
  );

  const getModifiedProperties = useCallback(
    <T extends object>(propertiesState: T): string[] => {
      const filteredEntries = Object.entries(propertiesState).filter(
        ([, value]) => value === RecruitmentNegotiationPropertyState.Modified
      );
      const modifiedPropertiesState = Object.fromEntries(filteredEntries);
      return Object.keys(modifiedPropertiesState);
    },
    []
  );

  const shouldDisplayMenu = useCallback(
    (
      roomStatus: RecruitmentNegotiationRoomStatus,
      propertyState: RecruitmentNegotiationPropertyState
    ) =>
      roomStatus === RecruitmentNegotiationRoomStatus.InProgress &&
      (propertyState === RecruitmentNegotiationPropertyState.Initial ||
        propertyState === RecruitmentNegotiationPropertyState.Modified),
    []
  );

  const value = useMemo(
    () => ({
      rooms,
      requestChatRoomUnreadMessagesCount:
        data?.rooms
          .map((room) => room.requestChatRoomUnreadMessagesCount)
          ?.reduce((sum, count) => sum + count, 0) ?? 0,
      request: data?.request,
      negotiationId,
      checkedRooms,
      setCheckedRooms,
      isComparing,
      setIsComparing,
      isLoading,
      shouldDisplayMenu,
      refreshRecruitmentNegotiation,
      getModifiedProperties,
      onAcceptValues: handleAcceptValues,
      onRejectValues: handleRejectValues,
    }),
    [
      checkedRooms,
      data?.rooms,
      data?.request,
      getModifiedProperties,
      handleAcceptValues,
      handleRejectValues,
      isComparing,
      isLoading,
      negotiationId,
      refreshRecruitmentNegotiation,
      rooms,
      shouldDisplayMenu,
    ]
  );

  return (
    <RecruitmentNegotiationContext.Provider value={value}>
      {children}
    </RecruitmentNegotiationContext.Provider>
  );
};

export default RecruitmentNegotiationProvider;
