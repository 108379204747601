import { useTranslation } from 'react-i18next';
import { UseQueryExecute } from 'urql';

import { FormActions, PagePanel, Stack } from '@application/components';
import { Accordion } from '@application/components/accordion';
import { AccordionItem } from '@application/components/accordion/accordion-item';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import {
  ConditionsSummary,
  DescriptionSummary,
} from '@application/views/recruitment/opportunity/summary';
import { RecruitmentOpportunity, RequestTypeCode } from '@domain/graphql.types';

import { CandidatesProposal } from './candidates-proposal';
import {
  CandidatesEvaluationProcessesFields,
  MandateFollowUpsFields,
  RequestTypesFields,
  SalesPitchFields,
  SimilarMandatesExperiencesFields,
  TalentSourcesFields,
} from './candidates-proposal/fields';

type CandidatesPanelProps = {
  recruitmentOpportunity: RecruitmentOpportunity | undefined;
  isEditing?: boolean;
  nextStep: () => void;
  refetchOffer?: UseQueryExecute;
};

const CandidatePanels = ({
  recruitmentOpportunity,
  isEditing,
  nextStep,
  refetchOffer,
}: CandidatesPanelProps) => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'offer',
  });

  const isServiceRequest =
    recruitmentOpportunity?.types?.includes(RequestTypeCode.Headhunter) &&
    !recruitmentOpportunity?.generalContractualConditions
      .candidateInHandMandatoryValue;

  return (
    <PagePanel
      title={isServiceRequest ? t('steps.services') : t('steps.candidate')}
      text={
        isServiceRequest ? t('steps.servicesText') : t('steps.candidateText')
      }
    >
      <Stack space={24}>
        <Accordion>
          <AccordionItem
            summary={t('subtitles.employerNeedsSummary')}
            customClassName="[&>div:last-of-type]:p-s-0 [&>div:first-of-type]:bg-secondary  [&>div:first-of-type]:text-18 [&>div:first-of-type]:font-semibold  [&>div:first-of-type]:p-s-16"
          >
            <DescriptionSummary
              data={recruitmentOpportunity}
              isInOpportunityOffer
            />
            <ConditionsSummary data={recruitmentOpportunity} />
          </AccordionItem>
        </Accordion>

        {isServiceRequest && (
          <>
            <SimilarMandatesExperiencesFields />

            <TalentSourcesFields />

            <CandidatesEvaluationProcessesFields />

            <MandateFollowUpsFields />
          </>
        )}

        {recruitmentOpportunity?.types &&
          recruitmentOpportunity?.types.length > 1 && (
            <RequestTypesFields
              types={[...(recruitmentOpportunity?.types || [])]}
            />
          )}

        <SalesPitchFields />

        <CandidatesProposal
          isEditing={isEditing}
          refetchOffer={refetchOffer}
          mustProposeCandidates={
            recruitmentOpportunity?.generalContractualConditions
              .candidateInHandMandatoryValue || false
          }
        />

        <FormActions
          nextStep={nextStep}
          cancelPath={RootPrivatePage.RECRUITMENT_OPPORTUNITIES}
          className="m-s-0"
        />
      </Stack>
    </PagePanel>
  );
};

export default CandidatePanels;
