import { CombinedError } from 'urql';

import { extractErrorCodes } from '@application/utils';
import ViewModel from '@application/view-model';
import { Mutation } from '@domain/graphql.types';

type ToViewModel = {
  candidateRequestChatRoomMessagesMarkAsRead: {
    data:
      | Pick<Mutation, 'markCandidateRequestChatRoomMessagesAsRead'>
      | undefined;
    fetching: boolean;
    error: CombinedError | undefined;
  };
};

export const toViewModel = ({
  candidateRequestChatRoomMessagesMarkAsRead: { data, fetching, error },
}: ToViewModel): ViewModel<
  Pick<Mutation, 'markCandidateRequestChatRoomMessagesAsRead'> | undefined
> =>
  new ViewModel<
    Pick<Mutation, 'markCandidateRequestChatRoomMessagesAsRead'> | undefined
  >({
    isLoading: fetching,
    errorCodes: extractErrorCodes(error),
    data,
  });
