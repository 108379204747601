import { useTranslation } from 'react-i18next';

import { SectionGridRow } from '@application/components';
import { PlaceholderSwitcher } from '@application/components/switch';
import { Request } from '@domain/graphql.types';

type GeneralConditionsProps = {
  data: Request | undefined;
};

const GeneralConditions = ({ data }: GeneralConditionsProps) => {
  const { t } = useTranslation('requests');
  const { t: tGlobal } = useTranslation();

  return (
    <>
      <SectionGridRow isTopRow>
        <div className="flex flex-col">
          <div className="mr-s-8">{t('labels.baselineBudget')}&nbsp;</div>

          {data?.generalContractualConditions?.maximumBudgetValue && (
            <div className="mr-s-8 mt-s-16 flex">
              <span>{t('labels.minBudget')}&nbsp;</span>

              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: data?.generalContractualConditions?.minimumBudgetValue,
                })}
              </span>

              <div className="divider divider-horizontal ml-s-8 mr-s-16 before:w-s-1 after:w-s-1" />

              <span>{t('labels.maxBudget')}&nbsp;</span>

              <span className="text-18 font-semibold text-primary">
                {tGlobal('suffix.currencyValue', {
                  value: data?.generalContractualConditions?.maximumBudgetValue,
                })}
              </span>
              <span className="ml-s-32">{t('labels.perCandidateHired')}</span>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow>
        <div className="flex flex-col">
          <div className="mr-s-8">
            {t('labels.preferredPaymentMethod')}&nbsp;
            <PlaceholderSwitcher
              value={data?.generalContractualConditions.paymentMethodValue}
            >
              <span className="text-18 font-semibold text-primary">
                {t(
                  `enum.requestConditionPaymentMethodCode.${data?.generalContractualConditions.paymentMethodValue?.toLowerCase()}`
                )}
              </span>
            </PlaceholderSwitcher>
          </div>
          {data?.generalContractualConditions.paymentFrequencyValue && (
            <div className="mr-s-8 mt-s-16 flex">
              <span className="mr-s-8">
                {`${t('enum.requestConditionStartOfAgreementPaymentMethodCode.payment_frequency')}:`}
              </span>
              <span className="text-18 font-semibold text-primary">
                {t(
                  `enum.requestConditionPaymentFrequencyCode.${data?.generalContractualConditions.paymentFrequencyValue?.toLowerCase()}`
                )}
              </span>
            </div>
          )}
        </div>
      </SectionGridRow>

      <SectionGridRow>
        <span className="mr-s-8">{t('labels.mustProposeCandidates')}</span>
        <PlaceholderSwitcher
          value={
            data?.generalContractualConditions.candidateInHandMandatoryValue
          }
        >
          <span className="text-18 font-semibold text-primary">
            {tGlobal(
              `boolean.${!!data?.generalContractualConditions
                .candidateInHandMandatoryValue}`
            )}
          </span>
        </PlaceholderSwitcher>
      </SectionGridRow>
    </>
  );
};

export default GeneralConditions;
