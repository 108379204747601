import { Link } from 'react-router-dom';

import { Box, PlaceholderSwitcher, Stack } from '@application/components';
import { Account } from '@domain/graphql.types';
import { getExternalLink } from '@utils/link-utils';

type GenerationInformationProps = {
  account?: Account;
};

const GeneralInformation = ({ account }: GenerationInformationProps) => (
  <Box>
    <Stack as="ul" space={8} className="text-14">
      {/* <li className="flex flex-row items-baseline">
        <i className="ri-map-pin-2-line text-20 mr-s-8" />
        <PlaceholderSwitcher value="yo">salut</PlaceholderSwitcher>
      </li> */}

      {account?.phoneDisplay && (
        <li className="flex flex-row items-baseline">
          <i className="ri-phone-line text-20 mr-s-8" />
          <PlaceholderSwitcher value={account?.phoneNumber}>
            {account?.phoneNumber}
          </PlaceholderSwitcher>
        </li>
      )}

      <li className="flex flex-row items-baseline">
        <i className="ri-building-4-line text-20 mr-s-8" />
        <PlaceholderSwitcher value={account?.address}>
          {account?.address}
        </PlaceholderSwitcher>
      </li>

      <li className="flex flex-row items-baseline">
        <i className="ri-global-line text-20 mr-s-8" />
        <PlaceholderSwitcher value={account?.website}>
          <Link
            to={getExternalLink(account?.website as string)}
            target="_blank"
            className="text-ellipsis overflow-hidden whitespace-nowrap"
          >
            {account?.website}
          </Link>
        </PlaceholderSwitcher>
      </li>

      {/* TODO: member since */}
      <li>
        <i className="ri-profile-line text-20 mr-s-8" />
        <span>-</span>
      </li>
    </Stack>
  </Box>
);

export default GeneralInformation;
