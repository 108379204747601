import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { validate as isUUID } from 'uuid';

import {
  Button,
  ConfirmationModal,
  PageHeader,
  PageLoader,
  Tooltip,
} from '@application/components';
import { ModalContext } from '@application/context';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import { OfferCandidateStatusTypeCode } from '@domain/graphql.types';

import CandidateHireModal from '../../offer/candidate/hired/CandidateHiredModal';
import { useGetOfferCandidate } from '../../offer/candidate/hooks';
import useUpdateCandidatePrivateNotes from '../../offer/candidate/hooks/useUpdateCandidatePrivateNotes';
import useUpdateCandidateStatusToHired from '../../offer/candidate/hooks/useUpdateCandidateStatusToHired';
import useUpdateCandidateStatusToInterview from '../../offer/candidate/hooks/useUpdateCandidateStatusToInterview';
import useUpdateCandidateStatusToRejected from '../../offer/candidate/hooks/useUpdateCandidateStatusToRejected';
import CandidatePrivateNotesModal from '../../offer/candidate/privateNotes/CandidatePrivateNotesModal';
import CandidateRejectionReasonModal from '../../offer/candidate/rejection-reason/CandidateRejectionReasonModal';
import useSeenAtOfferCandidate from '../../offer/hooks/useOfferCandidateSeenAt';
import { getOfferCandidateName } from './OfferCandidateAnonymization';
import GeneralInformation from './summary/GeneralInformation';
import Remuneration from './summary/Remuneration';
import SoftSkills from './summary/SoftSkills';

const OfferCandidateDetailsPage = () => {
  const { t } = useTranslation('candidates', {
    keyPrefix: 'candidateView',
  });

  const { t: tCandidates } = useTranslation('candidates');

  const navigate = useNavigate();
  const { setModal } = useContext(ModalContext);
  const { candidateId = '' } = useParams();

  const {
    viewModel: { data: offerCandidate, isLoading },
  } = useGetOfferCandidate(candidateId);

  const { updateSeenAt } = useSeenAtOfferCandidate();

  const location = useLocation();
  const fromState = location.state?.from;

  useEffect(() => {
    if (!(candidateId && isUUID(candidateId))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }

    updateSeenAt(candidateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, candidateId]);

  const { updateCandidateStatusToHired } = useUpdateCandidateStatusToHired();
  const { updateCandidateStatusToInterview } =
    useUpdateCandidateStatusToInterview();
  const { updateCandidateStatusToRejected } =
    useUpdateCandidateStatusToRejected();

  const handleUpdateStatusToHired = useCallback(
    async (closeRequest: boolean) => {
      await updateCandidateStatusToHired({
        updateCandidateStatusToHiredInput: { candidateId, closeRequest },
      });
    },
    [candidateId, updateCandidateStatusToHired]
  );

  const openHireConfirmationModal = useCallback(() => {
    setModal({
      title: tCandidates('modal.titles.hireConfirmation'),
      maxWidth: '2xl',
      content: (
        <CandidateHireModal
          onCancel={() => setModal(null)}
          onConfirm={async (closeRequest) => {
            await handleUpdateStatusToHired(closeRequest);
            setModal(null);
          }}
        />
      ),
    });
  }, [handleUpdateStatusToHired, setModal, tCandidates]);

  const handleUpdateStatusToInterview = useCallback(async () => {
    await updateCandidateStatusToInterview({
      updateCandidateStatusToInterviewInput: { candidateId },
    });
  }, [candidateId, updateCandidateStatusToInterview]);

  const openInterviewModal = useCallback(() => {
    setModal({
      title: tCandidates('modal.titles.interview'),
      maxWidth: 'xl',
      content: (
        <ConfirmationModal
          content={tCandidates('modal.contents.interviewConfirmation')}
          onCancel={() => setModal(null)}
          onConfirm={() => {
            handleUpdateStatusToInterview();
            setModal(null);
          }}
        />
      ),
    });
  }, [handleUpdateStatusToInterview, setModal, tCandidates]);

  const openRejectionReasonModal = useCallback(() => {
    setModal({
      title: tCandidates('modal.titles.rejectionReason'),
      maxWidth: '2xl',
      content: (
        <CandidateRejectionReasonModal
          onCancel={() => setModal(null)}
          onConfirm={async (rejectedCandidateData) => {
            await updateCandidateStatusToRejected({
              updateCandidateStatusToRejectedInput: {
                candidateId,
                rejectionReason: rejectedCandidateData.rejectionReason,
                rejectionExplanation: rejectedCandidateData.otherReason,
              },
            });

            setModal(null);
          }}
        />
      ),
    });
  }, [candidateId, setModal, tCandidates, updateCandidateStatusToRejected]);

  const { updateCandidatePrivateNotes } = useUpdateCandidatePrivateNotes();

  const handleUpdatePrivateNotes = useCallback(
    async (notes: string) => {
      await updateCandidatePrivateNotes({
        updateCandidatePrivateNotesInput: {
          candidateId,
          privateNotes: notes,
        },
      });
    },
    [candidateId, updateCandidatePrivateNotes]
  );

  const openPrivateNotesModal = useCallback(() => {
    setModal({
      title: tCandidates('modal.titles.privateNotes'),
      maxWidth: '4xl',
      content: (
        <CandidatePrivateNotesModal
          initialNotes={offerCandidate?.privateNotes || ''}
          onCancel={() => setModal(null)}
          onConfirm={async (notes) => {
            await handleUpdatePrivateNotes(notes);
            setModal(null);
          }}
        />
      ),
    });
  }, [
    handleUpdatePrivateNotes,
    offerCandidate?.privateNotes,
    setModal,
    tCandidates,
  ]);

  const getAvailableActions = useMemo(() => {
    const interviewButton = (
      <Button onClick={openInterviewModal}>{t('button.interview')}</Button>
    );

    const hireButton = (
      <Button primary onClick={openHireConfirmationModal}>
        {t('button.hire')}
      </Button>
    );

    const rejectButton = (
      <Button
        icon={<i className="ri-prohibited-line" />}
        onClick={openRejectionReasonModal}
      >
        {t('button.reject')}
      </Button>
    );

    switch (offerCandidate?.status) {
      case OfferCandidateStatusTypeCode.Rejected:
        return [interviewButton, hireButton];

      case OfferCandidateStatusTypeCode.Hired:
        return [interviewButton, rejectButton];

      case OfferCandidateStatusTypeCode.Interview:
        return [hireButton, rejectButton];

      default:
        return [interviewButton, hireButton, rejectButton];
    }
  }, [
    offerCandidate?.status,
    openHireConfirmationModal,
    openInterviewModal,
    openRejectionReasonModal,
    t,
  ]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        className="relative"
        titleAs="h1"
        title={
          <div className="flex justify-between items-center w-full">
            <div>
              {offerCandidate &&
                t('title', { name: getOfferCandidateName(offerCandidate) })}
            </div>
            {offerCandidate && (
              <div className="flex gap-s-16 absolute right-s-0">
                <Tooltip
                  message={
                    <div className="line-clamp-4 overflow-hidden">
                      {offerCandidate?.privateNotes}
                    </div>
                  }
                >
                  <Button
                    icon={<i className="ri-edit-2-line" />}
                    type="button"
                    onClick={openPrivateNotesModal}
                  >
                    {t('button.privateNotes')}
                  </Button>
                </Tooltip>
                {getAvailableActions}
              </div>
            )}
          </div>
        }
        backTo={fromState.from || RootPrivatePage.CANDIDATE_OFFERS}
      />

      <GeneralInformation offerCandidate={offerCandidate} />
      <Remuneration offerCandidate={offerCandidate} />
      <SoftSkills offerCandidate={offerCandidate} />
    </>
  );
};

export default OfferCandidateDetailsPage;
