import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { validate as isUUID } from 'uuid';

import { PageHeader, PageLoader } from '@application/components';
import { ButtonLinkCounter } from '@application/components/buttons';
import { ButtonLink } from '@application/components/links';
import { PrivatePage, RootPrivatePage } from '@application/enums/pagesUrl';
import { flattenEdges } from '@utils/data-utils';

import { useGetOffers } from '../offer/hooks';
import { useGetRecruitmentOpportunity } from './hooks';
import { ConditionsSummary, DescriptionSummary } from './summary';

const ViewRecruitmentOpportunityPage = () => {
  const { t } = useTranslation('recruitment', {
    keyPrefix: 'opportunity',
  });
  const { t: tChat } = useTranslation('chatRoom', { keyPrefix: 'button' });

  const { id = '' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!(id && isUUID(id))) {
      navigate(RootPrivatePage.NOT_FOUND, { replace: true });
    }
  }, [id, navigate]);

  const {
    viewModel: { data, isLoading },
  } = useGetRecruitmentOpportunity(id);

  const {
    viewModel: { data: offersData, isLoading: offersLoading },
  } = useGetOffers(undefined, { requestId: data?.id });

  const offer = flattenEdges(offersData?.offers.page.edges?.slice())[0];

  const showMakeOfferButton = useMemo(
    () =>
      data &&
      !offer &&
      DateTime.now().startOf('day') <=
        DateTime.fromISO(data.receivingOfferDeadline).startOf('day'),
    [data, offer]
  );

  return isLoading || offersLoading ? (
    <PageLoader />
  ) : (
    <>
      <PageHeader
        titleAs="h1"
        title={t('title.details')}
        backTo={RootPrivatePage.RECRUITMENT_OPPORTUNITIES}
      >
        {offer && (
          <ButtonLink
            primary
            className="mr-s-16"
            to={generatePath(PrivatePage.VIEW_OFFER, {
              id: offer.id,
            })}
            from={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, {
              id,
            })}
          >
            {t('button.seeMyOffer')}
          </ButtonLink>
        )}
        {showMakeOfferButton && (
          <ButtonLink
            className="mr-s-16"
            to={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_OFFER_CREATE, {
              id,
            })}
            primary
          >
            {t('button.makeOffer')}
          </ButtonLink>
        )}
        <ButtonLinkCounter
          count={data?.unreadMessagesCount}
          iconName="ri-question-answer-line"
          to={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_CHAT, {
            id: data?.id ?? '',
          })}
          from={generatePath(PrivatePage.RECRUITMENT_OPPORTUNITY_DETAILS, {
            id: data?.id ?? '',
          })}
          size="medium"
        >
          {tChat('chat')}
        </ButtonLinkCounter>
      </PageHeader>

      <div>
        <DescriptionSummary data={data} />

        <ConditionsSummary data={data} />
      </div>
    </>
  );
};

export default ViewRecruitmentOpportunityPage;
